import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import TestNewSpreadsheetData from "./TestNewSpreadsheetData";
import { getTTFB } from "web-vitals";

const TestSpreadsheetData = ({ defaultColDef, columnsData, user, setIsLoading, setShowTestData, showTestData, setShowProperties, currentPropertyIds, properties}) => {

  // ******************************************** Cell renderer functions ************************************************ \\

  const foundingCitizenCellRenderer = (params) => {
    return (
      <div>
        <input 
          type="checkbox"
          checked={params.value}
          onChange={() => {
            const newValue = !params.value;
            params.node.setDataValue('foundingCitizen', newValue);
          }}
        />
      </div>
    );
  };

  const testPropertyImageCellRenderer = (params) => {
    const url = "https://ik.imagekit.io/98sb9awea/properties/city1/tr:h-100/" + params.value;
    return (
      <div>
        <img 
          src={url}
        />
      </div>
    )
  };

  const [testColumnsData, setTestColumnsData] = useState([
    {field: 'id', width: 70, filter: 'agNumberColumnFilter' },
    {field: 'img_name', cellRenderer: testPropertyImageCellRenderer, width: 100},
    {field: 'map_ref'},
    {field: 'name', width: 150},
    {field: 'description', width: 100, suppressSizeToFit: true, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'tower'},
    {field: 'district'},
    {field: 'neighborhood'},
    {field: 'primary_type'},
    {field: 'sub_types.0', headerName: 'Sub Type 1', width: 100},
    {field: 'sub_types.1', headerName: 'Sub Type 2', width: 100},
    {field: 'structure'},
    {field: 'features.0', headerName: 'Feature 1', width: 100, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'features.1', headerName: 'Feature 2', width: 100, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'features.2', headerName: 'Feature 3', width: 100, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'price'},
    {field: 'status'},
    {field: 'foundingCitizen', cellRenderer: foundingCitizenCellRenderer},
    {field: 'owner', width: 100},
    {field: 'utility'},
  ]);

  const testGridRef = useRef();
  const [testerProperties, setTesterProperties] = useState(null);
  const [testerRowChanged, setTesterRowChanged] = useState([]);

  const [testSelectedFile, setTestSelectedFile] = useState();
  const [testSelectedFileEncoding, setTestSelectedFileEncoding] = useState(null);

  const [testUploadImageModal, setTestUploadImageModal] = useState(false);
  const [testUploadImageId, setTestUploadImageId] = useState(null);
  const [testUploadImageName, setTestUploadImageName] = useState("");
  const [testUpdateMapRef, setTestUpdateMapRef] = useState(null);
  const [testCurrentMapRefId, setTestCurrentMapRefId] = useState(null);

  useEffect(() => {
    if(showTestData === true) {
      getAllTesterProperties();
    }
  }, [showTestData]);

  // ******************************************** Grid layout functions ************************************************ \\

  const onTestFilterTextChanged = useCallback(() => {
    testGridRef.current.api.setQuickFilter(document.getElementById('test-filter-text-box').value);
  }, []);

  const onTestFirstDataRendered = useCallback(() => {
    autoSizeAll();
  }, []);

  const autoSizeAll = useCallback(() => {
    const allColumnIds = [];
    // Auto sizes the columns based on their content except for columns with large amount of content
    testGridRef.current.columnApi.getColumns().forEach((column) => {
      if(column.getId() === 'description' || column.getId() === 'features.0' || column.getId() === 'features.1' || column.getId() === 'features.2' || column.getId() === 'features.3') {
        return;
      }
      allColumnIds.push(column.getId());
    });
    testGridRef.current.columnApi.autoSizeColumns(allColumnIds);
  }, []);

  const clearTestFilter = useCallback(() => {
    testGridRef.current.api.setFilterModel(null);
    testGridRef.current.api.setQuickFilter('');
    document.getElementById('test-filter-text-box').value = '';
  }, []);

  // ******************************************** Update cell functions ************************************************ \\

  const onTestCellClicked = (e) => {
    console.log("Cell Clicked: ", e);
    if(e.column.colId === 'img_name') {
      console.log("Image clicked");
      setTestUploadImageId(e.data.id);
      if(e.data.name) {
        setTestUploadImageModal(true);
        setTestUploadImageName(e.data.name);
        setTestCurrentMapRefId(e.data.map_ref);
      } else {
        setTestUploadImageModal(false);
      }
    }
  };
  
  const onTestCellValueChanged = (e) => {
    // If there is an item in testerRowChanged that is the same as the row that is being changed
    // then we update the item already in the row instead of adding a new item 
    if(testerRowChanged.find(item => item.id === e.data.id)) {
      const items = testerRowChanged.find(item => item.id === e.data.id);
      const index = testerRowChanged.indexOf(items);

      // Gather all data in row
      let temp_state = [...testerRowChanged];
      // Only update the item that is being changed
      let temp_element = {...temp_state[index]};
      temp_element = e.data;
      temp_state[index] = temp_element;
      setTesterRowChanged(temp_state);

      console.log("Changed data: ", testerRowChanged);
    } else {
      setTesterRowChanged(prevRow => [...prevRow, e.data]);
    }
  };

  const updateTestImageName = (fileName) => {
    let currentRow = undefined;
    testGridRef.current.api.forEachNode((rowNode) => {
      if(rowNode.data.id === testUploadImageId) {
        console.log("Found row node: ", rowNode);
        rowNode.setDataValue('img_name', fileName);
        if(testUpdateMapRef) {
          console.log("Map ref: ", fileName.replace(".png", ""));
          rowNode.setDataValue("map_ref", fileName.replace(".png", ""));
        }
        currentRow = rowNode;
      }
    })
    if(currentRow !== undefined) {
      onTestCellValueChanged(currentRow);
    }
  };


  // ******************************************** Database control functions ************************************************ \\

  const getAllTesterProperties = async () => {
    setIsLoading(true);
    try {
      const getAllPropertiesTxn = await user.functions.all_properties_test();
      // const getAllPropertiesTxn = await dropUser.functions.getAllProperties();
      setTesterProperties(getAllPropertiesTxn);
      console.log("Properties: ", getAllPropertiesTxn);
    } catch (error) {
      console.log("Error retreiving properties: ", error);
    }
    setIsLoading(false);
  };

  const updateTestProperties = async () => {
    console.log("Update test triggered");
    await uploadImageToImageKit()
    try {
      for(let i = 0; i < testerRowChanged.length; i++){
        const updateProperties = await user.functions.update_property_test(testerRowChanged[i].id, testerRowChanged[i]);
        console.log("Update Properties: ", updateProperties);
        console.log("Row ID: ", testerRowChanged[i].id, "Row: ", testerRowChanged[i]);
      }
      setTesterRowChanged([]);
      setTestUpdateMapRef(false);
    } catch (error) {
      console.log("Error updating properties: ", error);
    }
};


  const fileUploadHandler = (e) => {
    // State to store uploaded file
    setTestSelectedFile(e.target.files[0]);
    const reader = new FileReader();
    const testData = reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log("File: ", reader.result);
      setTestSelectedFileEncoding(reader.result);
    };
    reader.onerror = () => {
      console.log("Error");
    }
  };

  const handleSubmission = () => {
    console.log("selected file: ", testSelectedFile);
    console.log("selected file encoding: ", testSelectedFileEncoding);
    uploadImageToImageKit();
  };

  const uploadImageToImageKit = async () => {
    const sc = process.env.REACT_APP_FAST_KEY;
    const params = {
      imageFile: testSelectedFileEncoding,
      imageName: testSelectedFile.name,
    };

    console.log("params: ", params);

    fetch("http://127.0.0.1:8000/uploadToImageKit", {
      method: "POST",
      mode: 'cors',
      header: {
        "Accept": 'application/json',
        "Content-Type": "application/json",
        "secretKey": sc,
      },
      body: JSON.stringify(params),
    })
    .then((response) => response.json())
      .then((result) => {
        console.log("result: ", result);
        updateTestImageName(result.data.imageName)
        console.log("iamge name: ", result.data.imageName);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const testUploadMapRefRenderer = () => {
    if(testUploadImageName && testSelectedFile) {
      return (
        <p className="pe-2 d-inline-block">from {testCurrentMapRefId} to {testSelectedFile.name.replace(".png", "")}</p>
      );
    } else {
      return (
        <div></div>
      )
    }
  };

  return (
    <div>
      <Modal size="xl" show={showTestData} onHide={() => {setShowTestData(false); setShowProperties(true)}}>
        <Modal.Header>
          <Modal.Title>Test Spreadsheet Data</Modal.Title>
          <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn pb-0" onClick={() => {setShowTestData(false); setShowProperties(true)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Row>
            <Col>
              <Row>
                <Col className="p-0">
                  <Form.Group className="d-inline-block p-2 align-right">
                    <Form.Control 
                      type="string"
                      placeholder="Search"
                      id="test-filter-text-box"
                      onInput={onTestFilterTextChanged}
                    />
                  </Form.Group>
                  <Button onClick={() => clearTestFilter()}>Clear filter</Button>
                </Col>
              </Row>
              <Row>
                {testUploadImageModal && (
                  <p>Updating image for: {testUploadImageName}</p>
                )}
              </Row>
              <Row>
                <Col className="pr-1">
                  <input 
                    type="file"
                    onChange={fileUploadHandler}
                    style={{width: "30%"}}
                  />
                  <Button onClick={() => handleSubmission()}>Submit</Button>
                  <Row>
                    <Col xs={8} className="pr-0">
                      <p className="m-0 ps-1 pe-1 d-inline-block">Update map ref via file</p>
                      {testUpdateMapRef && (testUploadMapRefRenderer())}
                      <input
                        type="checkbox"
                        onChange={() => setTestUpdateMapRef(!testUpdateMapRef)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div aria-description="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 500}}>
            <AgGridReact 
              ref={testGridRef}
              rowData={testerProperties}
              rowHeight={100}
              columnDefs={testColumnsData}
              defaultColDef={defaultColDef}
              onFirstDataRendered={onTestFirstDataRendered}
              onCellValueChanged={onTestCellValueChanged}
              onCellClicked={onTestCellClicked}
            />
          </div>
          <Modal.Footer>
            <Button onClick={() => updateTestProperties()}>Update</Button>
            <Button onClick={() => getAllTesterProperties()}>Log test data</Button>
          </Modal.Footer>
        </Modal.Body>
        <TestNewSpreadsheetData defaultColDef={defaultColDef} columnsData={columnsData} user={user} currentPropertyIds={currentPropertyIds} properties={properties}/>
      </Modal>
    </div>
  );
}

export default TestSpreadsheetData;