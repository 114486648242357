import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";
import "./UserPage.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const LevelComponent = ({ setIsLoading, softClayContract }) => {

  const [currentPioneerLevel, setCurrentPioneerLevel] = useState(0);
  const [newPioneerLevel, setNewPioneerLevel] = useState(0);
  const [currentLegendLevel, setCurrentLegendLevel] = useState(0);
  const [newLegendLevel, setNewLegendLevel] = useState(0);

  useEffect(() => {
    loadLevelData();
  }, []);

  const handlePioneerLevel = (e) => {
    setNewPioneerLevel(e.target.value);
  };
  const handleLegendLevel = (e) => {
    setNewLegendLevel(e.target.value);
  };

  const loadLevelData = async () => {
    getPioneerLevel();
    getLegendLevel();
  }

  const getPioneerLevel = async () => {
    const getPioneerLevelTxn = await softClayContract.getPioneerLevel();
    console.log("New pioneer level: ", getPioneerLevelTxn);
    setCurrentPioneerLevel(getPioneerLevelTxn);
  };

  const getLegendLevel = async () => {
    const getLegendLevelTxn = await softClayContract.getLegendLevel();
    console.log("New legend level: ", getLegendLevelTxn);
    setCurrentLegendLevel(getLegendLevelTxn);
  };

  const changePioneerLevel = async () => {
    try {
      setIsLoading(true);
      const changePioneerLevelTxn = await softClayContract.setPioneerLevel(newPioneerLevel);
      await changePioneerLevelTxn.wait();
      console.log("Pioneer level changed to: ", changePioneerLevelTxn);
      getPioneerLevel();
    } catch (error) {
      console.log("Error updating pioneer level: ", error);
    }
    setIsLoading(false);
  };
  
  const changeLegendLevel = async () => { 
    try {
      setIsLoading(true);
      const changeLegendLevelTxn = await softClayContract.setLegendLevel(newLegendLevel);
      await changeLegendLevelTxn.wait();
      console.log("Legend level changed to: ", changeLegendLevelTxn);
      getLegendLevel();
    } catch (error) {
      console.log("Error changing legend level: ", error);
    }
    setIsLoading(false);
  };

  return (
    <Card>
      <Card.Header>Levels</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Row>
                  <Col>
                    <Form.Label>Pioneer: {currentPioneerLevel} Soft Clay</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Form.Control 
                    type="number"
                    placeholder="Pioneer level"
                    value={newPioneerLevel}
                    onChange={handlePioneerLevel}
                  />
                </Row>
              </Form.Group>
                <Row xs={3} className="justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-button-1">Set number of soft clay needed to reach Pioneer level</Tooltip>}
                  >
                    <Button onClick={() => changePioneerLevel()}>Change level</Button>
                  </OverlayTrigger>
                </Row>
            </Col>
          </Row>
        </Card.Body>

        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Row>
                  <Col>
                    <Form.Label>Legend: {currentLegendLevel} Soft Clay</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Form.Control 
                    type="number"
                    placeholder="Legend level"
                    value={newLegendLevel}
                    onChange={handleLegendLevel}
                  />
                </Row>
              </Form.Group>
                <Row xs={3} className="justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-button-1">Set number of soft clay needed to reach Legend level</Tooltip>}
                  >
                    <Button onClick={() => changeLegendLevel()}>Change level</Button>
                  </OverlayTrigger>
                </Row>
            </Col>
          </Row>
        </Card.Body>
    </Card>
    );
};

export default LevelComponent;