import { useRef } from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ToolTipComponent = ({message}) => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="tooltip-button-1">{message}</Tooltip>}
    >
      <img className="information-icon" src="https://img.icons8.com/material-outlined/24/34495E/info--v1.png" width="20" height="20"/>
    </OverlayTrigger>
  )
};

export default ToolTipComponent;