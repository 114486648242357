import React, { useEffect, useState } from "react";
import twitterLogo from "./assets/twitter-logo.svg";
import "./App.css";
import * as Realm from "realm-web";
import HomePage from "./Components/HomePage";
import DetailsPage from "./Components/DetailsPage";
import NavigationBar from "./Components/NavBar";
import SideBar from "./Components/SideBar";
import { ACCESS_TOKEN_CONTRACT_ADDRESS, PAYMENT_SPLIT_CONTRACT_ADDRESS, IMAGE_DATA_CONTRACT_ADDRESS, LOTTERY_CONTRACT_ADDRESS, WHITELIST_CONTRACT_ADDRESS, SOFTCLAY_CONTRACT_ADDRESS, WIN_CHANCE_CONTRACT_ADDRESS, transformPassportData, EGG_COLLECTION_CONTRACT_ADDRESS } from "./constants";
// import MetropolisWorldAccessToken from "./utils/AccessToken.json";
import MetropolisWorldAccessToken from "./utils/AccessTokenMainnet.json";
import MetropolisWorldImageContract from "./utils/ImageData.json";
import MetropolisWorldPaymentSplit from "./utils/PaymentSplitPassport.json";
import MetropolisWorldLottery from "./utils/Lottery.json";
import MetropolisWorldWhitelist from "./utils/Whitelist.json";
import MetropolisWorldSoftclay from "./utils/SoftClay.json";
import MetropolisWorldWinChance from "./utils/WinChance.json";
import EggCollection from "./utils/EggCollection.json";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { parse } from "@ethersproject/transactions";
import UsersPage from "./Components/UsersPage";
import bcrypt from "bcryptjs";
import PwdChange from "./Components/Modal/pwdChange";

const App = () => {

  // ********************* MongoDB ********************* //
  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [loggedIn, setLoggedIn] = useState(null);
  // MongoDB username 
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // Current MongoDB user details
  const [currentAccount, setCurrentAccount] = useState(null);

  // ********************* Content Rendering ********************* //
  const [userPage, setUserPage] = useState(false);
  const [detailsPage, setDetailsPage] = useState(false);
  const [pannel, setPannel] = useState("");
  const [showUnused, setShowUnused] = useState(false);

  // ********************* Smart Contracts ********************* //
  const [contracts, setContracts] = useState({
    accessTokenContract: null,
    imageContract: null,
    paymentSplitContract: null,
    lotteryContract: null,
    whitelistContract: null,
    softClayContract: null,
    winChanceContract: null,
    eggCollectionContract: null
  }) 

  const [currentWallet, setCurrentWallet] = useState(null);
  const [creator, setCreator] = useState("0xB5573aD4969c698DD81E0eAA8Ff4ec5deA785EB3".toLowerCase());

  // ********************* NavBar ********************* //
  // Toggles the top navigation bar
  const [showNavBar, setShowNavBar] = useState(true);

  // ********************* SideBar ********************* //

  // Toggles the side navigation bar. false = Expanded, true = Collapsed
  const [collapseSideBar, setCollapseSideBar] = useState(false);
  // Passed into respective pages for navigation
  const [sideBarItemPages, setSideBarItemPages] = useState({
    passportPage: "token",
    cityPage: "properties",
    citizensPage: "avatars"
  });
  

  const handleChange = (e) => {
    setUsername(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    loadBlockchainData();
  }, []);

  const loadBlockchainData = async () => {
    try {
      const { ethereum } = window;
      if(!ethereum) {
        alert("Try MetaMask");
        return;
      } else {
        const accounts = await ethereum.request({method: 'eth_accounts'});
        if(accounts.length !== 0) {
          // Retrieve first account from array of accounts in MetaMask
          const account = accounts[0];
          console.log("Found authorized account: ", account);
          setCurrentWallet(account);
        } else {
          console.log("No authorized account found");
        }
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const accessTokenContract = new ethers.Contract(
          ACCESS_TOKEN_CONTRACT_ADDRESS,
          MetropolisWorldAccessToken.abi,
          signer
      );
      let tempObj = {};
      tempObj.accessTokenContract = accessTokenContract;
      // setAccessTokenContract(accessTokenContract);

      const imageContract = new ethers.Contract(
        IMAGE_DATA_CONTRACT_ADDRESS,
        MetropolisWorldImageContract.abi,
        signer
      )
      tempObj.imageContract = imageContract;
      // setImageContract(imageContract);

      const paymentSplitContract = new ethers.Contract(
        PAYMENT_SPLIT_CONTRACT_ADDRESS,
        MetropolisWorldPaymentSplit.abi,
        signer
      )
      tempObj.paymentSplitContract = paymentSplitContract;
      // setPaymentSplitContract(paymentSplitContract);

      const lotteryContract = new ethers.Contract(
       LOTTERY_CONTRACT_ADDRESS,
       MetropolisWorldLottery.abi,
       signer 
      )
      tempObj.lotteryContract = lotteryContract;
      // setLotteryContract(lotteryContract);

      const whitelistContract = new ethers.Contract(
        WHITELIST_CONTRACT_ADDRESS,
        MetropolisWorldWhitelist.abi,
        signer
      )
      tempObj.whitelistContract = whitelistContract;
      // setWhitelistContract(whitelistContract);

      const softClayContract = new ethers.Contract(
        SOFTCLAY_CONTRACT_ADDRESS,
        MetropolisWorldSoftclay.abi,
        signer
      )
      tempObj.softClayContract = softClayContract;
      // setSoftClayContract(softClayContract);

      const winChanceContract = new ethers.Contract(
        WIN_CHANCE_CONTRACT_ADDRESS,
        MetropolisWorldWinChance.abi,
        signer
      )
      tempObj.winChanceContract = winChanceContract;
      // setWinChanceContract(winChanceContract);

      const eggCollectionContract = new ethers.Contract(
        EGG_COLLECTION_CONTRACT_ADDRESS,
        EggCollection.abi,
        signer
      );
      tempObj.eggCollectionContract = eggCollectionContract;
      // setEggCollectionContract(eggCollectionContract);
      setContracts({...tempObj});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderContent = () => {
    
    if (!currentAccount) {
      return (
        <div className="connect-wallet-container">
          <Card>
            <Card.Body>
              {loggedIn === false && (
                <Alert variant="danger">
                  You have entered an incorrect username or password. Please try
                  again, or contact IT.
                </Alert>
              )}
              <Row>
                <Col>
                  <img
                    src="https://media.giphy.com/media/kvl2YhR110qsBrHid2/giphy.gif"
                    alt="Mission Control Gif"
                  />
                </Col>
                <Col>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={username}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handleChangePassword}
                      />
                    </Form.Group>

                    <Button
                      className="cta-button connect-wallet-button"
                      onClick={logUserInAction}
                    >
                      Login To Get Started
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      );
      /*
       * Scenario #2
       */
    } else if (currentAccount && userPage===false && detailsPage===false) {
      return <HomePage  currentAccount={currentAccount} setDetailsPage={setDetailsPage} setPannel={setPannel}/>
    } else if (currentAccount && userPage===true && detailsPage===false){
      return <UsersPage />;
    }else if (currentAccount && userPage===false && detailsPage===true){
      return <DetailsPage currentAccount={currentAccount} pannel={pannel} contracts={contracts}
      currentWallet={currentWallet} creator={creator} setShowNavBar={setShowNavBar} collapseSideBar={collapseSideBar}
      setCollapseSideBar={setCollapseSideBar} sideBarItemPages={sideBarItemPages} setSideBarItemPages={setSideBarItemPages}
      />
    }
  };

  const logUserInAction = async () => {
    //alert("logging in");
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate the user
      const user = await realmApp.logIn(credentials);
      console.log("mongo user", user);
      const salt = '$2a$10$.qotYSqDjhj0JVUGzfk1q.'
      const hashed = bcrypt.hashSync(
        password,
        salt
      );
      const loggedIn = await user.functions.LogUserIn(username, hashed);
      if (loggedIn !== null) {
        // looged in fine.
        console.log(typeof loggedIn);
        setCurrentAccount(JSON.stringify(loggedIn));
        // setCurrentAccount(loggedIn);
        setLoggedIn(true);
        console.log("User logged in: ", loggedIn);
        // store the user in localStorage
        localStorage.setItem('user', JSON.stringify(loggedIn));
        // localStorage.setItem('user', loggedIn);
        setUserPage(false);
      } else {
        setLoggedIn(false);
        console.log("user not logged in", loggedIn);
      }
    } catch (err) {
      console.error("Failed to get minted list", err);
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setCurrentAccount(foundUser);
      setLoggedIn(true);
    }
  }, []);

  
  return (
    <div className="App">
      <div className="containers">
        <div className="header-container">
        {currentAccount === null && (
          <p className="header gradient-text">
            Metropolis World Mission Control
          </p>)}
          {/* <Row>
          {currentAccount !== null && showNavBar && (
            <NavigationBar currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} setUserPage={setUserPage} setDetailsPage={setDetailsPage} 
            currentWallet={currentWallet} setCurrentWallet={setCurrentWallet}/>
          )}
          </Row> */}
          {/* <div id="grid" className="grid">
            <div><SideBar setDetailsPage={setDetailsPage} setUserPage={setUserPage} setPannel={setPannel} collapseSideBar={collapseSideBar} setCollapseSideBar={setCollapseSideBar}/></div>
            <div>
            {currentAccount !== null && showNavBar && (
                <NavigationBar currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} setUserPage={setUserPage} setDetailsPage={setDetailsPage} 
                currentWallet={currentWallet} setCurrentWallet={setCurrentWallet}/>
              )}
              {renderContent()}
            </div>
          </div> */}
          <Row>
              <SideBar setDetailsPage={setDetailsPage} setUserPage={setUserPage} setPannel={setPannel} collapseSideBar={collapseSideBar} 
              setCollapseSideBar={setCollapseSideBar} sideBarItemPages={sideBarItemPages} setSideBarItemPages={setSideBarItemPages} accessTokenContract={contracts.accessTokenContract}
              currentWallet={currentWallet} showUnused={showUnused}
              />
            <Col className="main-col">
              {currentAccount !== null && showNavBar && (
                <NavigationBar currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} setUserPage={setUserPage} setDetailsPage={setDetailsPage} 
                currentWallet={currentWallet} setCurrentWallet={setCurrentWallet} showUnused={showUnused} setShowUnused={setShowUnused}/>
              )}
              {renderContent()}
            </Col>
          </Row>
        </div>
        {/* <div className="footer-container">
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
        </div> */}
      </div>
    </div>
  );
};

export default App;
