import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'

const NewSpreadsheetData = ({ defaultColDef, columnsData, user, setIsLoading, currentPropertyIds, properties}) => {

  const newGridRef = useRef();
  const [newRowData, setNewRowData] = useState([]);
  const [newRowChanged, setNewRowChanged] = useState([]);
  const [newIdIncrement, setNewIdIncrement] = useState(1);
  const [newRowId, setNewRowId] = useState(1);

  const onNewGridReady = (e) => {
    newGridRef.current.api.setDomLayout('autoHeight');
    document.querySelector("#newGrid").style.height = '';
  };

  const onNewCellValueChanged = (e) => {
    // Check to see if e.data.id is already in newRowData
    if(newRowData.find(item => item.id === e.data.id)) {
      const items = newRowData.find(item => item.id === e.data.id);
      const index = newRowData.indexOf(items);

      let temp_state = [...newRowChanged];
      let temp_element = {...temp_state[index]};
      temp_element = e.data;
      temp_state[index] = temp_element;
      setNewRowChanged(temp_state);

      console.log("Changed data: ", newRowChanged);
    } else {
      setNewRowChanged(...prevRow => [...prevRow, e.data]);
    }
    console.log("New row data: ", newRowData);
  };

  const addNewRow = (e) => {
    const newIndex = properties.length;
    setNewRowId(properties[newIndex - 1].id + newIdIncrement);
    setNewIdIncrement(newIdIncrement + 1);
  };

  useEffect(() => {
    if(newRowId != 1) {
      const appendData = {
        id: newRowId,
        drop: 'city1',
        choiceCount: 0,
        foundingCitizen: false,
        features: [],
        sub_types: [],
        staus: 'Draft',
      }; 
      if(newRowData === []) {
        setNewRowData(appendData);
      } else {
        setNewRowData(prevRow => [...prevRow, appendData]);
      }
    }
  }, [newRowId])

  const newProperties = async () => {
    try {
      if(currentPropertyIds.includes(parseInt(newRowChanged[0].id))) {
        alert("ID " + newRowChanged[0].id + " already exists! Try to update instead");
      } else {
        for(let i = 0; i < newRowData.length; i++){
          checkIfEmpty(i);
          newRowData[i].id = parseInt(newRowData[i].id);
          const newPropertiesTxn = await user.functions.new_property(newRowData[i].id, newRowData[i]);
          // console.log("Row ID: ", newRowChanged[0].id, "Row: ", newRowChanged[0]);
        }
        setNewRowData([]);
      }
    } catch (error) {
      console.log("Error adding new properties: ", error);
    }
  };

  // Checks if the features or sub type arrays are empty 
  const checkIfEmpty = (index) => {
    // Delete empty feature array
    if(newRowData[index].features.length === 0) {
      console.log("Feature is empty: ", newRowData[index].features);
      delete newRowData[index].features;
      // If there is one value and it is null or an empty string then delete the array
    } else if (newRowData[index].features.length === 1) {
      if (newRowData[index].features[0] === null) {
        console.log("Feature null found");
        delete newRowData[index].features;
      } else if(newRowData[index].features[0] === "") {
        console.log("Feature empty string found");
        delete newRowData[index].features;
      } 
      // If there is more than one value:
    } else if (newRowData[index].features.length > 1) {
      // Filter the null and empty strings and update the array
      newRowData[index].features = newRowData[index].features.filter((oldArr) => {return oldArr !== null});
      newRowData[index].features = newRowData[index].features.filter((oldArr) => {return oldArr !== ""});
      console.log("Filtered feature: ", newRowData[index].features);
      // If everything is filtered we delete the array
      if (newRowData[index].features.length === 0) {
        delete newRowData[index].features;
      }
    }
    
    if (newRowData[index].sub_types.length === 0) {
      console.log("Sub type is empty: ", newRowData[index].sub_types);
      delete newRowData[index].sub_types;
    } else if (newRowData[index].sub_types.length === 1) {
      if (newRowData[index].sub_types[0] === null) {
        console.log("Sub type null found");
        delete newRowData[index].sub_types;
      } else if(newRowData[index].sub_types[0] === "") {
        console.log("Sub type empty string found");
        delete newRowData[index].sub_types;
      } 
    } else if (newRowData[index].sub_types.length > 1) {
      newRowData[index].sub_types = newRowData[index].sub_types.filter((oldArr) => {return oldArr !== null});
      newRowData[index].sub_types = newRowData[index].sub_types.filter((oldArr) => {return oldArr !== ""});
      console.log("Filtered sub type: ", newRowData[index].sub_types);
      if(newRowData[index].sub_types.length === 0) {
        delete newRowData[index].sub_types;
      }
    }
  }

  return (
    <div>
      <Modal.Body className="pt-0">
          <h2>New</h2>
          <div id="newGrid" aria-description="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 150}}>
            <AgGridReact 
              ref={newGridRef}
              rowData={newRowData}
              columnDefs={columnsData}
              defaultColDef={defaultColDef}
              onCellValueChanged={onNewCellValueChanged}
              onGridReady={onNewGridReady}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addNewRow()}>New row</Button>
          <Button onClick={() => setNewRowData([])}>Clear</Button>
          <Button onClick={() => newProperties()}>Add new properties</Button>
        </Modal.Footer>
    </div>
  )
}

export default NewSpreadsheetData;