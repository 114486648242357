import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'

const TestNewSpreadsheetData = ({ defaultColDef, columnsData, user, currentPropertyIds, properties }) => {

  const newTestGridRef = useRef();
  const [newTestRowData, setNewTestRowData] = useState([]);
  const [newTestRowChanged, setNewTestRowChanged] = useState([]);
  const [newTestIdIncrement, setNewTestIdIncrement] = useState(1);
  const [newTestRowId, setNewTestRowId] = useState(1);

  const onNewTestGridReady = (e) => {
    newTestGridRef.current.api.setDomLayout('autoHeight');
    document.querySelector("#newTestGrid").style.height = '';
  };

  const onNewTestCellValueChanged = (e) => {
    // Check to see if e.data.id is already in newRowData
    if(newTestRowData.find(item => item.id === e.data.id)) {
      const items = newTestRowData.find(item => item.id === e.data.id);
      const index = newTestRowData.indexOf(items);

      let temp_state = [...newTestRowChanged];
      let temp_element = {...temp_state[index]};
      temp_element = e.data;
      temp_state[index] = temp_element;
      setNewTestRowChanged(temp_state);

      console.log("Changed data: ", newTestRowChanged);
    } else {
      setNewTestRowChanged(...prevRow => [...prevRow, e.data]);
    }
    console.log("New row data: ", newTestRowData);
  };

  const addNewTestRow = (e) => {
    const newIndex = properties.length;
    setNewTestRowId(properties[newIndex - 1].id + newTestIdIncrement);
    setNewTestIdIncrement(newTestIdIncrement + 1);
  };

  useEffect(() => {
    if(newTestRowId != 1) {
      const appendData = {
        id: newTestRowId,
        drop: 'city1',
        choiceCount: 0,
        foundingCitizen: false,
        features: [],
        sub_types: [],
        staus: 'Draft',
      }; 
      if(newTestRowData === []) {
        setNewTestRowData(appendData);
      } else {
        setNewTestRowData(prevRow => [...prevRow, appendData]);
      }
    }
  }, [newTestRowId]);

  
  const newTestProperties = async () => {
    try {
      if(currentPropertyIds.includes(parseInt(newTestRowChanged[0].id))) {
        alert("ID " + newTestRowChanged[0].id + " already exists! Try to update instead");
      } else {
        for(let i = 0; i < newTestRowData.length; i++){
          checkIfEmpty(i);
          newTestRowData[i].id = parseInt(newTestRowData[i].id);
          const newTestPropertiesTxn = await user.functions.new_property_test(newTestRowData[i].id, newTestRowData[i]);
          // console.log("Row ID: ", newRowChanged[0].id, "Row: ", newRowChanged[0]);
        }
        setNewTestRowData([]);
      }
    } catch (error) {
      console.log("Error adding new properties: ", error);
    }
  };

    // Checks if the features or sub type arrays are empty 
    const checkIfEmpty = (index) => {
      // Delete empty feature array
      if(newTestRowData[index].features.length === 0) {
        console.log("Feature is empty: ", newTestRowData[index].features);
        delete newTestRowData[index].features;
        // If there is one value and it is null or an empty string then delete the array
      } else if (newTestRowData[index].features.length === 1) {
        if (newTestRowData[index].features[0] === null) {
          console.log("Feature null found");
          delete newTestRowData[index].features;
        } else if(newTestRowData[index].features[0] === "") {
          console.log("Feature empty string found");
          delete newTestRowData[index].features;
        } 
        // If there is more than one value:
      } else if (newTestRowData[index].features.length > 1) {
        // Filter the null and empty strings and update the array
        newTestRowData[index].features = newTestRowData[index].features.filter((oldArr) => {return oldArr !== null});
        newTestRowData[index].features = newTestRowData[index].features.filter((oldArr) => {return oldArr !== ""});
        console.log("Filtered feature: ", newTestRowData[index].features);
        // If everything is filtered we delete the array
        if (newTestRowData[index].features.length === 0) {
          delete newTestRowData[index].features;
        }
      }
      
      if (newTestRowData[index].sub_types.length === 0) {
        console.log("Sub type is empty: ", newTestRowData[index].sub_types);
        delete newTestRowData[index].sub_types;
      } else if (newTestRowData[index].sub_types.length === 1) {
        if (newTestRowData[index].sub_types[0] === null) {
          console.log("Sub type null found");
          delete newTestRowData[index].sub_types;
        } else if(newTestRowData[index].sub_types[0] === "") {
          console.log("Sub type empty string found");
          delete newTestRowData[index].sub_types;
        } 
      } else if (newTestRowData[index].sub_types.length > 1) {
        newTestRowData[index].sub_types = newTestRowData[index].sub_types.filter((oldArr) => {return oldArr !== null});
        newTestRowData[index].sub_types = newTestRowData[index].sub_types.filter((oldArr) => {return oldArr !== ""});
        console.log("Filtered sub type: ", newTestRowData[index].sub_types);
        if(newTestRowData[index].sub_types.length === 0) {
          delete newTestRowData[index].sub_types;
        }
      }
    }

  return (
    <div>
      <Modal.Body className="pt-0">
          <h2>New</h2>
          <div id="newTestGrid" aria-description="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 150}}>
            <AgGridReact 
              ref={newTestGridRef}
              rowData={newTestRowData}
              columnDefs={columnsData}
              defaultColDef={defaultColDef}
              onCellValueChanged={onNewTestCellValueChanged}
              onGridReady={onNewTestGridReady}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => addNewTestRow()}>New row</Button>
          <Button onClick={() => setNewTestRowData([])}>Clear</Button>
          <Button onClick={() => newTestProperties()}>Add new properties</Button>
        </Modal.Footer>
    </div>
  );
}

export default TestNewSpreadsheetData;