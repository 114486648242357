import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

const GamerProfile = ({ setIsLoading, passportIndex, accessTokenContract, softClayContract }) => {

  const [profileAddress, setProfileAddress] = useState("");
  const [currentPassportId, setCurrentPassportId] = useState(0);
  const [currentSoftClay, setCurrentSoftClay] = useState(0);
  const [currentWinChances, setCurrentWinChances] = useState(0);
  const [currentRank, setCurrentRank] = useState("");
  const [pioneerLevel, setPioneerLevel] = useState(0);
  const [legendLevel, setLegendLevel] = useState(0);
  const [currentImage, setCurrentImage] = useState("");
  const [currentClaimableSoftClay, setCurrentClaimableSoftClay] = useState(0);
  const [reedeemSoftClayValue, setRedeemSoftClayValue] = useState(0);
  const [currentWhitelistSpots, setCurrentWhitelistSpots] = useState([]);
  // const [passportIndex, setPassportIndex] = useState(1);

  useEffect(() => {
    if(currentPassportId != 0) {
      getClaimableSoftClay();
    }
  }, [currentPassportId])

  const handleAddressChange = (e) => {
    setProfileAddress(e.target.value);
  };

  const checkIfHasPassport = async () => {
    try {
      const checkIfHasPassportTxn = await accessTokenContract.checkIfHasNFT(profileAddress);
      console.log("Passport txn: ", checkIfHasPassportTxn);
      loadPassportData(checkIfHasPassportTxn);
    } catch (error) {
      alert("No passport found with this address");
      console.log("Error finding passport with this address: ", error);
    }
  };

  const loadPassportData = async (passportTxn) => {
    setCurrentPassportId(passportTxn[passportIndex].id.toNumber());
    setCurrentSoftClay(passportTxn[passportIndex].softClay);
    setCurrentWinChances(passportTxn[passportIndex].winChances);
    setCurrentRank(passportTxn[passportIndex].rank);
    setCurrentImage(passportTxn[passportIndex].cdnImage);
    // setCurrentWhitelistSpots(passportTxn[passportIndex].whitelistSpots);
    getPioneerLevel();
    getLegendLevel();
  };

  const getPioneerLevel = async () => {
    const getPioneerLevelTxn = await softClayContract.getPioneerLevel();
    console.log("New pioneer level: ", getPioneerLevelTxn);
    setPioneerLevel(getPioneerLevelTxn);
  };

  const getLegendLevel = async () => {
    const getLegendLevelTxn = await softClayContract.getLegendLevel();
    console.log("New legend level: ", getLegendLevelTxn);
    setLegendLevel(getLegendLevelTxn);
  };

  const getClaimableSoftClay = async () => {
    try {
      const getClaimableSoftClayTxn = await softClayContract.getClaimableClay(currentPassportId);
      console.log("Claimable soft clay txn: ", getClaimableSoftClayTxn);
      setCurrentClaimableSoftClay(getClaimableSoftClayTxn);
    } catch (error) {
      console.log("Error retrieving claimable soft clay: ", error);
    }
  };

  const getSoftClay = async () => {
    try {
      const getSoftClayTxn = await accessTokenContract.getSoftClay(currentPassportId);
      setCurrentSoftClay(getSoftClayTxn);
      console.log("Soft clay txn: ", getSoftClayTxn);
      updateRank();
    } catch (error) {
      console.log("Error retrieving soft clay: ", error);
    }
  };

  const updateRank = async () => {
    if(currentSoftClay >= pioneerLevel && currentSoftClay < legendLevel) {
      setCurrentRank("Pioneer");
    } else if (currentSoftClay >= legendLevel) {
      setCurrentRank("Legend");
    }
  };

  const claimSoftClay = async () => {
    setIsLoading(true);
    try {
      const claimSoftClayTxn = await softClayContract.userClaimSoftClay(currentPassportId);
      await claimSoftClayTxn.wait();
      console.log("Claim soft clay txn: ", claimSoftClayTxn);
      getSoftClay();
      getClaimableSoftClay();
    } catch (error) {
      console.log("Error claiming soft clay: ", error);
    }
    setIsLoading(false);
  };

  const redeemSoftClay = async () => {
    setIsLoading(true);
    try {
      const redeemSoftClayTxn = await softClayContract.redeemSoftClay(currentPassportId, reedeemSoftClayValue);
      await redeemSoftClayTxn.wait();
      console.log("Redeem soft clay txn: ", redeemSoftClayTxn);
      getSoftClay();
    } catch (error) {
      console.log("Error redeeming soft clay: ", error);
    }
    setIsLoading(false);
  };

  const progressBar = () => {
    if(currentSoftClay < pioneerLevel) {
      return (
        <Row>
          <Col>
            {currentRank}
          </Col>
          <Col>
            <ProgressBar now={currentSoftClay} max={pioneerLevel}/>
          </Col>
          <Col>
            Pioneer
          </Col>
        </Row>
      )
    } else if (currentSoftClay < legendLevel && currentSoftClay >= pioneerLevel) {
      return (
        <Row>
          <Col>
            {currentRank}
          </Col>
          <Col>
            <ProgressBar now={currentSoftClay} max={legendLevel}/>
          </Col>
          <Col>
            Legend
          </Col>
        </Row>
      )
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <Card.Header>Address Field</Card.Header>
              <Card.Body>
                <Form.Group className="d-inline-block">
                  <Form.Label>Enter Address</Form.Label>
                    <Form.Control 
                      type="string"
                      placeholder="0x..."
                      value={profileAddress}
                      onChange={(e) => setProfileAddress(e.target.value)}
                    />
                </Form.Group>
                  <Button onClick={() => checkIfHasPassport()}>Search</Button>
              </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
        {currentPassportId != 0 && (
          <Card>
            <Card.Header>Information</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Card.Title>Passport ID: {currentPassportId}</Card.Title>
                    <Card.Subtitle>Soft Clay: {currentSoftClay}</Card.Subtitle>
                    <Card.Subtitle>Claimable Soft Clay: {currentClaimableSoftClay}</Card.Subtitle>
                    <Card.Subtitle>Win Chances: {currentWinChances}</Card.Subtitle>
                    <Card.Subtitle>Rank: {currentRank}</Card.Subtitle>
                    <Card.Subtitle>Whitelist Spots: {currentWhitelistSpots}</Card.Subtitle>
                  </Col>
                  <Col>
                    {progressBar()}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Card.Img src={currentImage} className="img-fluid" />
                  </Col>
                  <Col>
                    <Button onClick={() => claimSoftClay()}>Claim Soft Clay</Button>
                  </Col>
                  <Col>
                    <Form.Group className="d-inline-block">
                      <Row>
                        <Col>
                          <Form.Label>Redeem Soft Clay</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Control 
                          type="number"
                          placeholder="0"
                          value={reedeemSoftClayValue}
                          onChange={(e) => setRedeemSoftClayValue(e.target.value)}
                        />
                      </Row>
                      <Row>
                        <Button onClick={() => redeemSoftClay()}>Redeem</Button>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
          </Card>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default GamerProfile;