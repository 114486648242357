import React, { useEffect, useState, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as Realm from "realm-web";
import "./UserPage.css";
import { getSuggestedQuery } from "@testing-library/react";

const SoftClayComponent = ({ setIsLoading, currentAccount, passportIndex, accessTokenContract, softClayContract}) => {

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });

  const [softClayAddress, setSoftClayAddress] = useState("");
  const [claimableSoftClayAddresses, setClaimableSoftClayAddresses] = useState([]);
  const [claimableSoftClayValues, setClaimableSoftClayValues] = useState([]);
  const [passportId, setPassportId] = useState(null);
  const [softClayValue, setSoftClayValue] = useState(0);
  const [user, setUser] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(JSON.parse(currentAccount));

  useEffect(() => {
    getUser(); 
  }, [])

  useEffect(() => {
    if(softClayAddress != "") {
      checkIfHasPassport();
    }
  }, [softClayAddress]);

  const handleSoftClayAddress = (e) => {
    setSoftClayAddress(e.target.value);
  };
  const handleSoftClayValue = (e) => {
    setSoftClayValue(e.target.value.toString());
  };

  // const getUser = async () => {
  //   const apiKey = process.env.REACT_APP_MONGOAPIKEY;
  //   const credentials = Realm.Credentials.apiKey(apiKey);
  //   try {
  //     // Authenticate user
  //     const user = await realmApp.logIn(credentials);
  //     setUser(user);
  //   } catch (error) {
  //     console.log("Error getting user: ", error);
  //   }
  // };

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  
  const checkIfHasPassport = async () => {
    try {
      const checkIfHasPassportTxn = await accessTokenContract.checkIfHasNFT(softClayAddress);
      console.log("Passport txn: ", checkIfHasPassportTxn);
      setPassportId(checkIfHasPassportTxn[passportIndex].id.toNumber());
    } catch (error) {
      console.log("Error finding passport with this address: ", error);
    }
  };

  // const updateSoftClay = async () => {
  //   setIsLoading(true);
  //   console.log("Soft Clay Val: ", softClayValue);
  //   if(softClayValue < 0) {
  //     try {
  //       const decreaseSoftClayTxn = await accessTokenContract.decreaseSoftClay(passportId, softClayValue*-1);
  //       console.log("Decrease soft clay txn: ", decreaseSoftClayTxn);
  //     } catch (error) {
  //       console.log("Error decreasing soft clay: ", error);
  //     }
  //   } else if (softClayValue > 0) {
  //     try {
  //       const increaseSoftClayTxn = await accessTokenContract.increaseSoftClay(passportId, softClayValue);
  //       console.log("Increase soft clay txn: ", increaseSoftClayTxn);
  //     } catch (error) {
  //       console.log("Error increasing soft clay: ", error);
  //     }
  //   } else if (softClayValue === 0) {
  //     alert("Enter a value for soft clay");
  //   }
  //   setIsLoading(false);
  //   console.log("Passport ID: ", passportId);
  //   console.log("Value: ", softClayValue);
  // };

  
  const setClaimableSoftClayParameters = async () => {
    if(softClayValue > 0) {
      setClaimableSoftClayAddresses(oldList => [...oldList, passportId]);
      setClaimableSoftClayValues(oldList => [...oldList, softClayValue]);
      setSoftClayAddress("");
      setPassportId(null);
      setSoftClayValue(0);
    } else {
      alert("Enter a positive value for claimable soft clay");
    }
  };

  const awardClaimableSoftClay = async () => {
    setIsLoading(true);
    console.log("Claimable Soft Clay Addresses: ", claimableSoftClayAddresses);
    console.log("Claimable Soft Clay Values: ", claimableSoftClayValues);
    try {
      const awardClaimableSoftClayTxn = await softClayContract.addClaimableSoftClay(claimableSoftClayAddresses, claimableSoftClayValues);
      await awardClaimableSoftClayTxn.wait();
      console.log("Award claimable soft clay txn: ", awardClaimableSoftClayTxn);
    } catch (error) {
      console.log("Error awarding claimable soft clay: ", error);
    }
    setIsLoading(false);
  };

  return (
    <Card>
      <Card.Header>Soft Clay</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Row>
                  <Col>
                    <Form.Label>Address</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-button-1">Enter address to award soft clay</Tooltip>}
                  >
                    <Form.Control 
                      type="string"
                      placholder="0x..."
                      value={softClayAddress}
                      onChange={handleSoftClayAddress}
                    />
                  </OverlayTrigger>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Body>
          <Row>
            <Col className="d-flex justify-content-center">
              <Form.Group className="mb-3">
                <Row>
                  <Col>
                    <Form.Label className="p-0">Value</Form.Label>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col className="p-0">
                    <Button onClick={() => setSoftClayValue(softClayValue-1)}>-</Button>
                  </Col>
                  <Col className="p-0">
                    <Form.Control 
                      type="number"
                      placholder="0"
                      value={softClayValue}
                      onChange={handleSoftClayValue}
                    />
                  </Col>
                  <Col  className="p-0">
                    <Button onClick={() => setSoftClayValue(softClayValue+1)}>+</Button>
                  </Col>
                </Row>
              {/* </Form.Group> */}
                <Row xs={3} className="justify-content-center">
                  {/* <Col>
                    <Button className="btn-margin" onClick={() => updateSoftClay()}>Update soft clay</Button>
                  </Col> */}
                  <Col>
                    <Row className="mb-3">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-button-1">Add address & value to list of claimable soft clay</Tooltip>}
                      >
                        <Button onClick={() => setClaimableSoftClayParameters()}>Add claimable</Button>
                      </OverlayTrigger>
                    </Row>
                    <Row>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-button-1">Allow added users to claim their soft clay</Tooltip>}
                      >
                        <Button onClick={() => awardClaimableSoftClay()}>Award claimable soft clay</Button>
                      </OverlayTrigger>
                    </Row>
                  </Col>
                </Row>
                </Form.Group>
            </Col>
          </Row>
        </Card.Body>
    </Card>
  );
};

export default SoftClayComponent;