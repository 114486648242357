import { useState, useEffect, useRef} from "react";
import "./SideBar.css";
import {Card, Button, Row, Col} from 'react-bootstrap';
import { transformPannelData } from "../../constants";
import * as Realm from "realm-web";
import * as Icon from "react-bootstrap-icons";
import MetropolisIcon from "../../assets/MetropolisIcon.png";
import { keccak256, toUtf8Bytes } from "ethers/lib/utils";
import { useLoaderData } from "react-router-dom";

const SideBar = ({setDetailsPage, setUserPage, setPannel, collapseSideBar, setCollapseSideBar, sideBarItemPages, setSideBarItemPages, accessTokenContract, currentWallet, showUnused}) => {

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [pannels, setPannels] = useState([]);
  const loadedPannels = useRef(false);

  // CSS states
  const [selectedPannel, setSelectedPannel] = useState("Home");
  const currentPannelRef = useRef(null);

  /****************** Subpannels ******************/ 
  const [isCollapsed, setIsCollapsed] = useState(collapseSideBar);
  const [collapseSubPannels, setCollapseSubPannels] = useState({
    "Home": false,
    "passport": false,
    "city1": false,
    "citizens": false,
    "customer_profile": false,
    "activations": false,
  });
  // Allows access to passport admin subpannel
  const [isUpdater, setIsUpdater] = useState(false);
  const [selectedSubPannel, setSelectedSubPannel] = useState("");
  const currentPassportSubPannelRef = useRef(null);
  const currentCity1SubPannelRef = useRef(null);
  const currentCitizenSubPannelRef = useRef(null);

  useEffect(() => {
    const getPannels = async () => {
      console.log("getting pannels");
      const apiKey = process.env.REACT_APP_MONGOAPIKEY;
      const credentials = Realm.Credentials.apiKey(apiKey);
      try {
        // Authenticate the user
        const user = await realmApp.logIn(credentials);
        const pannelList = await user.functions.getPannels();
        console.log("pannel list is:", pannelList);
        if (pannelList) {
          let tempArr = [];
          for(let i = 0; i < pannelList.length; i++) {
            if(pannelList[i].page === "passport" || pannelList[i].page === "customer_profile" || pannelList[i].page === "activations") {
              continue;
            } else {
              tempArr.push(pannelList[i]);
            }
          }
          console.log("setting the state");
          setPannels(tempArr);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getPannels();
    console.log(pannels);
  }, []);

  useEffect(() => {
    console.log("pannels updated: ", pannels);
  }, [pannels]);

  const goHome = () => {
    setUserPage(false);
    setDetailsPage(false);
    setSelectedPannel("Home");
  };

  useEffect(() => {
    loadData();
  })

  const loadData = () => {
    handleSelectedPannel();
  }

  // Skip triggering useEffect on initial render
  const useFirstRender = () => {
    const firstRender = useRef(true);

    useEffect(() => {
      firstRender.current = false;
    }, [])

    return firstRender.current;
  };

  const first = useFirstRender();

  const showDetails = (p) => {
    setDetailsPage(true);
    setPannel(p);
    setSelectedPannel(p);
    showSubPannels(p);
  };

  const showSubPannels = (pannel) => {
    // Find all subpannels that are open (true)
    const openSubPannels = Object.keys(collapseSubPannels).filter(key => collapseSubPannels[key] === true);
    for(let i = 0 ; i< openSubPannels.length; i++) {
      // Close a subpannel if it is open and not the new one that was clicked 
      if(openSubPannels[i] !== pannel) {
        collapseSubPannels[openSubPannels[i]] = false;
      }
    }
    // Swap between open or close on the subpannel that was clicked
    // Also sets the state for the changes made in the for loop
    setCollapseSubPannels({...collapseSubPannels, [pannel]: !collapseSubPannels[pannel]});
    console.log("collapse: ", collapseSubPannels);
  }
 
  // useEffect(() => {
  //   getRoles();
  // }, []);

  useEffect(() => {
    if(!first){
      handleCollapseSideBar();
    }
  }, [collapseSideBar]);

  useEffect(() => {
    handleSelectedPannel();
  }, [selectedPannel]);

  useEffect(() => {
    handleSubPannels();
  }, [selectedSubPannel]);

  // Reselects current subpannel when sidebar expands
  useEffect(() => {
    if(!isCollapsed) {
      handleSubPannels();
    }
  }, [isCollapsed]);

  const handleSelectedPannel = () => {
    const currentPannel = document.getElementById(selectedPannel);
    // console.log("Sidebar item pages: ", sideBarItemPages);
    // If the current pannel is not the same as the previous one (currentPannelRef), remove the selected-pannel class from previous one 
    if(currentPannelRef.current !== currentPannel) {
      if(currentPannelRef.current) {
        currentPannelRef.current.classList.remove("selected-pannel");
      }
    }
    // If the current pannel exists, add the selected-pannel class to it and set it as the current pannel
    if(currentPannel) {
      currentPannelRef.current = currentPannel;
      currentPannel.classList.add("selected-pannel");
    }
    handleSubPannels();
  }

  const handleSubPannels = () => {
    const currentPassportSubPannel = document.getElementById(sideBarItemPages.passportPage+"-subpannel");
    const currentCity1SubPannel = document.getElementById(sideBarItemPages.cityPage+'-subpannel');
    const currentCitizenSubPannel = document.getElementById(sideBarItemPages.citizensPage+'-subpannel');

    if(currentPassportSubPannelRef.current !== currentPassportSubPannel) {
      if(currentPassportSubPannelRef.current) {
        currentPassportSubPannelRef.current.classList.remove("selected-subpannel");
      }
    } 
    if(currentPassportSubPannel) {
      currentPassportSubPannel.classList.add("selected-subpannel");
      currentPassportSubPannelRef.current = currentPassportSubPannel;
    }

    if(currentCity1SubPannelRef.current !== currentCity1SubPannel) {
      if(currentCity1SubPannelRef.current) {
        currentCity1SubPannelRef.current.classList.remove("selected-subpannel");
      }
    }
    if(currentCity1SubPannel) {
      currentCity1SubPannel.classList.add("selected-subpannel");
      currentCity1SubPannelRef.current = currentCity1SubPannel;
    }

    if(currentCitizenSubPannelRef.current !== currentCitizenSubPannel) {
      if(currentCitizenSubPannelRef.current) {
        currentCitizenSubPannelRef.current.classList.remove("selected-subpannel");
      }
    }
    if(currentCitizenSubPannel) {
      currentCitizenSubPannel.classList.add("selected-subpannel");
      currentCitizenSubPannelRef.current = currentCitizenSubPannel;
    }
  }

  const handleCollapseSideBar = async () => {
    const sideBar = document.getElementById("sidebar");
    const btnName = document.getElementsByClassName("btn-name");
    const brandName = document.getElementById("brand-name");    
      sideBar.classList.toggle("sidebar-small");
      if(!collapseSideBar) {
        // Delays the toggle of the brand and button names to match the animation of the sidebar
        setTimeout(() => {
          brandName.classList.toggle("brand-name-small");
            for(let i = 0; i < btnName.length; i++) {
              btnName[i].classList.toggle("btn-name-small");
            }
            setIsCollapsed(collapseSideBar);
        }, 900);
      } else {
        brandName.classList.toggle("brand-name-small");
          for(let i = 0; i< btnName.length; i++) {
            btnName[i].classList.toggle("btn-name-small");
          }
          setIsCollapsed(collapseSideBar);
        }
  }

  // Updates the current page to be displayed
const updatePages = (page, index) => {
  if(index === 0) { 
    let newArr = {...sideBarItemPages};
    newArr.passportPage = page;
    setSideBarItemPages(newArr);
  } else if(index === 1) {
    let newArr = {...sideBarItemPages};
    newArr.cityPage = page;
    setSideBarItemPages(newArr);
  } else if (index === 2) {
    let newArr = {...sideBarItemPages};
    newArr.citizensPage = page;
    setSideBarItemPages(newArr);
  }
  setSelectedSubPannel(page+'-subpannel');
};

// const getRoles = async () => {
//   const UPDATER_ROLE = keccak256(toUtf8Bytes("UPDATER_ROLE"));
//   try {
//     const hasRoleTxn = await accessTokenContract.hasRole(UPDATER_ROLE, currentWallet);
//     console.log("Has role: ", hasRoleTxn);
//     setIsUpdater(hasRoleTxn);
//   } catch (error) {
//     console.log("Error retrieving roles: ", error);
//   }
// };

  const renderPannels = () => {
    return (
      <div>
        {pannels.map((pannel, index) => (
          <div key={index} id="content" className="sidebar-content">
            <Button
              className="sidebar-btn"
              id={pannel.page}
              value={pannel.page}
              onClick={(e) => showDetails(pannel.page)}
            >
              <div className="selected-bar"></div>
              {/* Displays specific icons within the button */}
              {pannel.page === "passport" ? (
                  <Icon.Files className="icon-container"/>
                ) : (pannel.page === "city1") ?  (
                  <Icon.Building className="icon-container"/>
                ) : (pannel.page === "citizens") ? (
                  <Icon.People className="icon-container"/>
                ) : (pannel.page === "customer_profile") ? (
                  <Icon.PersonCircle className="icon-container"/>
                ) : (pannel.page === "activations") ? (
                  <Icon.CalendarEvent className="icon-container" />
                ) : (
                  <></>
                )
            }
              <span className="btn-name">{pannel.headline}</span>
            </Button>

            {selectedPannel === "passport" && pannel.page === "passport" && collapseSubPannels.passport === true && !isCollapsed ? (
                <div id="subpannels-container" className="subpannels-container">
                  {/* <ul className="subpannels">
                    <li id="token-subpannel">
                      <button onClick={() => updatePages("token", 0)} className="subpannel-btn">Token</button>
                    </li>
                    <li id="payment-subpannel">
                      <button onClick={() => updatePages("payment", 0)} className="subpannel-btn">Payment</button>
                    </li>
                    <li id="whitelist-subpannel">
                      <button onClick={() => updatePages("whitelist", 0)} className="subpannel-btn">Whitelist</button>
                    </li>
                    <li id="gamerProfile-subpannel">
                      <button onClick={() => updatePages("gamerProfile", 0)} className="subpannel-btn">Gamer Profile</button>
                    </li>
                    <li id="user-subpannel">
                      <button onClick={() => updatePages("user", 0)} className="subpannel-btn">Users</button>
                    </li>
                    {isUpdater ? (
                    <li id="admin-subpannel">
                      <button onClick={() => updatePages("admin", 0)} className="subpannel-btn">Admin</button>
                    </li>
                    ) : <></>}
                  </ul> */}
                </div>
              ) : (selectedPannel === "city1" && pannel.page === "city1" && collapseSubPannels.city1 === true && !isCollapsed) ? (
                <div id="subpannels-container" className="subpannels-container">
                  <ul className="subpannels">
                    <li id="properties-subpannel">
                      <button onClick={() => updatePages("properties", 1)} className="subpannel-btn">Properties</button>
                    </li>
                    <li id="collectables-subpannel">
                      <button onClick={() => updatePages("collectables", 1)} className="subpannel-btn">Collectables</button>
                    </li>
                    <li id="eggs-subpannel">
                      <button onClick={() => updatePages("eggs", 1)} className="subpannel-btn">Eggs</button>
                    </li> 
                  </ul>
                </div>
              ) : (selectedPannel === "citizens" && pannel.page === "citizens" && collapseSubPannels.citizens === true && !isCollapsed) ? (
                <div id="subpannels-container" className="subpannels-container">
                  <ul className="subpannels">
                    <li id="avatars-subpannel">
                      <button onClick={() => updatePages("avatars", 2)} className="subpannel-btn">Avatars</button>
                    </li>
                    <li id="ethereals-subpannel">
                      <button onClick={() => updatePages("ethereals", 2)} className="subpannel-btn">Ethereals</button>
                    </li>
                  </ul>
                </div>
                // Unused now, but these may be needed later: 
              ) : (selectedPannel === "customer_profile" && pannel.page === "customer_profile" && collapseSubPannels.customer_profile === true && !isCollapsed) ? (
                <div id="subpannels-container" className="subpannels-container">
                </div>
              ) : (selectedPannel === "activations" && pannel.page === "activations" && collapseSubPannels.activations === true && !isCollapsed) ? (
                <div id="subpannels-container" className="subpannels-container">
                </div>
              ) : (
                <></>
              )
            }

          </div>
        ))}
        {loadedPannels.current = true}
      </div>
      )
  };

  return (
    <Col id="sidebar" className="p-0 ml-2" md={{span: 2}}>
    {/* <div id="sidebar">  */}
    <div className="sidebar-div">
      <div className="sidebar-content-container">
        <div className="brand">
          <img
            alt=""
            src={MetropolisIcon}
            width="20"
            height="20"
            className="d-inline-block align-top"
          />{" "}
          <h6 id="brand-name">Mission Control</h6>
        </div>
        {/* Styling: Wait for pannels to load before rendering home button */}
        {loadedPannels.current === true ? (
          <div id="content" className="sidebar-content">
            <Button id="Home" className="sidebar-btn" onClick={goHome}>
            <div className="selected-bar"></div>
                <Icon.House className="icon-container"/>            
                  <span className="btn-name">Home</span>
            </Button>
          </div>
        ) : <></>}
        {renderPannels()}
        <div>
          {/* {loadedPannels.current === true ? ()} */}
          {collapseSideBar ? (
            <Button onClick={() => setCollapseSideBar(!collapseSideBar)}><Icon.ArrowBarRight /></Button>
          ) : (!collapseSideBar) ? (
            <Button onClick={() => setCollapseSideBar(!collapseSideBar)}><Icon.ArrowBarLeft /></Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
    {/* </div>  */}
    </Col> 
  )
}

export default SideBar