import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import * as Realm from "realm-web";
import { Button, Row, Col, Form, Modal, Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import LoadingIndicator from "../../../LoadingIndicator";
import Uploader from "./Components/Upload/Uploader";
import Assets from "./Components/Assets/index.js";
import Statistics from "./Components/Statistics/index.js";


const AvatarsPage = ({ currentAccount }) => {

  const translationImageCellRenderer = (params) => {
    // const url = "https://ik.imagekit.io/metropolis/Avatars/Centred/tr:h-150/" + params.value;
    const url = "https://ik.imagekit.io/metropolis/" + params.value + "/tr:h-150";
    return (
      <div>
        <p className="m-0">{params.value}</p>
        <img 
          className="mb-2"
          src={url}
        />
      </div>
    )
  };

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [loggedInUser, setLoggedInUser] = useState(JSON.parse(currentAccount));
  const [isLoading, setIsLoading] = useState(false);
  const [translations, setTranslations] = useState(null);
  const [accessories, setAccessories] = useState(null);
  const [combinedAssets, setCombinedAssets] = useState(null);
  const [accessoryRowData, setAccessoryRowData] = useState(null);

  const [changedRowData, setChangedRowData] = useState([]);
  const [changedAccessoryData, setChangedAccessoryData] = useState([]);

  const [showNewModal, setShowNewModal] = useState(false);
  const [newTranslation, setNewTranslation] = useState({
    code: -1,
    id: -1,
    fileName: "",
    customerName: "",
    thumbnail: "",
    layer: "",
    type: "",
    supplyFromRandoms: -1,
  })
  const gridRef = useRef();

  const defaultColDef = useMemo(() => ({
    sortable: true,
    editable: false,
    resizable: true,
    filter: 'agTextColumnFilter',
    width: "auto",
    filterParams: {buttons: ['apply', 'cancel', 'reset'], closeOnApply: true}, 
  }));

  const [columnsData, setColumnsData] = useState([
    {field: 'fileName', cellRenderer: translationImageCellRenderer, editable: true},
    {field: 'thumbnail', cellRenderer: translationImageCellRenderer},
    {field: 'name', editable: true},
    {field: "layer"},
    {field: 'type'},
    {field: 'description', editable: true},
  ]);

  const accessoryColumnData = [
    {field: 'thumbnail', cellRenderer: translationImageCellRenderer, editable: true},
    {field: 'name', editable: true},
    {field: 'price', editable:  true},
    {field: 'supply', editable: true},
    {field: 'layer'},
    {field: 'gender'},
    {field: 'type'},
    {field: 'createdBy'}
  ]

  // const onFirstDataRendered = useCallback(() => {
  //   const allColumnIds = [];
  //   gridRef.current.columnApi.getColumns().forEach((column) => {
  //     allColumnIds.push(column.getId());
  //   });
  //   gridRef.current.columnApi.autoSizeColumns(allColumnIds);
  // }, []);

  const onCellValueChanged = async (e) => {
    console.log("row data: ", changedRowData);
    // setChangedRowData(e.data);
    if(changedRowData.find(item => item.fileName === e.data.fileName)) {
      const items = changedRowData.find(item => item.fileName === e.data.fileName);
      console.log("Items: ", items);
      const index = changedRowData.indexOf(items);

      // Temp variable to hold the current state of changed data. 
      let temp_state = [...changedRowData];
      // Temp variable to hold the current row we are updating.
      let temp_element = {...temp_state[index]};
      // Update the temp row with the new data.
      temp_element = e.data;
      // Set the old row to the new row.
      temp_state[index] = temp_element;
      // Update state
      setChangedRowData(temp_state);

      console.log("Changed data: ", changedRowData);
    } else {
      // Else if row data not already included, add it. 
      // setChangedRowData(prevRow => [...prevRow, e.data]);
      const tempArr = [...changedRowData, e.data];
      console.log("tempArr: ", tempArr);
      setChangedRowData(tempArr);
      // setChangedRowData([...changedRowData, e.data]);
    }
  };

  const onAccessoryCellValueChanged = async (e) => {
    console.log("row data: ", changedAccessoryData);
    console.log("e", e);
    e.data.price = parseFloat(e.data.price);
    e.data.supply = parseFloat(e.data.supply);
    // setChangedAccessoryData(e.data);
    if(changedAccessoryData.find(item => item.fileName === e.data.fileName)) {
      const items = changedAccessoryData.find(item => item.fileName === e.data.fileName);
      console.log("Items: ", items);
      const index = changedAccessoryData.indexOf(items);

      // Temp variable to hold the current state of changed data. 
      let temp_state = [...changedAccessoryData];
      // Temp variable to hold the current row we are updating.
      let temp_element = {...temp_state[index]};
      // Update the temp row with the new data.
      temp_element = e.data;
      // Set the old row to the new row.
      temp_state[index] = temp_element;
      // Update state
      setChangedAccessoryData(temp_state);

      console.log("Changed data: ", changedAccessoryData);
    } else {
      // Else if row data not already included, add it. 
      // setChangedAccessoryData(prevRow => [...prevRow, e.data]);
      const tempArr = [...changedAccessoryData, e.data];
      console.log("tempArr: ", tempArr);
      setChangedAccessoryData(tempArr);
      // setChangedAccessoryData([...changedAccessoryData, e.data]);
    }
  };

  const clearFilter = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.setQuickFilter('');
    document.getElementById('filter-text-box').value = '';
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      const translations = await getTranslations();
      setTranslations(translations)
      const accessories = await getAccessories();
      setAccessories(accessories);
      const combinedAssets = await combineAssets(translations, accessories);
      setCombinedAssets(combinedAssets);
      // await handleAccessoryRowData(translations, accessories);
    }
    initializeData().catch(error => console.log("Error initializing data: ", error));
  }, []);

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  const getTranslations = async () => {
    // setIsLoading(true);
    const user = await getUser();
    try {
      const translations = await user.functions.get_translations();
      setTranslations(translations);
      console.log("translations: ", translations);
      return translations;
    } catch (error) {
      console.log("Error getting translations: ", error);
    }
  };

  const getAccessories = async () => {
    const user = await getUser();
    const accessories = await user.functions.getAvatarAccessories();
    console.log("Accessories: ", accessories);
    setAccessories(accessories);
    return accessories;
  };

  const updateTranslation = async () => {
    setIsLoading(true);
    try {
      const user = await getUser();
      for(let i=0; i < changedRowData.length; i++) {
        // const updateCustomerNameTxn = await user.functions.update_translation(changedRowData[i].filename, changedRowData[i].customerName, changedRowData[i].price, changedRowData[i].supply, changedRowData[i].description);
        // const userLogTxn = await user.functions.user_log(loggedInUser.name, "update_translation", {code: changedRowData[i].code, customer_facing: changedRowData[i].customerName, price: changedRowData[i].price, supply: changedRowData[i].supply, description: changedRowData[i].description});
        // console.log("for ", i, "changedData: ", changedRowData[i]);
        const updateCustomerNameTxn = await user.functions.update_translation_images(changedRowData[i].fileName, changedRowData[i].name, changedRowData[i].price, changedRowData[i].supply, changedRowData[i].description);
      }
      clearAfterUpdate();
    } catch (error) {
      console.log("Error updating customer name: ", error);
    }
    setIsLoading(false);
  };



  // I want to display price and supply accessory data so the team can update those values
  // But I also want to display the thumbnail image
  // I think it is worth it to merge translations and accessory properties into one array of objects
  // Then set the rowData to that array of objects instead of trying to combine them in real time
  const handleAccessoryRowData = async (translations, accessories) => {
    var tempArr = [];
    var tempObj = {};
    for(let i=0; i < accessories.length; i++) {
      translations.find((item) => {
        if(item.code === accessories[i].translationCode) {
          tempObj = {...accessories[i]};
          tempObj.thumbnail = item.thumbnail;
          tempObj.fileName = item.fileName;
          tempObj.name = item.name;
          tempArr.push(tempObj);
        }
      })
    }
    console.log("temp array: ", tempArr);
    setAccessoryRowData(tempArr);
    return tempArr;
  };

  const updateAccessory = async () => {
    for(let i=0; i < changedAccessoryData.length; i++) {
      console.log("Changed accessory data: ", changedAccessoryData[i]);
      accessories.find((item) => {
        if(item.id === changedAccessoryData[i].id) {
          item.price = changedAccessoryData[i].price;
          item.supply = changedAccessoryData[i].supply;
          console.log("New item: ", item);
        }
      })
    }
  }

  const clearAfterUpdate = () => {
    setChangedRowData([]);
    console.log("cleared data");
  }

  const combineAssets = async (translations, accessories) => {
    let tempArr = [];
    for(let i=0; i < translations.length; i++) {
      var matchedAccessory = accessories.find(accessory => accessory.translationCode === translations[i].code);
      if(matchedAccessory) {
        matchedAccessory.fileName = translations[i].fileName;
        matchedAccessory.name = translations[i].name;
        matchedAccessory.thumbnail = translations[i].thumbnail;
        matchedAccessory.code = translations[i].code;
        // console.log("Matched accessory: ", matchedAccessory);
        tempArr.push(matchedAccessory);
      }
    }
    // Sorts the array in ascending order by code
    var newAssets = tempArr.sort(function(a,b){return a.code - b.code});
    return newAssets;
  }

  return (
    <div>
      <h1>Avatars</h1>
      <Modal show={isLoading}>
        <LoadingIndicator />
      </Modal>
      <div>
        <Statistics translations={translations} accessories={accessories} combinedAssets={combinedAssets}/>
      </div>

      <div>
        {/* {combinedAssets && (
          <Assets getUser={getUser} translations={translations} accessories={accessories} combinedAssets={combinedAssets}/>
        )} */}
      </div>
      <Card>
        <Row>
            <Form.Group className="m-3">
              <Row className="d-flex align-items-center">
                <Col md={4} className="p-0 m-1">
                  <Form.Control 
                    type="text"
                    placeholder="Search"
                    id="filter-text-box"
                    onChange={(e) => gridRef.current.api.setQuickFilter(e.target.value)}
                  />
                </Col>
                <Col xs={1}>
                  <Button onClick={() => clearFilter()}>Clear</Button>
                </Col>
              </Row>
            </Form.Group>
        </Row>
        <div role="Spreadsheet div" className="ag-theme-alpine" style={{width: '100%', height: 500}}>
        <AgGridReact
          ref={gridRef} 
          rowData={translations}
          rowHeight={200}
          rowSelection='single'
          columnDefs={columnsData}
          defaultColDef={defaultColDef}
          onCellValueChanged={onCellValueChanged}
          // onFirstDataRendered={onFirstDataRendered}
        />
      </div>
        <Row>
          <Col className="d-flex align-items-center justify-content-left">
            <Button onClick={() => updateTranslation()}>Update</Button>
          </Col>
        </Row>
      </Card>
    </div>
  )
};

export default AvatarsPage;