import React, { useEffect, useState } from "react";
import { CONTRACT_ADDRESS, transformPassportData } from "../../constants";
import MetropolisWorldAccessToken from "../../utils/AccessToken.json";
import { ethers } from "ethers";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { transformPannelData } from "../../constants";
import * as Realm from "realm-web";
import { Button } from "react-bootstrap";
import LoadingIndicator from "../LoadingIndicator";
import Confirmation from "../Confirmation";
import { render } from "@testing-library/react";
import SpreadsheetData from "./Components/SpreadsheetPage/SpreadsheetData.js";
import UploadToIPFSPage from "./Components/UploadToIPFSPage/UploadToIPFSPage";
import Collectables from "./Components/Collectables";
import Eggs from "./Components/Eggs";

const CityPage = ({ currentAccount, pannel, collapseSideBar, setCollapseSideBar, sideBarItemPages, setSideBarItemPages, eggCollectionContract }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [ipfsBool, setIpfsBool] = useState(false);
  const [imgBool, setImgBool] = useState(false);
  const [confMsg, setConfMsg] = useState(null);
  const [addedToDb, setAddedToDb] = useState(null);
  const [missingInput, setMissingInput] = useState(null);

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const nftRealmApp = new Realm.App({ id: process.env.REACT_APP_NFTDROPAPP });
  const [user, setUser] = useState(null);
  const [dropUser, setDropUser] = useState(null);
  const [showProperties, setShowProperties] = useState(false);
  const [properties, setProperties] = useState(null);
  const [testerProperties, setTesterProperties] = useState(null);

  const [pageSelection, setPageSelection] = useState(0);

  const [showIPFSModal, setShowIPFSModal] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const ifpsChange = (e) => {
    setIpfsBool(e.target.value);
  };
  const imgKitChange = (e) => {
    setImgBool(e.target.value);
  };

  useEffect(() => {
    loadCityPageData();
  }, [])

  const loadCityPageData = async () => {
    getUser();
    getNFTDropUser();
  };

  useEffect(() => {
    if(user != null) {
      getAllProperties();
      // getAllTesterProperties();
    }
  }, [user])

  useEffect(() => {
    if(dropUser != null) {
      // getAllProperties();
    }
  }, [dropUser])

  // const uploadMetaData = (e) => {
  //   console.log(typeof(ipfsBool));
  //   console.log(typeof(imgBool));
  //   console.log(typeof(pannel));
  //   console.log(typeof(selectedFile));
  //   setIsLoading(true);
  //   const sc = process.env.REACT_APP_FAST_KEY;
  //   let formData = new FormData();
  //   formData.append("file", selectedFile);
  //   formData.append("ipfs", ipfsBool);
  //   formData.append("image_kit", imgBool);
  //   formData.append("city", pannel);
    
  //   fetch("https://pythonapi.metropolisworld.io/addCitydata", {
  //     method: "POST",
  //     headers: {
  //       // "Content-Type": "multipart/form-data",
  //       "secretkey": sc,
  //     },
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("result: ", result);
  //       setConfMsg(result);
  //       console.log("ConfMsg: ", confMsg);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       setIsLoading(false);
  //     });
  // };

  // const renderFeedback = () => {
  //   if(confMsg["added to db"]) {
  //     setAddedToDb(true);
  //   }
  //   else if(!confMsg["added to db"]) {
  //     setAddedToDb(false);
  //   }
  //   if(confMsg["missing Columns"]) {
  //     setMissingInput(confMsg["missing Columns"]);
  //     console.log("Columns: ", JSON.stringify(missingInput));
  //   }
  //   if (confMsg["missing images"]) {
  //     setMissingInput(confMsg["missing images"]);
  //     console.log("Images:", missingInput);
  //   }
  // };

  const getUser = async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      setUser(user);
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  };

  const getNFTDropUser = async () => {
    const nftDropApiKey = process.env.REACT_APP_NFTDROPKEY;
    const nftDropCredentials = Realm.Credentials.apiKey(nftDropApiKey);
    try {
      const nftDropUser = await nftRealmApp.logIn(nftDropCredentials);
      setDropUser(nftDropUser);
    } catch (error) {
      console.log("Error getting nft drop user: ", error);
    }
  };

  const getAllProperties = async () => {
    setIsLoading(true);
    try {
      const getAllPropertiesTxn = await user.functions.all_properties();
      // const getAllPropertiesTxn = await dropUser.functions.getAllProperties();
      setProperties(getAllPropertiesTxn);
      console.log("Properties: ", properties);
    } catch (error) {
      console.log("Error retreiving properties: ", error);
    }
    setIsLoading(false);
  };

  return (
    // <Card>
    //   <Card.Header>
    //     <Card.Title>{pannel}</Card.Title>
    //   </Card.Header>
    //   <Card.Body>
    //     <SpreadsheetData setIsLoading={setIsLoading} user={user} testerProperties={testerProperties} properties={properties} showProperties={showProperties} setShowProperties={setShowProperties}/>
    //   </Card.Body>
    //   <Card.Body>
    //     <UploadToIPFSPage setIsLoading={setIsLoading} showIPFSModal={showIPFSModal} setShowIPFSModal={setShowIPFSModal}/>
    //   </Card.Body>
    // </Card>
    <div>
      <Row>
        {/* <Col>
          <button>Update</button>
        </Col>
        <Col>
          <Button>Collectables</Button>
        </Col> */}
      </Row>
      <Row>
        <Col>
          {sideBarItemPages.cityPage === "" ? (
            <h1>Main page</h1>
          ) : (sideBarItemPages.cityPage === "properties") ? (
            <SpreadsheetData setIsLoading={setIsLoading} user={user} testerProperties={testerProperties} properties={properties} showProperties={showProperties} setShowProperties={setShowProperties}/>
          ): (sideBarItemPages.cityPage === "collectables") ? (
            <Collectables currentAccount={currentAccount} properties={properties}/>
          ) : (sideBarItemPages.cityPage === "eggs") ? (
            <Eggs eggCollectionContract={eggCollectionContract}/>
          ): (
            <></>
          )}

          {/* <SpreadsheetData setIsLoading={setIsLoading} user={user} testerProperties={testerProperties} properties={properties} showProperties={showProperties} setShowProperties={setShowProperties}/> */}
        </Col>
      </Row>
    </div>
  );
};
export default CityPage;

{/* <Card.Body>
        <Row xs={1} md={2} className="g-4">
          <Col>
            <Card>
              {isLoading && <LoadingIndicator />}
              <Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id="ipfs-switch"
                      label="Add Images to IPFS"
                      onChange={ifpsChange}
                    />
                    <Form.Check
                      type="switch"
                      id="cdn-switch"
                      label="Add Images to Image Kit(CDN)"
                      onChange={imgKitChange}
                    />
                    <Form.Control type="file" onChange={changeHandler} />
                  </Form.Group>
                  <Form.Text id="passwordHelpBlock" muted>
                    You should choose a excel sheet with a single sheet and all the correct columns.
                  </Form.Text>
                </Form>
                <Button onClick={uploadMetaData} disabled={isLoading}>
                  Add Metadata to DB
                </Button>
                <Button onClick={() => getAllProperties()}>Get</Button>
                <Row className="mb-3">
                <Button onClick={() => setShowProperties(true)}>View spreadsheet</Button>
                </Row>
                <Row className="mb-3">
                <Button onClick={() => setShowIPFSModal(true)}>Upload to IPFS</Button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body> */}
