import { useState, useEffect, useCallback, useRef } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import * as Realm from "realm-web";
import CollectablePage2 from "./CollectablePage2";
import CollectablePage3 from "./CollectablePage3";
import "./Collectables.css";


const Collectables = ({ currentAccount, properties }) => {

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [newCollectable, setNewCollectable] = useState({
    id: "",
    name: "",
    link: "",
    type: "",
    icon: "",
    category: ""
  });
  const [allCollectables, setAllCollectables] = useState(null);
  const [collectablePage, setCollectablePage] = useState(1);
  const [selectedCollectable, setSelectedCollectable] = useState(null);
  const [username, setUsername] = useState(JSON.parse(currentAccount).name);

  // Skip triggering useEffect on initial render
  const useFirstRender = () => {
    const firstRender = useRef(true);

    useEffect(() => {
      firstRender.current = false;
    }, [])

    return firstRender.current;
  };

  const first = useFirstRender();

  useEffect(() => {
    refreshAllCollectables();
  }, []);

  useEffect(() => {
    if(!first){
      resetNewCollectable();
    }
  }, [allCollectables]);
  

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  const refreshAllCollectables = async () => {
    const user = await getUser();
    try {
      const refreshCollectablesTxn = await user.functions.getAllCollectables();
      console.log("refresh collectables txn: ", refreshCollectablesTxn);
      setAllCollectables(refreshCollectablesTxn);
      return refreshCollectablesTxn;
    } catch (error) {
      console.log("Error refreshing collectables: ", error);
    }
  };

  const submitCollectable = async () => {
    const user = await getUser();
    try {
      const newCollectableTxn = await user.functions.add_collectable(newCollectable.id, newCollectable);
      console.log("collectable trying to add: ", newCollectable);
      console.log("New collectable added: ", newCollectableTxn);
      const userLog = await user.functions.user_log(username, "add_collectable", {id: newCollectable.id, asset: newCollectable});
    } catch (error) {
      console.log("Error submitting collectable: ", error);
    }
  }

  const resetNewCollectable = () => {
    const newCollectableId = allCollectables[allCollectables.length-1].id + 1;
    setNewCollectable({...newCollectable, id: newCollectableId});
  };

  return (
    <div>
      {collectablePage === 1 ?(
      <div>
      <h1>Collectables</h1>

        {/* <Button onClick={() => console.log(newCollectable)}>Log</Button> */}
        <Button onClick={() => submitCollectable()}>Submit</Button>

        {/* Require allCollectables to be set before next page can be accessed */}
        {allCollectables ? (<Button onClick={() => setCollectablePage(2)}>Next</Button>) : 
        (<Button onClick={() => setCollectablePage(2)} disabled>Next</Button>)}


        <form className="form-container">
          <div className="form-input">
            {/* <label for="collectable-id">ID</label> */}
            <input 
              id="collectable-id" 
              className="newCollectableId" 
              type="number"
              placeholder="ID" 
              value={newCollectable.id || ""}
              onChange={(e) => setNewCollectable({...newCollectable, id: e.target.value})} 
            />
          </div>
          <div className="form-input">
            {/* <label for="collectable-name">Name</label> */}
            <input 
              type="text"
              placeholder="Name"
              id="collectable-name"
              // value={newCollectable.name || ""}
              onChange={(e) => newCollectable.name = e.target.value}
            />
          </div>
          <div className="form-input">
            {/* <label for="collectable-link">Link</label> */}
            <input 
              type="text"
              placeholder="Link"
              id="collectable-link"
              // value={newCollectable.link || ""}
              onChange={(e) => newCollectable.link = e.target.value}
            />
          </div>
          <div className="form-input">
            {/* <label for="collectable-type">Type</label> */}
            <input 
              type="text"
              placeholder="Type"
              id="collectable-type"
              // value={newCollectable.type || ""}
              onChange={(e) => newCollectable.type = e.target.value}
            />
          </div>
          <div className="form-input">
            {/* <label for="collectable-icon">Icon</label> */}
            <input 
              type="text"
              placeholder="Icon"
              id="collectable-icon"
              // value={newCollectable.icon || ""}
              onChange={(e) => newCollectable.icon = e.target.value}
            />
          </div>
          <div className="form-input">
            {/* <label for="collectable-category">Category</label> */}
            <input 
              type="text"
              placeholder="Category"
              id="collectable-category"
              // value={newCollectable.category || ""}
              onChange={(e) => newCollectable.category = e.target.value}
            />
          </div>
        </form>

        {/* <Row>
          <Col>
        <Form className="form-inputs-container">

        <div>
          <Form.Group className="form-inputs-group" controlId="formPlaintextEmail">
            <Form.Label column sm="2">ID</Form.Label>
              <Form.Control 
                type="number"
                placeholder="ID"
                className="newCollectableId"
                value={newCollectable.id || ""}
                onChange={(e) => setNewCollectable({...newCollectable, id: e.target.value})}
              />
          </Form.Group>
          </div>

          <Col>
          <Form.Group>
            <Form.Label column sm="2">Name</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Name"
                // value={newCollectible.name}
                onChange={(e) => newCollectable.name = e.target.value}
              />
          </Form.Group>
          </Col>
          <Row>
            <Col>
          <Form.Group>
            <Form.Label column sm="2">Link</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Link"
                // value={newCollectible.link}
                onChange={(e) => newCollectable.link = e.target.value}
              />
          </Form.Group>
          </Col>
          <Col>
          <Form.Group>
            <Form.Label column sm="2">Icon</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Icon"
                // value={newCollectible.icon}
                onChange={(e) => newCollectable.icon = e.target.value}
              />
          </Form.Group>
          </Col>
          </Row>
          <Form.Group>
            <Form.Label column sm="2">Type</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Type"
                // value={newCollectible.type}
                onChange={(e) => newCollectable.type = e.target.value}
              />
          </Form.Group>

          <Form.Group>
            <Form.Label column sm="2">Category</Form.Label>
              <Form.Control 
                type="text"
                placeholder="Category"
                // value={newCollectible.category}
                onChange={(e) => newCollectable.category = e.target.value}
              />
          </Form.Group>
        </Form>
        </Col>
        </Row> */}
      </div>
      ) : (collectablePage === 2) ? (
        <CollectablePage2 collectablePage={collectablePage} setCollectablePage={setCollectablePage} 
        allCollectables={allCollectables} setSelectedCollectable={setSelectedCollectable}/>
      ) : (collectablePage === 3) ? (
        <CollectablePage3 collectablePage={collectablePage} setCollectablePage={setCollectablePage} 
        allCollectables={allCollectables} properties={properties} selectedCollectable={selectedCollectable} username={username}/>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Collectables;