import { useState, useEffect, useRef, useMemo } from 'react'
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'

const Assets = ({ getUser, translations, accessories, combinedAssets }) => {

  
  const translationImageCellRenderer = (params) => {
    // const url = "https://ik.imagekit.io/metropolis/Avatars/Centred/tr:h-150/" + params.value;
    const url = "https://ik.imagekit.io/metropolis/" + params.value + "/tr:h-150";
    return (
      <div>
        <p className="m-0">{params.value}</p>
        <img 
          className="mb-2"
          src={url}
        />
      </div>
    )
  };
  
  const assetGridRef = useRef();
  const [assets, setAssets] = useState(combinedAssets);
  const [assetColumnData, setAssetColumnData] = useState(
    [
      {field: 'code', width: "75%"},
      {field: 'fileName', cellRenderer: translationImageCellRenderer, editable: true},
      {field: 'type'},
      {field: 'name', editable: true},
      {field: "layer"},
      {field: 'price', editable:  true},
      {field: 'supply', editable: true},
      {field: 'gender'},
      {field: 'createdBy'}
    ]
  );

  const [changedAssetData, setChangedAssetData] = useState([]);

  const defaultColDef = useMemo(() => (
    {
      sortable: true,
      editable: false,
      resizable: true,
      filter: 'agTextColumnFilter',
      width: "auto",
      filterParams: {buttons: ['apply', 'cancel', 'reset'], closeOnApply: true}, 
    }
  ));

  const onAssetCellValueChanged = async (e) => {
    console.log("row data: ", changedAssetData);
    console.log("e", e);
    e.data.price = parseFloat(e.data.price);
    e.data.supply = parseFloat(e.data.supply);
    // setchangedAssetData(e.data);
    if(changedAssetData.find(item => item.fileName === e.data.fileName)) {
      const items = changedAssetData.find(item => item.fileName === e.data.fileName);
      console.log("Items: ", items);
      const index = changedAssetData.indexOf(items);

      // Temp variable to hold the current state of changed data. 
      let temp_state = [...changedAssetData];
      // Temp variable to hold the current row we are updating.
      let temp_element = {...temp_state[index]};
      // Update the temp row with the new data.
      temp_element = e.data;
      // Checks to see if the column name already exists in the column array. If not, add it.
      temp_element.column.indexOf(e.colDef.field) === -1 ? temp_element.column.push(e.colDef.field) : console.log(`Column ${e.colDef.field} already included`);
      // Set the old row to the new row.
      temp_state[index] = temp_element;
      // Update state
      setChangedAssetData(temp_state);

      console.log("Changed data: ", changedAssetData);
    }  
    else {      // Else if row data not already included, add it. 
      const newData = e.data;
      // Adds the column name to the column array so I can validate which columns have been changed.
      newData.column = [e.colDef.field];
      const tempArr = [...changedAssetData, newData];
      console.log("tempArr: ", tempArr);
      setChangedAssetData(tempArr);
      // setchangedAssetData([...changedAssetData, e.data]);
    }
  };

  const uploadChangedData = async () => {
    console.log("Changed data: ", changedAssetData);

  }

  // useEffect(() => {
  //   console.log("Useeffect")
  //   const initializeData = async (translations, accessories) => {
  //     console.log("Initialize data")
  //     // const translations = await getTranslations();
  //     // const accessories = await getAccessories();
  //     await handleAssetCreation(translations, accessories);
  //   }
  //   initializeData(translations, accessories).catch(error => console.log("Error initializing data: ", error));
  // }, []);

  // const handleAssetCreation = async (translations, accessories) => {
  //   console.log("asset creation start")
  //   // let newAsset = {};
  //   let tempArr = [];
  //   for(let i=0; i < translations.length; i++) {
  //     var matchedAccessory = accessories.find(accessory => accessory.translationCode === translations[i].code);
  //     if(matchedAccessory) {
  //       // newAsset = matchedAccessory;
  //       matchedAccessory.fileName = translations[i].fileName;
  //       matchedAccessory.name = translations[i].name;
  //       matchedAccessory.thumbnail = translations[i].thumbnail;
  //       matchedAccessory.code = translations[i].code;
  //       console.log("Matched accessory: ", matchedAccessory);
  //       tempArr.push(matchedAccessory);
  //     }
  //   }
  //   // Sorts the array in ascending order by code
  //   var newAssets = tempArr.sort(function(a,b){return a.code - b.code});
  //   setAssets(newAssets);
  // }

  const logFunc = () => {
    console.log("Assets: ", assets);
  }

  // const updateTranslation = async () => {
  //   setIsLoading(true);
  //   try {
  //     const user = await getUser();
  //     for(let i=0; i < changedAssetData.length; i++) {
  //       // const updateCustomerNameTxn = await user.functions.update_translation(changedRowData[i].filename, changedRowData[i].customerName, changedRowData[i].price, changedRowData[i].supply, changedRowData[i].description);
  //       // const userLogTxn = await user.functions.user_log(loggedInUser.name, "update_translation", {code: changedRowData[i].code, customer_facing: changedRowData[i].customerName, price: changedRowData[i].price, supply: changedRowData[i].supply, description: changedRowData[i].description});
  //       // console.log("for ", i, "changedData: ", changedRowData[i]);
  //       const updateCustomerNameTxn = await user.functions.update_translation_images(changedAssetData[i].fileName, changedAssetData[i].name, changedAssetData[i].price, changedAssetData[i].supply, changedAssetData[i].description);
  //     }
  //     clearAfterUpdate();
  //   } catch (error) {
  //     console.log("Error updating customer name: ", error);
  //   }
  //   setIsLoading(false);
  // };

  return (
    <div>
      <h1>Assets</h1>
      <button onClick={() => logFunc()}>Log</button>
      {assets && (
      <div role="Spreadsheet div" className="ag-theme-alpine" style={{width: '100%', height: 500}}>
        <AgGridReact
          ref={assetGridRef} 
          rowData={assets}
          rowHeight={200}
          rowSelection='single'
          columnDefs={assetColumnData}
          defaultColDef={defaultColDef}
          onCellValueChanged={onAssetCellValueChanged}
          // onFirstDataRendered={onFirstDataRendered}
        />
      </div>
      )}
    </div>
  )
}

export default Assets;