import React, { useEffect, useState } from "react";
// import { CONTRACT_ADDRESS, transformPassportData } from "../../constants";
// import { CONTRACT_ADDRESS } from "../../../../constants";
import { ethers } from "ethers";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { Form, Modal } from "react-bootstrap";
import { formatBytes32String, keccak256, toUtf8Bytes } from "ethers/lib/utils";

const RoleSelector = ({accessTokenContract, imageContract, paymentSplitContract, lotteryContract, whitelistContract, softClayContract, winChanceContract, currentWallet}) => {

  const [assignWallet, setAssignWallet] = useState("");
  const [currentContract, setCurrentContract] = useState(0);

  const UPDATER_ROLE = keccak256(toUtf8Bytes("UPDATER_ROLE"));
  const BALANCE_ROLE = keccak256(toUtf8Bytes("BALANCE_ROLE"));
  const CONTRACT_ROLE = keccak256(toUtf8Bytes("CONTRACT_ROLE"));
  const DEFAULT_ADMIN_ROLE = keccak256(toUtf8Bytes("DEFAULT_ADMIN_ROLE"));

  const assignUpdaterRole = async (assignWallet) => {
    if(currentContract === 1) {
      try {
        const updaterRoleTxn = await accessTokenContract.grantRole(UPDATER_ROLE, assignWallet);
        await updaterRoleTxn.wait();
        console.log("Update txn for access: ", updaterRoleTxn);
      } catch (error) {
        console.log("Error assigning updater role from access token contract: ", error);
      }
    }
    else if (currentContract === 2) {
      try {
        const updaterRoleTxn = await imageContract.grantRole(UPDATER_ROLE, assignWallet);
        await updaterRoleTxn.wait();
        console.log("Update txn for image: ", updaterRoleTxn);
      } catch (error) {
        console.log("Error assigning updater role from image contract: ", error);
      }
    }
    else if (currentContract === 3) {
      try {
        const updaterRoleTxn = await paymentSplitContract.grantRole(UPDATER_ROLE, assignWallet);
        await updaterRoleTxn.wait();
        console.log("Update txn for payment: ", updaterRoleTxn);
      } catch (error) {
        console.log("Error assigning updater role from payment split contract", error);
      }
    }
    else if (currentContract === 4) {
      try {
        const updaterRoleTxn = await lotteryContract.grantRole(UPDATER_ROLE, assignWallet);
        await updaterRoleTxn.wait();
        console.log("Update txn for lottery: ", updaterRoleTxn);
      } catch (error) {
        console.log("Error assigning updater role from lottery contract: ", error);
      }
    }
    else if (currentContract === 5) {
      try {
        const updaterRoleTxn = await whitelistContract.grantRole(UPDATER_ROLE, assignWallet);
        await updaterRoleTxn.wait();
        console.log("Update txn for whitelist: ", updaterRoleTxn);
      } catch (error) {
        console.log("Error assigning updater role from whitelist contract: ", error);
      }
    }
    else if (currentContract === 6) {
      try {
        const updaterRoleTxn = await softClayContract.grantRole(UPDATER_ROLE, assignWallet);
        await updaterRoleTxn.wait();
        console.log("Update txn for soft clay: ", updaterRoleTxn);
      } catch (error) {
        console.log("Error assigning updater role from soft clay contract: ", error);
      }
    }
    else if (currentContract === 7) {
      try {
        const updaterRoleTxn = await winChanceContract.grantRole(UPDATER_ROLE, assignWallet);
        await updaterRoleTxn.wait();
        console.log("Update txn for win chance: ", updaterRoleTxn);
      } catch (error) {
        console.log("Error assigning updater role from win chance contract: ", error);
      }
    }
  };

  const assignBalanceRole = async (assignWallet) => {
    if (currentContract === 1) {
      try {
        const balanceRoleTxn = await accessTokenContract.grantRole(BALANCE_ROLE, assignWallet);
        await balanceRoleTxn.wait();
        console.log("Balance txn for access: ", balanceRoleTxn);
      } catch (error) {
        console.log("Error assigning balance role from access token contract: ", error);
      }
    }
    else if (currentContract === 3) {
      try {
        const balanceRoleTxn = await paymentSplitContract.grantRole(BALANCE_ROLE, assignWallet);
        await balanceRoleTxn.wait();
        console.log("Balance txn for payment: ", balanceRoleTxn);
      } catch (error) {
        console.log("Error assigning balance role from payment split contract: ", error);
      }
    }
  };

  const assignContractRole = async (assignWallet) => {
    if (currentContract === 1) {
      try {
        const contractRoleTxn = await accessTokenContract.grantRole(CONTRACT_ROLE, assignWallet);
        await contractRoleTxn.wait();
        console.log("Contract txn for access: ", contractRoleTxn);
      } catch (error) {
        console.log("Error assigning contract role from access token contract: ", error);
      }
    }
    else if (currentContract === 4) {
      try {
        const contractRoleTxn = await lotteryContract.grantRole(CONTRACT_ROLE, assignWallet);
        await contractRoleTxn.wait();
        console.log("Contract txn for lottery: ", contractRoleTxn);
      } catch (error) {
        console.log("Error assigning contract role from lottery contract: ", error);
      }
    }
    else if (currentContract === 5) {
      try {
        const contractRoleTxn = await whitelistContract.grantRole(CONTRACT_ROLE, assignWallet);
        await contractRoleTxn.wait();
        console.log("Contract txn for whitelist: ", contractRoleTxn);
      } catch (error) {
        console.log("Error assigning contract role from whitelist contract:" , error);
      }
    }
    else if (currentContract === 7) {
      try {
        const contractRoleTxn = await winChanceContract.grantRole(CONTRACT_ROLE, assignWallet);
        await contractRoleTxn.wait();
        console.log("Contract txn for win chance: ", contractRoleTxn);
      } catch (error) {
        console.log("Error assigning contract role from win chance contract: ", error);
      }
    }
  };

  const getUpdaterRoles = async () => {
    try {
      const getUpdateRolesTxn = await accessTokenContract.hasRole(UPDATER_ROLE, assignWallet);
      // await getUpdateRolesTxn.wait();
      console.log("Get update roles txn for access: ", getUpdateRolesTxn);
    } catch (error) {
      console.log("Error getting updater roles: ", error);
    }
  }

  const handleAssignWallet = (e) => {
    setAssignWallet(e.target.value);
  };

  const handleContract = (contract) => {
    setCurrentContract(contract);
  };

  const renderContent = () => {
    if(currentContract === 0) {
      return (
      <Card>
        <Card.Body>
          <Card.Title>Select which contract you want to update roles</Card.Title>
          <DropdownButton title="Contract Selection">
            <Dropdown.Item onClick={() => handleContract(1)}>Access Token</Dropdown.Item>
            <Dropdown.Item onClick={() => handleContract(2)}>Image Data</Dropdown.Item>
            <Dropdown.Item onClick={() => handleContract(3)}>Payment Split</Dropdown.Item>
            <Dropdown.Item onClick={() => handleContract(4)}>Lottery</Dropdown.Item>
            <Dropdown.Item onClick={() => handleContract(5)}>Whitelist</Dropdown.Item>
            <Dropdown.Item onClick={() => handleContract(6)}>Soft Clay</Dropdown.Item>
            <Dropdown.Item onClick={() => handleContract(7)}>Win Chance</Dropdown.Item>
          </DropdownButton>
        </Card.Body>
      </Card>
      );
    }
    else if(currentContract === 1) {
      return (
        <div>
          <Card>
            <Card.Body>
              <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => setCurrentContract(0)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
            </Button>
                <Card.Title>Token contract role selection</Card.Title>
                  <Form.Group>
                    <Form.Label>Enter address to assign role</Form.Label>
                      <Form.Control
                        type="string"
                        placeholder="address"
                        value={assignWallet}
                        onChange={handleAssignWallet}
                      />
                  </Form.Group>
                <DropdownButton title="Role">
                  <Dropdown.Item onClick={() => assignUpdaterRole(assignWallet)}>Updater Role</Dropdown.Item>
                  <Dropdown.Item onClick={() => assignBalanceRole(assignWallet)}>Balance Role</Dropdown.Item>
                  <Dropdown.Item onClick={() => assignContractRole(assignWallet)}>Contract Role</Dropdown.Item>
                </DropdownButton>
                <Button onClick={() => getUpdaterRoles()}>Get Roles</Button>
              </Card.Body>
          </Card>
        </div>
      );
    }
    else if (currentContract === 2) {
      return (
        <Card>
          <Card.Body>
            <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => setCurrentContract(0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
            </Button>
              <Card.Title>Image contract role selection</Card.Title>
                <Form.Group>
                  <Form.Label>Enter address to assign role</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="address"
                      value={assignWallet}
                      onChange={handleAssignWallet}
                    />
                </Form.Group>
              <DropdownButton title="Role">
                <Dropdown.Item onClick={() => assignUpdaterRole(assignWallet)}>Updater Role</Dropdown.Item>
              </DropdownButton>
          </Card.Body>
        </Card>
      );
    } 
    else if (currentContract === 3) {
      return (
        <Card>
          <Card.Body>
            <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => setCurrentContract(0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
            </Button>
              <Card.Title>Payment split role selection</Card.Title>
                <Form.Group>
                  <Form.Label>Enter address to assign role</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="address"
                      value={assignWallet}
                      onChange={handleAssignWallet}
                    />
                </Form.Group>
              <DropdownButton title="Role">
                <Dropdown.Item onClick={() => assignUpdaterRole(assignWallet)}>Updater Role</Dropdown.Item>
                <Dropdown.Item onClick={() => assignBalanceRole(assignWallet)}>Balance Role</Dropdown.Item>
              </DropdownButton>
          </Card.Body>
        </Card>
      );
    }
    else if(currentContract === 4) {
      return (
        <Card>
          <Card.Body>
            <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => setCurrentContract(0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
            </Button>
              <Card.Title>Lottery contract role selection</Card.Title>
                <Form.Group>
                  <Form.Label>Enter address to assign role</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="address"
                      value={assignWallet}
                      onChange={handleAssignWallet}
                    />
                </Form.Group>
              <DropdownButton title="Role">
                <Dropdown.Item onClick={() => assignUpdaterRole(assignWallet)}>Updater Role</Dropdown.Item>
                <Dropdown.Item onClick={() => assignContractRole(assignWallet)}>Contract Role</Dropdown.Item>
              </DropdownButton>
          </Card.Body>
        </Card>
      );
    }
    else if(currentContract === 5) {
      return (
        <Card>
          <Card.Body>
            <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => setCurrentContract(0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
            </Button>
              <Card.Title>Whitelist contract role selection</Card.Title>
                <Form.Group>
                  <Form.Label>Enter address to assign role</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="address"
                      value={assignWallet}
                      onChange={handleAssignWallet}
                    />
                </Form.Group>
              <DropdownButton title="Role">
                <Dropdown.Item onClick={() => assignUpdaterRole(assignWallet)}>Updater Role</Dropdown.Item>
                <Dropdown.Item onClick={() => assignContractRole(assignWallet)}>Contract Role</Dropdown.Item>
              </DropdownButton>
          </Card.Body>
        </Card>
      );
    }
    else if(currentContract === 6) {
      return (
        <Card>
          <Card.Body>
            <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => setCurrentContract(0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
            </Button>
              <Card.Title>Softclay contract role selection</Card.Title>
                <Form.Group>
                  <Form.Label>Enter address to assign role</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="address"
                      value={assignWallet}
                      onChange={handleAssignWallet}
                    />
                </Form.Group>
            <DropdownButton title="Role">
              <Dropdown.Item onClick={() => assignUpdaterRole(assignWallet)}>Updater Role</Dropdown.Item>
            </DropdownButton>
          </Card.Body>
        </Card>
      );
    }
    else if(currentContract === 7) {
      return (
        <Card>
          <Card.Body>
            <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => setCurrentContract(0)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
            </Button>
              <Card.Title>Win Chance contract role selection</Card.Title>
                <Form.Group>
                  <Form.Label>Enter address to assign role</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="address"
                      value={assignWallet}
                      onChange={handleAssignWallet}
                    />
                </Form.Group>
              <DropdownButton title="Role">
                <Dropdown.Item onClick={() => assignUpdaterRole(assignWallet)}>Updater Role</Dropdown.Item>
                <Dropdown.Item onClick={() => assignContractRole(assignWallet)}>Contract Role</Dropdown.Item>
              </DropdownButton>
          </Card.Body>
        </Card>
      );
    }
  };

  return (
    <div className="role-selector-container">
      {renderContent()}
    </div>
  );
};

export default RoleSelector;