import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import * as Realm from "realm-web";
import { Button, Row, Col, Form, Modal, Card, Dropdown } from "react-bootstrap";

const ExistingImagesPage = ({showExistingImagesModal, setShowExistingImagesModal, event, setEvent, isImage}) => {

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [existingImages, setExistingImages] = useState([]);
  const [prevIdx, setPrevIdx] = useState(0);

  useEffect(() => {
    refreshExistingImages("/activations");
  }, []);

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  const refreshExistingImages = async (folder) => {
    try {
      const user = await getUser();
      const refreshExistingImagesTxn = await user.functions.getExistingImages(folder);
      console.log("Refresh existing images txn: ", JSON.parse(refreshExistingImagesTxn));
      setExistingImages(JSON.parse(refreshExistingImagesTxn));
    } catch (error) {
      console.log("Error refreshing existing images", error);
    }
  };

  const selectExistingImage = async (e, idx) => {
    try {
      document.getElementById(prevIdx).style.border = "none";
      document.getElementById(idx).style.border="2px solid #000000";
      const newStr = e.target.src.replace("https://ik.imagekit.io/metropolis/tr:h-0.25,w-0.25/","");
      if(isImage) {
        setEvent({...event, prizeImage: newStr});
      } else if (!isImage) {
        setEvent({...event, icon: newStr});
      }
      setPrevIdx(idx);
    } catch (error) {
      console.log("Error selecting existing image: ", error);
    }
  }
  return (
    <div className="existing-images-page">
      <Modal className="existing-images-modal" size="xl" show={showExistingImagesModal} onHide={() => setShowExistingImagesModal(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        {/* <Button onClick={() => console.log("Event: ", event)}>Log event</Button> */}
        <Modal.Body>
          {existingImages.map((image, index) => {
            return (
              <div className="individual-images-container" id={index} key={index}>
                <Row>
                  <p>{image.name}</p>
                </Row>
                <Row className="d-inline-block">
                  <img className="existing-image" onClick={(e) => selectExistingImage(e, index)} src={`https://ik.imagekit.io/metropolis/tr:h-0.25,w-0.25${image.filePath}`} />
                </Row>
              </div>
            )
          })}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ExistingImagesPage;