import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Nav } from "react-bootstrap";
import Clock from "react-live-clock";
import * as Icon from "react-bootstrap-icons";
import Modal from 'react-bootstrap/Modal';

const PwdChange =({setShow, show}) => {
  
    const handleClose = () => setShow(false);
    
    return (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
  
  export default PwdChange;