import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { IKImage, IKContext, IKUpload } from "imagekitio-react";
import { AgRadioButton, RowNode } from "ag-grid-community";
import TestSpreadsheetData from "./TestSpreadsheetData";
import NewSpreadsheetData from "./NewSpreadsheetData";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import "../../CityPage.css";


const SpreadsheetData = ({ setIsLoading, user, properties, showProperties, setShowProperties }) => {

  // ******************************************** Cell renderer functions ************************************************ \\
  // These functions are used to render cells with specialized functionality.
  const foundingCitizenCellRenderer = (params) => {
    return (
      <div>
        <input 
          type="checkbox"
          checked={params.value}
          onChange={() => {
            const newValue = !params.value;
            params.node.setDataValue('foundingCitizen', newValue);
          }}
        />
      </div>
    );
  };

  const propertyImageCellRenderer = (params) => {
    const url = "https://ik.imagekit.io/metropolis/tr:h-100" + params.value;
    return (
      <div>
        <p className="m-0">{params.value}</p>
        <img 
          src={url}
        />
      </div>
    )
  };

  const priceCellRenderer = (params) => {
    const value = formatEther(125);
    return (
      <div>
        <p className="m-0">{params.value}</p>
        <input 
          type="number"
          value={params.value}
        />
        <p>{value}</p>
      </div>
    )
  };

  const towerCellRenderer = (params) => {
    const handleTowerUpdate = async (towerNum) => {
      if(towerNum === 1) {
        params.node.setDataValue('tower', "Tower 1");
      } else if (towerNum === 2) {
        params.node.setDataValue('tower', "Tower 2");
      } else if (towerNum === 3) {
        params.node.setDataValue('tower', "Tower 3");
      }
    }

    return (
      <div className="d-flex justify-content-center">
        <Dropdown id="towerMenu" style={{height: 150, overflowY: "scroll"}}>
          <Dropdown.Toggle style={{backgroundColor: "white", color: "black", border: "none", boxShadow: "none"}}id="dropdown-basic">
            {params.value}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleTowerUpdate(1)}>Tower 1</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTowerUpdate(2)}>Tower 2</Dropdown.Item>
            <Dropdown.Item onClick={() => handleTowerUpdate(3)}>Tower 3</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  };

  // Default values that are used for all columns unless overwritten.
  const defaultColDef = useMemo(()=> ({
    sortable: true,
    editable: true,
    resizable: true,
    filter: 'agTextColumnFilter', 
    filterParams: {buttons: ['apply', 'cancel', 'reset'], closeOnApply: true},
  }));

  // Columns data that is used in the spreadsheet. The fields are based on the row (properties object).
  const [columnsData, setColumnsData] = useState([
    {field: 'id', width: 70, filter: 'agNumberColumnFilter' },
    {field: 'img_name', cellRenderer: propertyImageCellRenderer, width: 100},
    {field: 'map_ref'},
    {field: 'name', width: 150},
    {field: 'description', width: 100, suppressSizeToFit: true, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'tower', cellRenderer: towerCellRenderer},
    {field: 'district'},
    {field: 'neighborhood'},
    {field: 'primary_type'},
    {field: 'sub_types.0', headerName: 'Sub Type 1', width: 100},
    {field: 'sub_types.1', headerName: 'Sub Type 2', width: 100},
    {field: 'structure'},
    {field: 'features.0', headerName: 'Feature 1', width: 100, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'features.1', headerName: 'Feature 2', width: 100, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'features.2', headerName: 'Feature 3', width: 100, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'price'},
    {field: 'status'},
    {field: 'foundingCitizen', cellRenderer: foundingCitizenCellRenderer},
    {field: 'owner', width: 100},
    {field: 'utility'},
  ]);

  const gridRef = useRef();
  const updateGridRef = useRef();

  const [rowData, setRowData] = useState([]);
  const [rowChanged, setRowChanged] = useState([]);

  const [currentPropertyIds, setCurrentPropertyIds] = useState([]);

  const [updateMapRef, setUpdateMapRef] = useState(false);

  const [updatedFile, setUpdatedFile] = useState(null);
  const [selectedFile, setSelectedFile]= useState(null);
  const [selectedFileEncoding, setSelectedFileEncoding] = useState(null);

  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [uploadImageId, setUploadImageId] = useState(null);
  const [uploadImageName, setUploadImageName] = useState("");

  const [showUpdatedModal, setShowUpdatedModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [showTestData, setShowTestData] = useState(false);

  const [currentMapRefId, setCurrentMapRefId] = useState(null);

  // Populates an array with the current property ids.
  useEffect(() => {
    setRowData(properties);
    if(properties != null) {
      for(let k = 0; k < properties.length; k++) {
        setCurrentPropertyIds(currentPropertyIds => [...currentPropertyIds, properties[k].id]);
      }
    }
  }, [properties]);

  // ******************************************** Grid layout functions ************************************************ \\

  // Sets a filter based on the value entered in the input field. 
  const onFilterTextChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }, []);

  // Call autoSizeAll when the spreadsheet data is first loaded.
  const onFirstDataRendered = useCallback(() => {
    autoSizeAll();
  }, []);

  // Autosizes all the columns (except for the ones in if statement). 
  const autoSizeAll = useCallback(() => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      // Autosizing these values disrupts the layout of the spreadsheet. They are set to display in a popup when clicked
      if(column.getId() === 'description' || column.getId() === 'features.0' || column.getId() === 'features.1' || column.getId() === 'features.2' || column.getId() === 'features.3') {
        return;
      }
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds);
  }, []);

  // Does the same as the autoSizeAll function, except it applies to the updateGridRef.
  const autoSizeAllUpdate = useCallback(() => {
    const allColumnIds = [];
    updateGridRef.current.columnApi.getColumns().forEach((column) => {
      if(column.getId() === 'description' || column.getId() === 'features.0' || column.getId() === 'features.1' || column.getId() === 'features.2' || column.getId() === 'features.3') {
        return;
      }
      allColumnIds.push(column.getId());
    });
    updateGridRef.current.columnApi.autoSizeColumns(allColumnIds);
  }, []);

  // Clears the search and applied filters when button is clicked. 
  const clearFilter = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.setQuickFilter('');
    document.getElementById('filter-text-box').value = '';
  }, []);

  const onUpdateGridReady = (e) => {
    updateGridRef.current.api.setDomLayout('autoHeight');
    document.querySelector("#updateGrid").style.height = '';
  }

  // ******************************************** Update cell functions ************************************************ \\

  // Triggers when a cell is changed.
  const onCellValueChanged = async (e) => {
    // If the cell changed has already been changed and added to rowChanged then find it and update the row.
    console.log("row changed: ", rowChanged);
    if(rowChanged.find(item => item.id === e.data.id)) {
      const items = rowChanged.find(item => item.id === e.data.id);
      console.log("Items: ", items);
      const index = rowChanged.indexOf(items);

      // Temp variable to hold the current state of changed spreadsheet data. 
      let temp_state = [...rowChanged];
      // Temp variable to hold the current row we are updating.
      let temp_element = {...temp_state[index]};
      // Update the temp row with the new data.
      temp_element = e.data;
      // Set the old row to the new row.
      temp_state[index] = temp_element;
      // Update state
      setRowChanged(temp_state);

      console.log("Changed data: ", rowChanged);
    } else {
      // Else if row data not already included, add it. 
      setRowChanged(prevRow => [...prevRow, e.data]);
    }
  };

  // Triggers when a cell is clicked.
  const onCellClicked = (e) => {
    console.log("Cell Clicked: ", e);
    // If the cell clicked is in column "img_name" then allow the user to upload an image.
    if(e.column.colId === 'img_name') {
      console.log("Image clicked");
      setUploadImageId(e.data.id);
      // If the cell that is clicked has a name then set the name to be displayed in the modal.
      if(e.data.name) {
        setUploadImageModal(true);
        setUploadImageName(e.data.name);
        setCurrentMapRefId(e.data.map_ref);
      } else {
        setUploadImageModal(false);
      }
    }
  };

  const onUploadSuccess = (fileName) => {
    console.log("Upload success");
    setUpdatedFile(fileName);
    console.log("File name: ", fileName);
    setUploadImageModal(false);
    updateImageName(fileName);
  };

  // Updates the image name in the spreadsheet. As well as the map_ref if selected.
  const updateImageName = async (fileName) => {
    let currentRow = undefined;
    gridRef.current.api.forEachNode((rowNode) => {
      // Find row with id that matches the id of the image we are updating.
      if(rowNode.data.id === uploadImageId) {
        console.log("Found row node: ", rowNode);
        rowNode.setDataValue('img_name', fileName);
        // If updateMapRef is selectd then we update the map ref. 
        if(updateMapRef) {
          console.log("Map ref: ", fileName.replace(".png", ""));
          rowNode.setDataValue("map_ref", fileName.replace(".png", ""));
        }
        currentRow = rowNode;
      }
    })
    // This may not be necessary. 
    if(currentRow !== undefined) {
      await onCellValueChanged(currentRow);
    }
  };

  // ******************************************** Database control functions ************************************************ \\

  // Triggers when the user confirms the update.
  const updateProperties = async () => {
      try {
        for(let i = 0; i < rowChanged.length; i++){
          // Ensures that if foundingCitizen is true then there is an owner attached to it. 
          if(rowChanged[i].foundingCitizen == true && rowChanged[i].owner == "") {
            alert("Please add a founding citizen for the property: " + rowChanged[i].id);
            continue;
          }
          // Calls the update property function in the database with the values to be changed as arguments.
          const updateProperties = await user.functions.update_property(rowChanged[i].id, rowChanged[i]);
          console.log("Update Properties: ", updateProperties);
          console.log("Row ID: ", rowChanged[i].id, "Row: ", rowChanged[i]);
        }
        clearAfterUpdate();
      } catch (error) {
        console.log("Error updating properties: ", error);
      }
  };

  // Resets various states after update is triggered
  const clearAfterUpdate = () => {
    setShowUpdatedModal(false);
    setShowProperties(true);
    setRowChanged([]);
    setUpdateMapRef(false);
  }

  // Checks which property IDs are missing from the spreadsheet.
  const checkMissingId = () => {
    const missingIds = [];
    for(let i = 1; i < properties[properties.length - 1].id; i++) {
      if(!currentPropertyIds.includes(i)) {
        missingIds.push(i);
      }
    }
    console.log("Missing Ids: ", missingIds);
  };

  // Reads the uploaded file data and converts it to URL so it can be uploaded to image kit. 
  const fileUploadHandler = (e) => {
    setSelectedFile(e.target.files[0]);
    const reader = new FileReader();
    const testData = reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log("File: ", reader.result);
      setSelectedFileEncoding(reader.result);
    };
    reader.onerror = () => {
      console.log("Error");
    }
  };

  // Triggers when the user submits a file. 
  const handleSubmission = () => {
    console.log("selected file: ", selectedFile);
    console.log("selected file encoding: ", selectedFileEncoding);
    uploadImageToImageKit()
  };

  const uploadImageToImageKit = async () => {
    const sc = process.env.REACT_APP_FAST_KEY;
    const params = {
      imageFile: selectedFileEncoding,
      imageName: selectedFile.name,
    };

    console.log("params: ", params);

    // Python API Function
    fetch("http://127.0.0.1:8000/uploadToImageKit", {
      method: "POST",
      mode: 'cors',
      header: {
        "Accept": 'application/json',
        "Content-Type": "application/json",
        "secretKey": sc,
      },
      body: JSON.stringify(params),
    })
    .then((response) => response.json())
      .then(async (result) =>{
        console.log("result: ", result);
        await updateImageName(result.data.imageName)
        console.log("image name: ", result.data.imageName);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const uploadMapRefRenderer = () => {
    if(uploadImageName && selectedFile) {
      return (
        <p className="pe-2 d-inline-block">from {currentMapRefId} to {selectedFile.name.replace(".png", "")}</p>
      )
    } else {
      return (<div></div>)
    }
  };

  return (
    <div aria-description="Modal div">
      {/* <Modal size="xl" show={showProperties} onHide={() => setShowProperties(false)}> */}
      <Card>
        <Card.Header>
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={{offset: 2}}>
              <Card.Title>Update</Card.Title>
            </Col>
            <Col md={{span: 2}} className="p-0">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">Settings</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => checkMissingId()}>Check missing IDs</Dropdown.Item>
                  <Dropdown.Item onClick={() => console.log("Row data: ", rowData)}>Log row data</Dropdown.Item>
                  <Dropdown.Item onClick={() => {setShowTestData(true); setShowProperties(false)}}>Show test data</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body className="pb-0">
          <Row className="tool-container">
            <Col>
              <Row>
                <Col className="p-0 d-flex justify-content-left">
                  <Form.Group className="d-inline-block p-2 align-right">
                    <Form.Control 
                      type="string"
                      placeholder="Search"
                      id="filter-text-box"
                      onInput={onFilterTextChanged}
                    />
                  </Form.Group>
                    <Button onClick={() => clearFilter()}>Clear filter</Button>
                </Col>
              </Row>

              <Row>
                <Col className="p-2 d-flex justify-content-left">
                  {uploadImageModal && (
                    <p className="mb-0">Updating image for: {uploadImageName}</p>
                  )}
                </Col>
              </Row>

              <Row>
                <Col className="p-2 file-upload-container">
                  <div style={{width: "30%"}} className="d-flex p-2 file-upload-container">
                  <input 
                    type="file"
                    onChange={fileUploadHandler}
                    // style={{width: "30%"}}
                  />
                  <Button onClick={() => handleSubmission()}>Submit</Button>
                  </div>
                  {/* <Button onClick={() => handleSubmission()}>Submit</Button> */}
                </Col>
              </Row>

              <Row>
                <Col xs={8} className="p-2 d-flex justify-content-left">
                  <p className="m-0 ps-1 pe-1 d-inline-block">Update map ref via file</p>
                    {updateMapRef && (uploadMapRefRenderer())}
                      <input 
                        type="checkbox"
                        onChange={() => setUpdateMapRef(!updateMapRef)}
                      />
                </Col>
              </Row>

            </Col>
          </Row>

          <div aria-labelledby="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 500}}>
            <AgGridReact 
              ref={gridRef}
              rowData={properties}
              rowHeight={150}
              columnDefs={columnsData}
              defaultColDef={defaultColDef}
              onCellValueChanged={onCellValueChanged}
              onFirstDataRendered={onFirstDataRendered}
              onCellClicked={onCellClicked}
            />
          </div>

          <Card.Footer className="p-1">
            <Button onClick={() => {setShowUpdatedModal(true); setShowProperties(false)}}>Update</Button>
          </Card.Footer>
        </Card.Body>
      
        <NewSpreadsheetData defaultColDef={defaultColDef} columnsData={columnsData} user={user} setIsLoading={setIsLoading} currentPropertyIds={currentPropertyIds} properties={properties}/>
        </Card>
      {/* </Modal> */}

        <TestSpreadsheetData defaultColDef={defaultColDef} columnsData={columnsData} user={user} 
        setIsLoading={setIsLoading} setShowTestData={setShowTestData} showTestData={showTestData} setShowProperties={setShowProperties}
        currentPropertyIds={currentPropertyIds} properties={properties}/>

      <Modal size="xl" show={showUpdatedModal} onHide={() => {setShowUpdatedModal(false); setShowProperties(true)}}>
        <Modal.Header>
          <Row>
            <Col xs={2}>
              <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => {setShowUpdatedModal(false); setShowProperties(true)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                </svg>
              </Button>
            </Col>
            <Col>
              <Modal.Title>Confirm changes</Modal.Title>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div id="updateGrid" aria-labelledby="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 500}}>
            <AgGridReact 
              ref={updateGridRef}
              rowData={rowChanged}
              columnDefs={columnsData}
              defaultColDef={defaultColDef}
              onFirstDataRendered={autoSizeAllUpdate}
              onGridReady={onUpdateGridReady}
            />
          </div>
          <Button onClick={() => updateProperties()}>Confirm</Button>
          <Button onClick={() => {console.log("Deny"); setShowUpdatedModal(false)}}>Deny</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SpreadsheetData;