import { useState, useEffect } from 'react'

const Statistics = ({translations, accessories, combinedAssets}) => {

  const [stats, setStats] = useState([]);

  // useEffect(() => {

  // }, []);

  const computeStats = async () => {
    var counter = []

    for(let i=0; i < combinedAssets.length; i++) {
      counter[combinedAssets[i].layer] ? counter[combinedAssets[i].layer].number += 1 : counter[combinedAssets[i].layer] = {number: 1};
    }

    // Loop through each key in the object and set the percentage
    for(let [key, value] of Object.entries(counter)) {
      value.percentage = parseFloat((value.number / combinedAssets.length).toFixed(3));
    }
    console.log("Count: ", counter);
  }

  return (
    <div>
      <h1>Stats!</h1>
      <button onClick={() => computeStats()}>Test</button>
    </div>
  )
}

export default Statistics;