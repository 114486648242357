import React, { useState } from "react";
import PassportPage from "../PassportPage";
import CustomerProfilePage from "../CustomerProfilePage";
import CityPage from "../CityPage";
import CitizenPage from "../CitizenPage";
import ActivationsPage from "../ActivationsPage";

const DetailsPage = ({ currentAccount, pannel, contracts, currentWallet, creator, setShowNavBar, collapseSideBar, setCollapseSideBar, sideBarItemPages, setSideBarItemPages }) => {

    const renderDetails = () =>{
        if (pannel==='passport'){
            return(
                <PassportPage currentAccount={currentAccount} contracts={contracts} currentWallet={currentWallet} 
                creator={creator} setShowNavBar={setShowNavBar} sideBarItemPages={sideBarItemPages} setSideBarItemPages={setSideBarItemPages}/>
            )
        }else if(pannel==='city1'){
            return(
                <CityPage pannel={pannel} collapseSideBar={collapseSideBar} setCollapseSideBar={setCollapseSideBar} currentAccount={currentAccount} 
                sideBarItemPages={sideBarItemPages} setSideBarItemPages={setSideBarItemPages} contracts={contracts}
                />
            )
        } else if (pannel==='citizens') {
            return (
                <CitizenPage currentAccount={currentAccount} sideBarItemPages={sideBarItemPages}/>
            )
        } else if (pannel==='customer_profile') {
            return (
                <CustomerProfilePage currentAccount={currentAccount}  />
            )
        } else if (pannel==='activations')
        return (
            <ActivationsPage currentAccount={currentAccount}  />
        )
    }
  return (
    <div>
        {renderDetails()}
    </div>
  );
};
export default DetailsPage;