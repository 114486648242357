import React, { useEffect, useState, useCallback } from "react";
import { CONTRACT_ADDRESS, transformPassportData } from "../../constants";
import { ethers } from "ethers";
import "./PassportPage.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { transformPannelData } from "../../constants";
import * as Realm from "realm-web";
import { Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import LoadingIndicator from "../LoadingIndicator";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
// import RoleSelector from "../RoleSelector";
import userEvent from "@testing-library/user-event";
import TokenPage from "./Components/TokenPage";
import WhitelistPage from "./Components/WhitelistPage";
import PaymentPage from "./Components/PaymentPage";
import GamerProfile from "./Components/GamerProfile";
import AdminPage from "./Components/AdminPage";
import UserPage from "./Components/UserPage";
import { getRoles } from "@testing-library/react";
import { formatBytes32String, keccak256, toUtf8Bytes } from "ethers/lib/utils";

const PassportPage = ({ currentAccount, contracts, currentWallet, creator, setShowNavBar, sideBarItemPages, setSideBarItemPages }) => {

const [passportAddress, setPassportAddress] = useState("");

// Global state for access token contract
const [currentPrice, setCurrentPrice] = useState("");
const [currentLimit, setCurrentLimit] = useState("");
const [currentSupply, setCurrentSupply] = useState("");

// State for accessing MongoDB functions
const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
const nftRealmApp = new Realm.App({ id: process.env.REACT_APP_NFTDROPAPP });
const [user, setUser] = useState(null);

// State for displaying certain modals
const [isLoading, setIsLoading] = useState(false);
const [isUpdater, setIsUpdater] = useState(false);

// State for payment split contract
const [paymentAddress, setPaymentAddress] = useState(currentWallet);
const [totalShares, setTotalShares] = useState(0);
const [newShares, setNewShares] = useState(0);
const [addressShares, setAddressShares] = useState(0);


const [passportIndex, setPassportIndex] = useState(0);

useEffect(() => {
  loadData();
}, []);

// Load initial data to be displayed
const loadData = async () => {
  getCurrentLimit();
  getCurrentSupply();
  getCurrentPrice();
  const user = getUser();
  getRoles();
};

// Updates total shares to display on site
const getTotalShares = async () => {
  try {
    const totalSharesTxn = await contracts.paymentSplitContract.totalShares();
    setTotalShares(totalSharesTxn.toNumber());
    console.log("Total shares: ", totalShares);
  } catch (error) {
    console.log("Error retreiving total shares: ", error);
  }
};

// Updates current shares to display on site
const getCurrentShares = async () => {
  try {
    const getSharesTxn = await contracts.paymentSplitContract.shares(paymentAddress);
    setAddressShares(getSharesTxn.toNumber());
    console.log("Current shares: ", addressShares);
  } catch (error) {
    console.log("Error retrieving current shares: ", error);
  }
};

const getCurrentLimit = async () => {
  try {
    const currentLimitTxn = await contracts.accessTokenContract._mintLimit();
    setCurrentLimit(currentLimitTxn); 
  } catch (error) {
    console.log("Error retrieving current mint limit: ", error);
  }
};

// Updates currentSupply to display on site
const getCurrentSupply = async () => {
  try {
    const getTokenIdTxn = await contracts.accessTokenContract.getCurrentTokenId();
    setCurrentSupply(getTokenIdTxn - 1);
  } catch (error) {
    console.log("Error retreiving current token ID: ", error);
  }
};

// Updates current price to display on site
const getCurrentPrice = async () => {
  try {
    const currentPriceTxn = await contracts.accessTokenContract._navPrice();
    // Format WEI to Ether
    setCurrentPrice(formatEther(currentPriceTxn));
  } catch (error) {
    console.log("Error retrieving current price: ", error);
  }
};

const getUser = useCallback(async () => {
  const apiKey = process.env.REACT_APP_MONGOAPIKEY;
  const credentials = Realm.Credentials.apiKey(apiKey);
  try {
    // Authenticate user
    const user = await realmApp.logIn(credentials);
    return user;
  } catch (error) {
    console.log("Error getting user: ", error);
  }
}, []);

const getRoles = async () => {
  const UPDATER_ROLE = keccak256(toUtf8Bytes("UPDATER_ROLE"));
  try {
    const hasRoleTxn = await contracts.accessTokenContract.hasRole(UPDATER_ROLE, currentWallet);
    console.log("Has role: ", hasRoleTxn);
    setIsUpdater(hasRoleTxn);
  } catch (error) {
    console.log("Error retrieving roles: ", error);
  }
};

const renderPages = () => {
  if(sideBarItemPages.passportPage === "") {
    return <h1>Select a page</h1>
  } else if (sideBarItemPages.passportPage === "token") {
    return <TokenPage setIsLoading={setIsLoading} isLoading={isLoading} currentWallet={currentWallet} contracts={contracts} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} setCurrentLimit={setCurrentLimit} setCurrentSupply={setCurrentSupply}/>
  } else if (sideBarItemPages.passportPage === "payment") {
    return <PaymentPage setIsLoading={setIsLoading} paymentSplitContract={contracts.paymentSplitContract} currentWallet={currentWallet} />
  } else if (sideBarItemPages.passportPage === "whitelist") {
    return <WhitelistPage setIsLoading={setIsLoading} accessTokenContract={contracts.accessTokenContract} lotteryContract={contracts.lotteryContract} realmApp={realmApp} nftRealmApp={nftRealmApp} setShowNavBar={setShowNavBar} />
  } else if (sideBarItemPages.passportPage === "gamerProfile") {
    return <GamerProfile setIsLoading={setIsLoading} passportIndex={passportIndex} accessTokenContract={contracts.accessTokenContract} softClayContract={contracts.softClayContract}/>
  } else if (sideBarItemPages.passportPage === "user") {
    return <UserPage setIsLoading={setIsLoading} currentAccount={currentAccount} passportIndex={passportIndex} accessTokenContract={contracts.accessTokenContract} softClayContract={contracts.softClayContract} winChanceContract={contracts.winChanceContract}/>
  } else if (sideBarItemPages.passportPage === "admin") {
    return <AdminPage accessTokenContract={contracts.accessTokenContract} imageContract={contracts.imageContract} paymentSplitContract={contracts.paymentSplitContract} lotteryContract={contracts.lotteryContract} whitelistContract={contracts.whitelistContract} softClayContract={contracts.softClayContract} winChanceContract={contracts.winChanceContract}/>
  }
}

  return (

    <div className="passport-page-container">
      <Row>
        <Col>
          <Card>
            <div className="passport-page-header">
              <h2>Passport Page</h2>
              <p>Current passport price: {currentPrice} ETH</p>
              <p>Passports Minted: {currentSupply}/{currentLimit}</p>
            </div>
          </Card>

          {/* {renderPages()} */}

        </Col>
      </Row>

      <div className="loading-modal">
        <Modal show={isLoading}>
          <Modal.Body>
            <LoadingIndicator />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
export default PassportPage;
