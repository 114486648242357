import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import * as Realm from "realm-web";
import { Button, Row, Col, Form, Modal, Card, Dropdown } from "react-bootstrap";
import {IKImage, IKContext, IKUpload} from 'imagekitio-react';
import LoadingIndicator from "../LoadingIndicator";
import EventList from "./Components/EventList";
import ExistingImagesPage from "./Components/ExistingImagesPage";
import "./ActivationsPage.css";

const ActivationsPage = ({ currentAccount }) => {

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [event, setEvent] = useState({
    id: null,
    type: "",
    name: "",
    description: "",
    prizeName: "",
    prizeImage: "",
    prizeDescription: "",
    difficulty: null,
    failMessage: "",
    failTitle: "",
    icon: "",
    location: null,
    winMessage: "",
    winTitle: "",
    live: false,
    open: true,
    public: false,
    randomWinner: false,
    winnersToRaffle: null,
    timeOfDay: "",
    start: null,
    finish: null
  });
  
  const [showExistingImagesModal, setShowExistingImagesModal] = useState(false);
  const [currentEvents, setCurrentEvents] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [loggedInUser, setLoggedInUser] = useState(JSON.parse(currentAccount));

  const [isImage, setIsImage] = useState(false);

  const publicKey = 'public_8ajALeusMVd4dqduyEoNWaqkKGw=';
  const urlEndpoint = 'https://ik.imagekit.io/metropolis';
  const authenticationEndpoint = "https://data.mongodb-api.com/app/mission_control-stvqu/endpoint/imup"

  useEffect(() => {
    loadCurrentData();
  }, []);

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  const loadCurrentData = async () => {
    try {
      const user = await getUser();
      const events = await user.functions.getAllEvents();
      setCurrentEvents(events);
      // console.log("currentId: ", currentId[currentId.length-1].id);
      setEvent({...event, id: events[events.length-1].id+1, type: "treasure-hunt"});
      // setEvent({...event, type: "treasure-hunt"});
    } catch (error) {
      console.log("Error refreshing current Id", error);
    }
  };

  const createNewEvent = async () => {
    try {
      const emptyFields = await checkForEmptyFields();
      if(emptyFields === []) {
        console.log("No empty fields");
      } else if (emptyFields !== []) {
        // alert("Please fill in all fields");
        // return;
        emptyFields.map((field) => {
          console.log("Empty field: ", field);
          // if(field !== "winnersToRaffle" || field !== "live" || field !== "open") {
          //   document.getElementById(field).className = "form-control-input-missing form-control";
          // }
        })
        // console.log("Empty fields: ", emptyFields);
      }
      const user = await getUser();
      const createNewEventTxn = await user.functions.createActivation(event);
      console.log("Create new event txn: ", createNewEventTxn);
      const userLog = await user.functions.user_log(loggedInUser.name, 'createActivation', {event: event});
      await loadCurrentData();
      alert("Event created successfully");
    } catch (error) {
      console.log("Error creating new event: ", error);
    }
  };

  const checkForEmptyFields = async () => {
    const emptyFields = [];
    let returnVal = true;
    Object.keys(event).forEach(function(key, index) {
      if(!event[key]) {
        // console.log("Empty field: ", key);
        emptyFields.push(key);
        // document.getElementById(key).style.borderColor = "red";
        returnVal = false;
      }
    });
    return emptyFields;
  };

  const log = async () => {
    setIsLoading(true);
    const emptyFields = await checkForEmptyFields();
    if(emptyFields === []) {
      console.log("No empty fields");
    } else if (emptyFields !== []) {
      // alert("Please fill in all fields");
      emptyFields.map((field) => {
        console.log("Empty field: ", field);
        // if(field !== "winnersToRaffle" || field !== "live" || field !== "open") {
        //   document.getElementById(field).className = "form-control-input-missing form-control";
        // }
      })
      // console.log("Empty fields: ", emptyFields);
    }
    await loadCurrentData();
    console.log("event: ", event);
    setIsLoading(false);
  };

  const onImageError = res => {
    console.log("Error uploading image: ", res);
  };

  const onImageSuccess = res => {
    console.log("Success", res);
    // setEvent({...event, prizeImage: res.url});
    setEvent({...event, prizeImage: res.url.replace("https://ik.imagekit.io/metropolis/", "")});
  };

  const onIconError = res => {
    console.log("Error uploading icon: ", res);
  };

  const onIconSuccess = res => {
    console.log("Success: ", res);
    setEvent({...event, icon: res.url.replace("https://ik.imagekit.io/metropolis/", "")});
  };

  const handleExistingImagesModal = (bool) => {
    setShowExistingImagesModal(!showExistingImagesModal);
    setIsImage(bool);
  }

  return (
    <div>
      <h1>Activations Page</h1>
      <Row>
      <Col md={{span: 6}} sm={{span: 6}}>
      <Card>
        <Card.Header>Event Manager</Card.Header>
          <Card.Body>
            <Form.Group>
              <Form.Label>Event Details</Form.Label>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">ID</Form.Label>
                    <Form.Control
                      type="number"
                      id="id"
                      placeholder="Enter ID"
                      className="form-control-input"
                      value={event.id || ""}
                      onChange={e => setEvent({...event, id: parseInt(e.target.value)})}
                    />
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Type</Form.Label>
                    {/* <Form.Control
                      type="text"
                      id="type"
                      placeholder="Enter type"
                      className="form-control-input"
                      value={event.type || ""}
                      onChange={e => setEvent({...event, type: e.target.value})}
                    /> */}
                    <Dropdown className="dropdown-container">
                      <Dropdown.Toggle className="dropdown-toggle-container"></Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-container">
                          <Dropdown.Item onClick={() => setEvent({...event, type: 'treasure-hunt'})}>Treasure-Hunt</Dropdown.Item>
                        </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Name</Form.Label>
                    <Form.Control 
                      type="text"
                      id="name"
                      placeholder="Enter name"
                      className="form-control-input"
                      onChange={e => setEvent({...event, name: e.target.value})}
                    />
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Description</Form.Label>
                    <Form.Control 
                      type="text"
                      id="description"
                      as="textarea"
                      placeholder="Enter description"
                      className="form-control-input"
                      onChange={e => setEvent({...event, description: e.target.value})}
                    />
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Difficulty</Form.Label>
                    <Form.Control 
                      type="number"
                      id="difficulty"
                      placeholder="Enter difficulty"
                      className="form-control-input"
                      onChange={e => setEvent({...event, difficulty: parseInt(e.target.value)})}
                    />
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Location</Form.Label>
                    <Form.Control 
                      type="number"
                      id="location"
                      placeholder="Enter location"
                      className="form-control-input"
                      onChange={e => setEvent({...event, location: parseInt(e.target.value)})}
                    />
                </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Prize Details</Form.Label>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Prize name</Form.Label>
                    <Form.Control 
                      type="text"
                      id="prizeName"
                      placeholder="Enter prize name"
                      className="form-control-input"
                      onChange={e => setEvent({...event, prizeName: e.target.value})}
                    />
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Prize Description</Form.Label>
                    <Form.Control 
                      type="text"
                      id="prizeDescription"
                      as="textarea"
                      placeholder="Enter prize description"
                      className="form-control-input"
                      onChange={e => setEvent({...event, prizeDescription: e.target.value})}
                    />
                </div>
                <div className="form-control-container">
                  {/* <Form.Label className="form-control-label">Prize Icon</Form.Label> */}
                  <Form.Label className="form-control-label">Quest Icon</Form.Label>
                    {/* <Col className="prize-icon-input">
                      <Row className="d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{width: '75%'}}>Use same image as prize image?</p>
                          <Form.Check 
                            type="switch"
                            id="icon"
                            style={{width: '15%'}}
                            checked={showPrizeIconUploader}
                            onChange={() => setShowPrizeIconUploader(!showPrizeIconUploader)}
                          />
                      </Row>
                      {!showPrizeIconUploader && (
                        <Row> */}
                          {/* <Form.Control 
                            type="file"
                            id="icon"
                            placeholder="Upload prize icon"
                            className="form-control-input"
                            onChange={e => iconFileUploadHandler(e)}
                          /> */}
                          <IKContext publicKey={publicKey} urlEndpoint={urlEndpoint} authenticationEndpoint={authenticationEndpoint}>
                            <IKUpload id="icon" className="form-control-input" folder="/activations" onError={onIconError} onSuccess={onIconSuccess} useUniqueFileName={false}/>
                          </IKContext>
                          <Button onClick={() => handleExistingImagesModal(false)}>
                            Browse previous
                          </Button>
                        {/* </Row>
                      )}
                    </Col> */}
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Prize Image</Form.Label>
                    {/* <Form.Control 
                      type="file"
                      id="prizeImage"
                      placeholder="Upload prize image"
                      className="form-control-input"
                      onChange={(e) => imageFileUploadHandler(e)}
                    /> */}
                    <IKContext publicKey={publicKey} urlEndpoint={urlEndpoint} authenticationEndpoint={authenticationEndpoint}>
                      <IKUpload id="prizeImage" className="form-control-input" folder="/activations" onError={onImageError} onSuccess={onImageSuccess} useUniqueFileName={false}/>
                    </IKContext>
                    <Button onClick={() => handleExistingImagesModal(true)}>
                      Browse previous
                    </Button>
                  </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Win/Lose Details</Form.Label>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Win Title</Form.Label>
                    <Form.Control 
                      type="text"
                      id="winTitle"
                      placeholder="Enter win title"
                      className="form-control-input"
                      onChange={e => setEvent({...event, winTitle: e.target.value})}
                    /> 
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Win Message</Form.Label>
                    <Form.Control 
                      type="text"
                      id="winMessage"
                      placeholder="Enter win message"
                      className="form-control-input"
                      onChange={e => setEvent({...event, winMessage: e.target.value})}
                    />
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Fail Title</Form.Label>
                    <Form.Control 
                      type="text"
                      id="failTitle"
                      placeholder="Enter fail title"
                      className="form-control-input"
                      onChange={e => setEvent({...event, failTitle: e.target.value})}
                    />
                </div>
                <div className="form-control-container">
                  <Form.Label className="form-control-label">Fail Message</Form.Label>
                    <Form.Control 
                      type="text"
                      id="failMessage"
                      placeholder="Enter fail message"
                      className="form-control-input"
                      onChange={e => setEvent({...event, failMessage: e.target.value})}
                    />
                </div>
            </Form.Group>

            <Form.Group>
              {/* <Row> */}
              <Form.Label className="form-title">Visibility Details</Form.Label>
              {/* </Row> */}
              <div className="form-control-container">
                <Form.Label className="form-control-label">Visibility</Form.Label>
                  {/* <Form.Control 
                    type="text"
                    id="timeOfDay"
                    placeholder="Enter time of day"
                    className="form-control-input"
                    onChange={e => setEvent({...event, timeOfDay: e.target.value})}
                  /> */}
                  <Dropdown className="dropdown-container">
                    <Dropdown.Toggle className="dropdown-toggle-container">
                      {/* Visibility Time */}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-container">
                      <Dropdown.Item onClick={() => setEvent({...event, timeOfDay: 'day'})}>Day</Dropdown.Item>
                      <Dropdown.Item onClick={() => setEvent({...event, timeOfDay: 'dusk'})}>Dusk</Dropdown.Item>
                      <Dropdown.Item onClick={() => setEvent({...event, timeOfDay: 'dawn'})}>Dawn</Dropdown.Item>
                      <Dropdown.Item onClick={() => setEvent({...event, timeOfDay: 'night'})}>Night</Dropdown.Item>
                      <Dropdown.Item onClick={() => setEvent({...event, timeOfDay: 'any'})}>Any</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
              </div>
              <div className="form-control-container">
                <Form.Label className="form-control-label">Start Time (local)</Form.Label>
                  <Form.Control 
                    type="datetime-local"
                    id="start"
                    placeholder="Enter start time"
                    className="form-control-input"
                    onChange={e => setEvent({...event, start: new Date(e.target.value).getTime()})}
                  />
              </div>
              <div className="form-control-container">
                <Form.Label className="form-control-label">Finish Time (local)</Form.Label>
                  <Form.Control 
                    type="datetime-local"
                    id="finish"
                    placeholder="Enter finish time"
                    className="form-control-input"
                    // onChange={e => setEvent({...event, end: Date.now(e.target.value)})}
                    onChange={e => setEvent({...event, finish: new Date(e.target.value).getTime()})}
                    // onChange={e => dateTest(e)}
                  />
              </div>
              <div className="event-switches-container">
                {/* <div className="form-control-container-center">
                  <Col>
                    <Row>
                      <Form.Label className="form-control-label">Set Live</Form.Label>
                    </Row>
                    <Row>
                      <Form.Check 
                        type="switch"
                        id="live"
                        checked={event.live}
                        onChange={e => setEvent({...event, live: e.target.checked})}
                      />
                    </Row>
                  </Col>
                </div>
                <div className="form-control-container-center">
                  <Col>
                    <Row>
                      <Form.Label className="form-control-label">Set Open</Form.Label>
                    </Row>
                    <Row>
                      <Form.Check 
                        type="switch"
                        id="open"
                        checked={event.open}
                        onChange={e => setEvent({...event, open: e.target.checked})}
                      />
                    </Row>
                  </Col>
                </div> */}
                <div className="form-control-container-center">
                  <Col>
                    <Row>
                      <Form.Label className="form-control-label">Set Public</Form.Label>
                    </Row>
                    <Row>
                      <Form.Check 
                        type="switch"
                        id="public"
                        checked={event.public}
                        onChange={e => setEvent({...event, public: e.target.checked})}
                      />
                    </Row>
                  </Col>
                </div>
                <div className="form-control-container-center">
                  <Col>
                    <Row>
                      <Form.Label className="form-control-label">Set Random Winner</Form.Label>
                    </Row>
                    <Row>
                      <Form.Check 
                        type="switch"
                        id="randomWinner"
                        checked={event.randomWinner}
                        onChange={e => setEvent({...event, randomWinner: e.target.checked})}
                      />
                    </Row>
                  </Col>
                </div>
              </div>
              {event.randomWinner && (
                <div>
                  <Form.Label className="form-control-label">Random Winner Limit</Form.Label>
                    <Form.Control 
                      type="number"
                      id="winnersToRaffle"
                      placeholder="Enter random winner limit"
                      className="form-control-input"
                      onChange={e => setEvent({...event, winnersToRaffle: parseInt(e.target.value)})}
                    />
                </div>
                )}
            </Form.Group>
            {isLoading && (
              <div>
                <LoadingIndicator />
              </div>
            )}
              <Button onClick={() => createNewEvent()}>Create new event</Button>
              {/* <Button onClick={() => log()}>Log</Button> */}
              {/* <Button onClick={() => refreshExistingFolderImages()}>Get</Button> */}
              {/* <Button onClick={() => refreshAllEvents()}>Refresh events</Button> */}
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <EventList currentEvents={currentEvents} setCurrentEvents={setCurrentEvents} loggedInUser={loggedInUser}/>
      </Col>
        <ExistingImagesPage showExistingImagesModal={showExistingImagesModal} setShowExistingImagesModal={setShowExistingImagesModal} event={event} setEvent={setEvent} isImage={isImage}/>
      </Row>
    </div>
  )
};

export default ActivationsPage;