import React from 'react';

const Confirmation = () => {
    const test = "Done"
  
    return (
    <>
      <div></div>
      <div>
        <p><strong> Confrimed: {test}</strong> </p>
      </div>
      <div></div>
      <div></div>
    </>
  );
};

export default Confirmation;
