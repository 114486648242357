// import "./AvatarsPage.css";
const Uploader = ({ translations }) => {

  return (
    <div>
      <h1>Test</h1>
      <div className="testLayout">
        {translations && translations.map((translation) => <p>{translation.name}</p>)}
      </div>
    </div>
  )
}

export default Uploader;