import React, { useEffect, useState, useRef, useMemo, useCallback, forwardRef } from "react";
import * as Realm from "realm-web";
import { Button, Row, Col, Form, Modal, Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgInputNumberField } from "ag-grid-community";

const EventList = ({ currentEvents, setCurrentEvents, loggedInUser }) => {

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [testBool, setTestBool] = useState(false);
  const [eventRowData, setEventRowData] = useState([]);
  const [eventRowDataChanged, setEventRowDataChanged] = useState([]);
  const eventRef = useRef();

  const startTimeCellRenderer = (params) => {
    const startTime = new Date(params.value);
    return (
      <div>
        <p className="m-0">{startTime.toUTCString()}</p>
      </div>
    )
  };

  const startTimeCellEditor = forwardRef((params, ref) => {
    const startTimeRefContainer = useRef(null);
    return (
      <div>
        <input 
          type="datetime-local"
          ref={startTimeRefContainer}
          onChange={e => {
            params.data.start = (new Date(e.target.value).getTime());
            setEventRowDataChanged(eventRowDataChanged => [...eventRowDataChanged, params.data]);
          }}
        />
      </div>
    )
  });

  const finishTimeCellRenderer = (params) => {
    const finishTime = new Date(params.value);
    return (
      <div>
        <p className="m-0">{finishTime.toUTCString()}</p>
      </div>
    )
  };

  const publicCellRenderer = (params) => {
    return (
      <div>
        <p className="m-0">{params.value ? "true" : "false"}</p>
      </div>
    )
  };

  const imageCellRenderer = (params) => {
   if (params.column.colId === 'icon') {
    const url = "https://ik.imagekit.io/metropolis/tr:h-30/" + params.value;
      return (
        <div>
          <img
            src={url}
          />
        </div>
      )
    } else if(params.column.colId === 'prizeImage') {
    const url = "https://ik.imagekit.io/metropolis/tr:h-50/" + params.value;
      return (
        <div>
          <img
            src={url}
          />
        </div>
      )
    }
  };

  const checkboxCellRenderer = (params) => {
    return (
      <div>
        <input
          type="checkbox"
          checked={params.value}
          onChange={e => {
            console.log("Params: ", params);
            if(params.column.colId === 'public') {
              const newValue = !params.value;
              params.node.setDataValue('public', newValue);
            } else if (params.column.colId === 'randomWinner') {
              const newValue = !params.value;
              params.node.setDataValue('randomWinner', newValue);
            } else if (params.column.colId === 'open') {
              console.log("open click");
              const newValue = !params.value;
              params.node.setDataValue('open', newValue);
            } else if (params.column.colId === 'live') {
              console.log("live click");
              const newValue = !params.value;
              params.node.setDataValue('live', newValue);
            }
          }}
        />
      </div>
    )
  };

  const checkboxCellEditor = forwardRef((params, ref) => {
    console.log("editor params: ", params);
    return (
      <div>
        <input 
          type="text"
          onChange={e => {
            if(params.column.colId === 'public') {
              if(e.target.value === 'true') {
                params.node.setDataValue('public', true);
              } else if (e.target.value === 'false') {
                params.node.setDataValue('public', false);
              }
            } else if (params.column.colId === 'randomWinner') {
              if (e.target.value === 'true') {
                params.node.setDataValue('randomWinner', true);
              } else if (e.target.value === 'false') {
                params.node.setDataValue('randomWinner', false);
              }
            } else if (params.column.colId === 'open') {
              if(e.target.value === 'true') {
                params.node.setDataValue('open', true);
              } else if (e.target.value === 'false') {
                params.node.setDataValue('open', false);
              }
            } else if (params.column.colId === 'live') {
              if(e.target.value === 'true') {
                params.node.setDataValue('live', true);
              } else if (e.target.value === 'false') {
                params.node.setDataValue('live', false);
              }
            }
          }}
        />
      </div>
    )
  });

  const defaultColDef = useMemo(() => ({
    sortable: true,
    editable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    width: 'auto', 
    filterParams: {buttons: ['apply', 'cancel', 'reset'], closeOnApply: true},
  }));

  const [eventColData, setEventColData] = useState([
    {field: 'id'},
    {field: 'type'},
    {field: 'name'},
    {field: 'description', cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'difficulty', width: '80%'},
    {field: 'location', width: '80%'},
    {field: 'prizeName'},
    {field: 'prizeDescription', cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'icon', cellRenderer: imageCellRenderer},
    {field: 'prizeImage', cellRenderer: imageCellRenderer},
    {field: 'winTitle'},
    {field: 'winMessage', cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'failTitle'},
    {field: 'failMessage', cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true},
    {field: 'timeOfDay', width: '80%'},
    {field: 'start', cellRenderer: startTimeCellRenderer, cellEditor: startTimeCellEditor},
    {field: 'finish', cellRenderer: finishTimeCellRenderer},
    {field: 'public', width: '80%', cellRenderer: checkboxCellRenderer, cellEditor: checkboxCellEditor},
    {field: 'randomWinner', width: '80%', cellRenderer: checkboxCellRenderer, cellEditor: checkboxCellEditor},
    {field: 'open', width: '80%', cellRenderer: checkboxCellRenderer, cellEditor: checkboxCellEditor},
    {field: 'live', width: '80%', cellRenderer: checkboxCellRenderer, cellEditor: checkboxCellEditor},
    {field: 'winnersToRaffle', width: '80%'},
    {field: 'email'},
    {field: 'wallet'},
  ]);

  useEffect(() => {
    if(!currentEvents){
      refreshEventList();
    }
  }, []);

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  const refreshEventList = async () => {
    try {
      console.log("refreshing event list");
      const user = await getUser();
      const refreshEventListTxn = await user.functions.getAllEvents();
      setEventRowData(await refreshEventWinners(refreshEventListTxn));
    } catch (error) {
      console.log("Error refreshing event list: ", error);
    }
  };

  const refreshEventWinners = async (eventRowData) => {
    try {
      console.log("refreshing event winners");
      let tempVar = eventRowData;
      const user = await getUser();
      const refreshEventWinnersTxn = await user.functions.getEventWinners();
      console.log("refreshEventWinnersTxn: ", refreshEventWinnersTxn);
      for(let i = 0; i < refreshEventWinnersTxn.length; i++){
        if(eventRowData.find(item => item.id === refreshEventWinnersTxn[i].eventId)){
          const item = eventRowData.find(item => item.id === refreshEventWinnersTxn[i].eventId);
          const index = eventRowData.indexOf(item);
          tempVar[index].email = refreshEventWinnersTxn[i].email;
          tempVar[index].wallet = refreshEventWinnersTxn[i].wallet;
        }
      }
      return tempVar;
    } catch (error) {
      console.log("Error getting event winners: ", error);
      return;
    }
  }

  const test = async () => {
    console.log("Event", currentEvents);
    setTestBool(!testBool);
    const user = await getUser();
    console.log("Row data: ", eventRowData);
    console.log("Current row changes: ", eventRowDataChanged);
    const getExistingImagesTxn = await user.functions.getExistingImages('/activations');
    console.log("Existing images: ", JSON.parse(getExistingImagesTxn));
  };

  const submitChanges = async () => {
    try {
      const user = await getUser();
      console.log("eventRowDataChanged", eventRowDataChanged);
      console.log("currentEvents", currentEvents);
      console.log("Row data: ", eventRowData);
      for(let i = 0; i < eventRowDataChanged.length; i++) {
        const updateEventTxn = await user.functions.updateActivation(eventRowDataChanged[i].id, eventRowDataChanged[i]);
        console.log("Update Event", updateEventTxn);
        await user.functions.user_log(loggedInUser.name, 'updateActivation', {eventId: eventRowDataChanged[i].id, eventObject: eventRowDataChanged[i]});
      }
      refreshEventList();
    } catch (error) {
      console.log("Error submitting changes: ", error);
    }
  }

  // Call autoSizeAll when the spreadsheet data is first loaded.
  const onFirstDataRendered = useCallback(() => {
    autoSizeAll();
  }, []);

  // Autosizes all the columns (except for the ones in if statement). 
  const autoSizeAll = useCallback(() => {
    const allColumnIds = [];
    eventRef.current.columnApi.getColumns().forEach((column) => {
      // Autosizing these values disrupts the layout of the spreadsheet. They are set to display in a popup when clicked
      if(column.getId() === 'description' || column.getId() === 'prizeDescription') {
        return;
      }
      allColumnIds.push(column.getId());
    });
    eventRef.current.columnApi.autoSizeColumns(allColumnIds);
  }, []);

  const onCellValueChanged = (e) => {
    console.log("item: ", eventRowData.find(item => item.id === e.data.id));
    if(eventRowData.find(item => item.id === e.data.id)) {
      const items = eventRowData.find(item => item.id === e.data.id);
      const index = eventRowData.indexOf(items);
      let temp_state = [...eventRowData];
      let temp_element = {...temp_state[index]};
      temp_element = e.data;
      temp_state[index] = temp_element;
      setEventRowData(temp_state);
      console.log("Event row changed: ", eventRowData);
      if(!eventRowDataChanged.find(item => item.id === e.data.id)) {
        // setEventRowDataChanged([...eventRowDataChanged, e.data]);
        setEventRowDataChanged(eventRowDataChanged => [...eventRowDataChanged, e.data]);
      }
    } else {
      setEventRowData(prevRow => [...prevRow, e.data]);
      setEventRowDataChanged(prevRow => [...prevRow, e.data]);
    }
  };

  return (
    <div>
      <Card>
        <Card.Header>Event List</Card.Header>
          <Card.Body>
            <Button bsClass="btn-primary" onClick={() => test()}>Log</Button>
            <Button onClick={() => refreshEventWinners()}>Get winners</Button>
            <div aria-description="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 500}}>
              <AgGridReact
                ref={eventRef} 
                rowData={eventRowData}
                columnDefs={eventColData}
                defaultColDef={defaultColDef}
                onCellValueChanged={onCellValueChanged}
                onFirstDataRendered={onFirstDataRendered}
              />
            </div>
            <Button onClick={() => submitChanges()}>Submit Changes</Button>
          </Card.Body>
      </Card>
    </div>
  )
}

export default EventList;