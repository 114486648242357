import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ethers } from "ethers";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import { toHaveAttribute } from "@testing-library/jest-dom/dist/matchers";

const PaymentPage = ({setIsLoading, paymentSplitContract, currentWallet}) => {

const [paymentAddress, setPaymentAddress] = useState(currentWallet);
const [totalShares, setTotalShares] = useState(0);
const [newShares, setNewShares] = useState(0);
const [currentShares, setCurrentShares] = useState(0);
const [totalReleased, setTotalReleased] = useState(0);
const [currentReleased, setCurrentReleased] = useState(0);
const [currentBalance, setCurrentBalance] = useState(0);

const [closeConfirmation, setCloseConfirmation] = useState(false);
const [releaseAllConfirmation, setReleaseAllConfirmation] = useState(false);

const [signer, setSigner] = useState(null);

const [newPayeesOpen, setNewPayeesOpen] = useState(true);

useEffect(() => {
  loadPaymentData();
}, []);

useEffect(() => {
  closeNewPayees();
}, [closeConfirmation]);

useEffect(() => {
  releaseAll();
}, [releaseAllConfirmation]);

const loadPaymentData = async () => {
  getTotalShares();
  getCurrentShares();
  getTotalReleased();
  getCurrentReleased();
  getBalance();
  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(ethereum);
  setSigner(provider.getSigner());
};

// Updates total shares to display on site
const getTotalShares = async () => {
  try {
    const totalSharesTxn = await paymentSplitContract.totalShares();
    setTotalShares(totalSharesTxn.toNumber());
    console.log("Total shares: ", totalShares);
  } catch (error) {
    console.log("Error retreiving total shares: ", error);
  }
};

// Updates current shares to display on site
const getCurrentShares = async () => {
  try {
    const getSharesTxn = await paymentSplitContract.shares(paymentAddress);
    setCurrentShares(getSharesTxn.toNumber());
    console.log("Current shares: ", currentShares);
  } catch (error) {
    console.log("Error retrieving current shares: ", error);
  }
};

const getTotalReleased = async () => {
  try {
    const getTotalReleasedTxn = await paymentSplitContract.totalReleased();
    setTotalReleased(getTotalReleasedTxn.toNumber());
    console.log("Total released: ", totalReleased);
  } catch (error) {
    console.log("Error retrieving total released: ", error);
  }
};

const getCurrentReleased = async () => {
  try {
    const getCurrentReleasedTxn = await paymentSplitContract.released(paymentAddress);
    setCurrentReleased(getCurrentReleasedTxn.toNumber());
    console.log("Current released: ", currentReleased);
  } catch (error) {
    console.log("Error retrieving current released: ", error);
  }
};

const getBalance  = async () => {
  try {
    const getBalanceTxn = await paymentSplitContract.getBalance();
    // Convert hex to string then parse the string to int then convert back to int
    const hexToInt = parseInt(getBalanceTxn._hex.toString()).toString();
    // Convert hex to Eth
    setCurrentBalance(formatEther(hexToInt));
    console.log("Balance: ", currentBalance);
  } catch (error) {
    console.log("Error retrieving balance: ", error);
  }
};

const sendEth = async () => {
  try {
    // const sendEthTxn = await paymentSplitContract.sendTransaction({value: parseEther("0.01")});
    const sendEthTxn = await signer.sendTransaction({to: "0xB727587b312E74f31E9Fc3Bd32B74F3a5877057A", value: parseEther("1.0")});
    // await sendEthTxn.wait();
    console.log("Send eth txn: ", sendEthTxn);
    getBalance();
  } catch (error) {
    console.log("Error sending ETH: ", error);
  }
};

/*********************** Functions for payment split contract ***********************/
// Handle user input
const handlePaymentAddress = async (e) => {
  setPaymentAddress(e.target.value);
};
const handleShares = async (e) => {
  setNewShares(e.target.value);
};

// Add address to payment split contract
const addAddressToPaymentSplit = async () => {
  setIsLoading(true);
  try {
    const addAddressTxn = await paymentSplitContract.addPayee(paymentAddress, newShares, {gasLimit: 300000});
    await addAddressTxn.wait();
    console.log("addAddressTxn: ", addAddressTxn);
    getCurrentShares();
    getTotalShares();
  } catch (error) {
    console.log("Error adding address to payment split: ", error);
  }
  setIsLoading(false);
};

const confirmCloseNewPayees = () => {
  setCloseConfirmation(window.confirm("Are you sure you want to close new payees?"));
};

const confirmReleaseAll = () => {
  setReleaseAllConfirmation(window.confirm("Are you sure you want to release all?"));
};

const closeNewPayees = async () => {
  if(closeConfirmation) {
    try {
      const closeNewPayeesTxn = await paymentSplitContract.closeNewPayees();
      console.log("Close new payees txn: ", closeNewPayeesTxn);
      setCloseConfirmation(false);
      setNewPayeesOpen(false);
    } catch (error) {
      console.log("Error closing new payees: ", error);
    }
  }
};

const releaseAll = async () => {
  if(releaseAllConfirmation) {
    try {
      const releaseAllTxn = await paymentSplitContract.releaseAll({gasLimit: 300000});
      await releaseAllTxn.wait();
      console.log("Release all txn: ", releaseAllTxn);
      setReleaseAllConfirmation(false);
    } catch (error) {
      console.log("Error releasing all: ", error);
    }
  }
};

const releaseShares = async () => {
  setIsLoading(true);
  try {
    const releaseSharesTxn = await paymentSplitContract.release(paymentAddress, {gasLimit: 300000});
    // await releaseSharesTxn.wait();
    console.log("Release shares txn: ", releaseSharesTxn);
  } catch (error) {
    console.log("Error releasing shares: ", error);
  }
  setIsLoading(false);
};

  return (
    <div className="payment-split-container">
        <Card>
          <Row>
            <Card.Title>Payment Section</Card.Title>
          </Row>
          <Button onClick={() => sendEth()}>Send</Button>
          <Row>
            <Card.Body>
              <Row>
                <Col>
                  Total released: {currentReleased}/{totalReleased}
                </Col>
                <Col>
                  Your shares: {currentShares}/{totalShares}
                </Col>
                <Col>
                  Balance: {currentBalance} ETH
                </Col>
              </Row>
              {/* <Button onClick={() => getBalance()}>Get</Button> */}
                <Form.Group className="mb-3 d-inline-block">
                  <Row>
                    <Form.Label>Address</Form.Label>
                  </Row>
                  <Row>
                    <Form.Control 
                      type="string"
                      placeholder="Address"
                      value={paymentAddress}
                      onChange={handlePaymentAddress}
                    />
                  </Row>
                </Form.Group>
                {newPayeesOpen && (
                  <Col className="mb-3">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-button-1">Enter address to retrieve their shares</Tooltip>}
                    >
                      <Button onClick={() => getCurrentShares()}>Get shares of address</Button>
                    </OverlayTrigger>
                  </Col>
                  )}
                  <Col>
                    <OverlayTrigger 
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-button-1">Enter address to release their shares</Tooltip>}
                    >
                      <Button onClick={() => releaseShares()}>Release shares</Button>
                    </OverlayTrigger>
                  </Col>
            </Card.Body>
          </Row>

          {newPayeesOpen && (
          <Row>
            <Card.Body>
              <Form.Group className="mb-3 d-inline-block">
                <Row>
                  <Form.Label>Add number of shares</Form.Label>
                </Row>
                <Row>
                  <Form.Control 
                    className="number-form"
                    type="number"
                    placeholder="Shares"
                    value={newShares}
                    onChange={handleShares}
                  />
                </Row>
              </Form.Group>
                <Row xs={3} className="justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-button-1">Add selected address and amount of shares to payment split contract</Tooltip>}
                  >
                    <Button className="form-btn" onClick={() => addAddressToPaymentSplit()}>Add new payment split</Button>
                  </OverlayTrigger>
                </Row>
            </Card.Body>
          </Row>
          )}

          <Row>
            <Card.Body>
              <Col className="mb-3">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-button-1">Prevents addition of new payees</Tooltip>}
                >
                  <Button onClick={() => confirmCloseNewPayees()} variant="danger">Close payees</Button>
                </OverlayTrigger>
              </Col>
              {!newPayeesOpen && (
              <Col>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-button-1">Transfer to all accounts with Ether owed</Tooltip>}
                >
                  <Button onClick={() => confirmReleaseAll()} variant="danger">Release all</Button>
                </OverlayTrigger>
              </Col>
              )}
            </Card.Body>
          </Row>
      </Card>
      </div>
  );
}

export default PaymentPage;