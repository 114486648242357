import { useState, useEffect } from 'react';

const Eggs = ({ eggCollectionContract }) => {

  const [currentTokenId, setCurrentTokenId] = useState(1);

  const checkRedeemStatus = async () => {
    const hasBeenRedeemedTxn = await eggCollectionContract.hasBeenRedeemed(currentTokenId);
    console.log("Has been redeemed: ", hasBeenRedeemedTxn);
  }

  return (
    <div className="eggs">
      <h1>Eggs</h1>
      <button onClick={() => checkRedeemStatus()}>Redeemed?</button>
    </div>
  );
}

export default Eggs;