import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import * as Realm from "realm-web";
import bcrypt from "bcryptjs";

const UsersPage = () => {
  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [level, setLevel] = useState("");
  const [users, setUsers] = useState([]);
  const handleChange = (e) => {
    setUsername(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeLevel = (e) => {
    setLevel(e.target.value);
  };
  const handleSubmit = async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    const salt = '$2a$10$.qotYSqDjhj0JVUGzfk1q.'
    try {
      const hashedPassword = bcrypt.hashSync(
        password,
        salt
      );
      // Authenticate the user
      const user = await realmApp.logIn(credentials);
      const add = await user.functions.createUser(
        name,
        level,
        username,
        hashedPassword
      );
      alert("user added", add);
    } catch (err) {
      console.error("Failed to get minted list", err);
    }
  };
  const getUsers = async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate the user
      const user = await realmApp.logIn(credentials);
      const items = await user.functions.getAllUsers();
      setUsers(items);
    } catch (err) {
      console.error("Failed to get minted list", err);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Card>
      <Row className="mt-1">
        <Col>
          <Card>
            <Card.Header>All Users</Card.Header>
            <Card.Body>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Level</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((u, index) => (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{u.name}</td>
                      <td>{u.email}</td>
                      <td>{u.level}</td>
                      <td>
                        <Button>Edit</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>Add User</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    value={name}
                    onChange={handleChangeName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={username}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={handleChangePassword}
                  />
                </Form.Group>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleChangeLevel}
                >
                  <option>Choose User type</option>
                  <option value="admin">Admin</option>
                  <option value="member">Member</option>
                  <option value="guest">Guest</option>
                </Form.Select>
                <Button onClick={handleSubmit}>Submit form</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default UsersPage;
