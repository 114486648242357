import React, { useEffect, useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import ToolTipComponent from "../ToolTip";

const MintingComponent = ({ setIsLoading, accessTokenContract, currentPrice, getCurrentSupply}) => {

const [freeMintPassportAddress, setFreeMintPassportAddress] = useState("");
const [freeMintUserVariable, setFreeMintUserVariable] = useState("");
const [freeMintUsersList, setFreeMintUsersList] = useState([]);
const [bulkMintAmount, setBulkMintAmount] = useState(0);
const [bulkMintAddress, setBulkMintAddress] = useState("");


/*********************** Functions for access token contract ***********************/

const handleFreeMintPassportAddress = (e) => {
  setFreeMintPassportAddress(e.target.value);
};
const handleFreeMintUserVariable = (e) => {
  setFreeMintUserVariable(e.target.value);
};
const handleBulkMintAddress = (e) => {
  setBulkMintAddress(e.target.value);
};
const handleBulkMintAmount = (e) => {
  setBulkMintAmount(e.target.value);
};

// Free mint
const freeMint = async () => {
  if (freeMintPassportAddress === "") {
    alert("Please enter a passport address");
  } else {
    try {
      const freeMintTxn = await accessTokenContract.freeMint(freeMintPassportAddress);
      setIsLoading(true);
      await freeMintTxn.wait();
      console.log("free mint: ", freeMintTxn);
      getCurrentSupply();
    } catch (error) {
      console.log("Error with free mint: ", error);
    }
    setIsLoading(false);
  }
};

const addFreeMintUsersList = () => {
  try {
    setFreeMintUsersList(oldList => [...oldList, freeMintUserVariable]);
    setFreeMintUserVariable("");
  } catch (error) {
    console.log("Error adding users to free mint list: ", error);
  }
};

const addFreeMintUsers = async () => {
  if(freeMintUsersList.length === 0) {
    alert("Please enter at least one user");
  } else {
    try {
      setIsLoading(true);
      console.log("free mint list: ", freeMintUsersList);
      const addFreeMintUsersTxn = await accessTokenContract.setFreeMinters(freeMintUsersList);
      await addFreeMintUsersTxn.wait();
      console.log("Add free mint users txn: ", addFreeMintUsersTxn);
    } catch (error) {
      console.log("Error with adding free mint users: ", error);
    }
    setIsLoading(false);
  }
};

const freeUserMint = async () => {
  try {
    setIsLoading(true);
    const freeUserMintTxn = await accessTokenContract.userFreeMint();
    await freeUserMintTxn.wait();
    console.log("Free user mint txn: ", freeUserMintTxn);
  } catch (error) {
    console.log("Error with free user mint: ", error);
  }
  setIsLoading(false);
};

const paidMint = async () => {
  try {
    setIsLoading(true);
    const paidMintTxn = await accessTokenContract.paidMint({ value: parseEther(currentPrice) });
    await paidMintTxn.wait();
    console.log("Paid mint txn: ", paidMintTxn);
  } catch (error) {
    console.log("Error with paid mint: ", error);
  }
  setIsLoading(false);
};

const bulkMint = async () => {
  try {
    setIsLoading(true);
    const bulkMintTxn = await accessTokenContract.bulkMint(bulkMintAmount, bulkMintAddress, {value: parseEther(currentPrice)*bulkMintAmount});
    await bulkMintTxn.wait();
    console.log("Bulk mint txn: ", bulkMintTxn);
  } catch (error) {
    console.log("Error with bulk mint: ", error);
  }
  setIsLoading(false);
};

// Check if connect wallet has an NFT 
const checkIfHasPassport = async (address) => {
  try {
    const checkIfHasPassportTxn = await accessTokenContract.checkIfHasNFT(address);
    console.log("Check if has NFT: ", checkIfHasPassportTxn);
    return checkIfHasPassportTxn;
  } catch (error) {
    console.log("Error checking if account has NFT: ", error);
  } 
};

  return (
    <Card>
      <Card.Header>Minting</Card.Header>
        <Container>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3 d-inline-block">
                  <Row>
                    <Col>
                      <Form.Label>Give free mint</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Control 
                      type="string"
                      placeholder="0x..."
                      value={freeMintPassportAddress}
                      onChange={handleFreeMintPassportAddress}
                    />
                  </Row>
                </Form.Group>
                  <Row xs={3} className="justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-button-1">Give free passport to an address</Tooltip>}
                    >
                      <Button onClick={() => freeMint()}>Free Mint</Button>
                    </OverlayTrigger>
                  </Row>
              </Col>
            </Row>
          </Card.Body>
        </Container>

        <Container>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3 d-inline-block">
                  <Row>
                    <Col>
                      <Form.Label>Add users to free mint list</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Control 
                      type="string"
                      placeholder="0x..."
                      value={freeMintUserVariable}
                      onChange={handleFreeMintUserVariable}
                    />
                  </Row>
                </Form.Group>
                  <Row xs={3} className="justify-content-center">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-button-1">Add address to list of users who can claim a free mint</Tooltip>}
                    >
                      <Button onClick={() => addFreeMintUsersList()}>Add user</Button>
                    </OverlayTrigger>
                  </Row>
              </Col>
            </Row>
          </Card.Body>
        </Container>

        <Container>
          <Card.Body>
            <Row>
              <Col>
                <Row className="mb-3">
                  <Col>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-button-1">Allow added users to claim free mint</Tooltip>}
                    >
                      <Button onClick={() => addFreeMintUsers()}>Allow listed users access to free mint</Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <Row> 
                  <Col>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-button-1">Claim free mint as user</Tooltip>}
                      >
                      <Button onClick={() => freeUserMint()}>User free mint</Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Container>

        <Container>
          <Card.Body>
            <Row>
              <Col>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-button-1">!! PAID FUNCTION !! Will use ETH to mint a passport to connected address</Tooltip>}
                >
                  <Button variant="danger" onClick={() => paidMint()}>Paid mint</Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Card.Body>
        </Container>

        <Container>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3 d-inline-block">
                  <Row>
                    <Col>
                      <Form.Label>Bulk mint</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Control 
                      type="number"
                      placeholder="Amount"
                      value={bulkMintAmount}
                      onChange={handleBulkMintAmount}            
                    />
                  </Row>
                  <Row>
                    <Form.Control 
                      type="string"
                      placeholder="0x..."
                      value={bulkMintAddress}
                      onChange={handleBulkMintAddress}
                    />
                  </Row>
                </Form.Group>
                  <Row xs={3} className="justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-button-1">Mints a specificed amount of passports to address</Tooltip>}
                    >
                      <Button variant="danger" onClick={() => bulkMint()}>Bulk mint</Button>
                    </OverlayTrigger>
                  </Row>
              </Col>
            </Row>
          </Card.Body>
        </Container>
    </Card>
  );
};

export default MintingComponent;