import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";
import { parseUnits } from "ethers/lib/utils";
import { BigNumber } from "ethers";
import "./UserPage.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ToolTipComponent from "../ToolTip";
import SoftClayComponent from "./SoftClayComponent";
import LevelComponent from "./LevelComponent";
import WinChanceComponent from "./WinChanceComponent";

const UserPage = ({ setIsLoading, currentAccount, passportIndex, accessTokenContract, softClayContract, winChanceContract }) => {

  return (
    <div>
      <Row>
        <h1>User Page</h1>
      </Row>
      <Row>
        <Col>
          <SoftClayComponent setIsLoading={setIsLoading} currentAccount={currentAccount} passportIndex={passportIndex} accessTokenContract={accessTokenContract} softClayContract={softClayContract} />
        </Col>
        <Col>
          <LevelComponent setIsLoading={setIsLoading} softClayContract={softClayContract}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <WinChanceComponent setIsLoading={setIsLoading} winChanceContract={winChanceContract} accessTokenContract={accessTokenContract}/>
        </Col>
      </Row>
    </div>
  );
}

export default UserPage;