import { Button, Modal } from "react-bootstrap";
// import { Blob } from "nft.storage";
import { NFTStorage } from 'nft.storage/dist/bundle.esm.min.js'
import { useState, useEffect } from "react";
// import { flushSync } from "react-dom";
import fs from "fs";
import path from "path"

const UploadToIPFSPage = ({setIsLoading, showIPFSModal, setShowIPFSModal}) => {

  const [nftStorageClient, setNftStorageClient] = useState(null);


  useEffect(() => {
    if(nftStorageClient == null) {
      const client = new NFTStorage({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDgxNTQzNWJCNmI4NDc3Y0EzMTFDRmRlQkM2NTQ1NTllMzk5ODAwZDIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MzM0MDA5NjI2NCwibmFtZSI6ImZpcnN0S2V5In0.3iwDBOfWQY2rSVYUSmrUFKL2H2PtGzsrUA4yRF3Eq-0' });
      setNftStorageClient(client);
      console.log("client created", client);
    }
  }, []);

  const storeImage = async () => {
    const imageFile = await loadImage("C:/Users/JTSte/Downloads/The_Mine.png");
    const metadata = await nftStorageClient.store({
      name: "Testname",
      description: "Testdescription",
      image: imageFile,
    });

    console.log("metadata", metadata);
  };

  const loadImage = async (path) => {
    const reader = new FileReader();
    const testData = await convertToBase64(path.target.files[0]);
    // const binary = reader.readAsBinaryString(path.target.files[0]);
    const imageFileTest = new File([testData], path.target.files[0].name, { type: path.target.files[0].type });
      console.log("imageFileTest: ", imageFileTest);
      const metadata = await nftStorageClient.store({
        name: "Testname",
        description: "Testdescription",
        image: imageFileTest
      });
      console.log("metadata", metadata);
    // reader.onload = async () => {
    //   console.log("File: ", reader.result);
    //   const imageFileTest = new File([testData], path.target.files[0].name, { type: path.target.files[0].type });
    //   console.log("imageFileTest: ", imageFileTest);
    //   const metadata = await nftStorageClient.store({
    //     name: "Testname",
    //     description: "Testdescription",
    //     image: imageFileTest
    //   });
    //   console.log("metadata", metadata);
    // };
    // reader.onerror = () => {
    //   console.log("Error");
    // }
  };

  const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
        console.log("fileReader.result", fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  return (
    <div>
      <Modal size="xl" show={showIPFSModal} onHide={() => setShowIPFSModal(false)}>
        <Button onClick={() => storeImage()}>Test</Button>
        <input 
          type="file"
          onChange={loadImage}
        />
      </Modal>
    </div>
  );
}

export default UploadToIPFSPage;