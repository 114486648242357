import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Downshift from "downshift";
import * as Realm from "realm-web";
import userEvent from "@testing-library/user-event";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const WhitelistPage = ({ setIsLoading, accessTokenContract, lotteryContract, realmApp, nftRealmApp, setShowNavBar, setShowPassportPage }) => {

// const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
// const nftRealmApp = new Realm.App({ id: process.env.REACT_APP_NFTDROPAPP });
const [user, setUser] = useState(null);
const [dropUser, setDropUser] = useState(null);

const [showWhitelistPage, setShowWhitelistPage] = useState(true);

const [cityName, setCityName] = useState("city1");
const [buildingId, setBuildingId] = useState(-1);
const [buildingName, setBuildingName] = useState('');
const [propertyItems, setPropertyItems] = useState(null);
const [passportId, setPassportId] = useState(null);
const [propertyRecording, setPropertyRecording] = useState("");
const [properties, setProperties] = useState(null);
const [whitelistPassportAddress, setWhitelistPassportAddress] = useState("");

const [passportIndex, setPassportIndex] = useState(0);

const [testState, setTestState] = useState(null);

useEffect(() => {
  loadWhitelistData();
},[]);

useEffect(() => {
  if(user != null) {
    getPropertyNames();
  }
}, [user]);

// Map properties to be displayed in whitelist dropdown menu
useEffect(() => {
  if(properties != null) {
    setPropertyItems(properties.map(properties => ({
      value: properties.name,
      id: properties.id,
    })))
  }
}, [properties]);

const loadWhitelistData = async () => {
  getUser();
  getNFTDropUser();
};

const getUser = async () => {
  const apiKey = process.env.REACT_APP_MONGOAPIKEY;
  const credentials = Realm.Credentials.apiKey(apiKey);
  try {
    // Authenticate user
    const user = await realmApp.logIn(credentials);
    setUser(user);
  } catch (error) {
    console.log("Error getting user: ", error);
  }
};

// Instance of NFT Drop MonogoDB - Property functions 
// Eventually add necessary functions to mission control to remove this
const getNFTDropUser = async () => {
  const nftDropApiKey = process.env.REACT_APP_NFTDROPKEY;
  const nftDropCredentials = Realm.Credentials.apiKey(nftDropApiKey);
  try {
    const nftDropUser = await nftRealmApp.logIn(nftDropCredentials);
    setDropUser(nftDropUser);
    console.log("NFT drop user: ", dropUser);
  } catch (error) {
    console.log("Error getting nft drop user: ", error);
  }
};

const getPropertyNames = async() => {
  try {
    const getPropertyTxn = await user.functions.all_properties();
    setProperties(getPropertyTxn);
    console.log("Property txn: ", getPropertyTxn);
  } catch (error) {
    console.log("Error getting property names: ", error);
  }
};

// // Check if connect wallet has an NFT 
const checkIfHasPassport = async (address) => {
  try {
    const checkIfHasPassportTxn = await accessTokenContract.checkIfHasNFT(address);
    console.log("Check if has NFT: ", checkIfHasPassportTxn);
    return checkIfHasPassportTxn;
  } catch (error) {
    console.log("Error checking if account has NFT: ", error);
  } 
};

/*********************** Functions for lottery Contract ***********************/
// Handle user input
const handleCityName = (e) => {
  setCityName(e.target.value);
};
const handleWhitelistSelection = (e) => {
  setBuildingName(e.value);
  setBuildingId(e.id);
};

const handleWhitelistPassportAddress = (e) => {
  setWhitelistPassportAddress(e.target.value);
};

const addAddressToWhitelist = async () => {
  try {
    const checkIfHasPassportTxn = await checkIfHasPassport(whitelistPassportAddress);
    const passportIdConvert = checkIfHasPassportTxn[passportIndex].id.toNumber();
    setPassportId(passportIdConvert);
    console.log("pass ID: ", passportId);
  } catch (error) {
    console.log("Error adding address to whitelist: ", error);
  }
};

const whitelistLotteryTrigger = async () => {
  try {
    setIsLoading(true);
    const recordOffChainWinnerTxn = await lotteryContract.recordOffChainWinner(cityName, buildingId, buildingName, passportId, propertyRecording, {gasLimit: 300000});
    await recordOffChainWinnerTxn.wait();
    console.log("Property winner txn: ", recordOffChainWinnerTxn);
    console.log("Passport ID:", recordOffChainWinnerTxn.value.toNumber(), "won", buildingName, "!");
    checkWinner(cityName, buildingId, recordOffChainWinnerTxn.value.toNumber());
  } catch (error) {
    console.log("Error picking property winner: ", error);
  }
  setIsLoading(false);
};

const checkWinner = async (cityName, buildingId, passportId) => {
  try {
    const checkWinnerTxn = await lotteryContract.verifyWinner(cityName, buildingId, passportId);
    if(checkWinnerTxn) {
      console.log(passportId, "is the owner");
    } else {
      console.log(passportId, "is not the owner");
    } 
  } catch (error) {
    console.log("Error checking winner: ", error);
  }
};

  return (
    <div className="whitelist-container">
        <Card>
          <Card.Header>Whitelist</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3 d-inline-block">
                    <Row>
                      <Form.Label>City Name</Form.Label>
                    </Row>
                    <Row>
                      <Form.Control 
                        type="string"
                        placeholder="City"
                        value={cityName}
                        onChange={handleCityName}
                      />
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>

          <Card.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3 d-inline-block">
                  <Row>
                    <Form.Label>Passport account</Form.Label>
                  </Row>
                  <Row>
                    <Form.Control 
                      type="string"
                      placeholder="Address"
                      value={whitelistPassportAddress}
                      onChange={handleWhitelistPassportAddress}
                    />
                  </Row>
               </Form.Group>
                  <Row xs={3} className="justify-content-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-button-1">Add property winner from off chain lottery</Tooltip>}
                    >
                      <Button className="form-btn" onClick={addAddressToWhitelist}>Add address</Button>
                    </OverlayTrigger>
                  </Row>
              </Col>
            </Row>
            </Card.Body>

            <Card.Body>
              <Row className="justify-content-center">
                <Col>
                  <Form.Group className="d-inline-block">
                    <Row>
                      {/* 
                          Enables autocomplete drop down menu for building names and sets the state of buildling selected 
                          to be passed into whitelist lottery contract 
                      */}
                      <Downshift 
                        onChange={selection => {
                          handleWhitelistSelection(selection);
                          }
                        }
                        itemToString={item => (item ? item.value : '')}
                      >
                        {({
                          getInputProps,
                          getItemProps,
                          getLabelProps,
                          getMenuProps,
                          isOpen,
                          inputValue,
                          highlightedIndex,
                          selectedItem,
                          getRootProps,
                        }) => (
                          <div>
                            <Row>
                              <label {...getLabelProps()}>Enter a building: </label>
                            </Row>
                            <Row>
                              <div
                                style={{display: 'inline-block'}}
                                {...getRootProps({}, {suppressRefError: true})}
                              >
                                <input className="border-primary m-1" {...getInputProps()} />
                              </div>
                            </Row>
                            <Row className="overflow-auto">
                            <ul {...getMenuProps()} className="building-name-list">
                              {isOpen
                                ? propertyItems
                                    .filter(item => !inputValue || item.value.includes(inputValue))
                                    .map((item, index) => (
                                      <li
                                        {...getItemProps({
                                          key: item.id,
                                          index,
                                          item,
                                          style: {
                                            backgroundColor:
                                              highlightedIndex === index ? 'lightgray' : 'white',
                                            fontWeight: selectedItem === item ? 'bold' : 'normal',
                                          },
                                        })}
                                      >
                                        {item.id}: {item.value}
                                      </li>
                                    ))
                                : null}
                            </ul>
                            </Row>
                          </div>
                        )}
                      </Downshift>
                    </Row>
                  </Form.Group>
                <Row xs={3} className="justify-content-center">
                  <Button className="form-btn" onClick={() => whitelistLotteryTrigger()}>Whitelist</Button>
                </Row>
                </Col>
              </Row>
            </Card.Body>
        </Card>
      </div>
  );
}

export default WhitelistPage;