import React, { useEffect, useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils";
import ToolTipComponent from "../ToolTip";
import ImageSection from "../ImageSection";
import MintingComponent from "./MintingComponent";

const TokenPage = ({ setIsLoading, isLoading, currentWallet, contracts, setCurrentPrice, currentPrice, setCurrentLimit, setCurrentSupply }) => {

// Local state for access token contract
const [newPrice, setNewPrice] = useState("");
const [newLimit, setNewLimit] = useState("");

// Updates currentLimit to display on site
const getCurrentLimit = async () => {
  try {
    const currentLimitTxn = await contracts.accessTokenContract._mintLimit();
    setCurrentLimit(currentLimitTxn); 
  } catch (error) {
    console.log("Error retrieving current mint limit: ", error);
  }
};

// Updates currentSupply to display on site
const getCurrentSupply = async () => {
  try {
    const getTokenIdTxn = await contracts.accessTokenContract.getCurrentTokenId();
    setCurrentSupply(getTokenIdTxn - 1);
  } catch (error) {
    console.log("Error retreiving current token ID: ", error);
  }
};

// Updates current price to display on site
const getCurrentPrice = async () => {
  try {
    const currentPriceTxn = await contracts.accessTokenContract._navPrice();
    // Format WEI to Ether
    setCurrentPrice(formatEther(currentPriceTxn));
  } catch (error) {
    console.log("Error retrieving current price: ", error);
  }
};


/*********************** Functions for access token contract ***********************/

// Handle user input
const handleNewLimit = (e) => {
  setNewLimit(e.target.value);
};
const handleNewPrice = (e) => {
  setNewPrice(e.target.value);
};

// Set price of NFT
const setPrice = async (newPrice) => {
  if(newPrice === "") { 
    alert("Please enter a price");
  } else {
    try {
      const setPriceTxn = await contracts.accessTokenContract.setPrice(parseEther(newPrice));
      setIsLoading(true);
      await setPriceTxn.wait();
      console.log("Set price: ", setPriceTxn);
      getCurrentPrice();
    } catch (error) {
      console.log("Error setting price: ", error)
    }
    setIsLoading(false);
  }
};

// Set limit of total NFTs that can be minted
const changeLimit = async (newLimit) => {
  if(newLimit === "") {
    alert("Please enter a limit");
  } else {
    try {
      const setLimitTxn = await contracts.accessTokenContract.setMintLimit(newLimit);
      setIsLoading(true);
      await setLimitTxn.wait();
      console.log("change limit: ", setLimitTxn);
      getCurrentLimit();
    } catch (error) {
      console.log("Error changing limit: ", error);
    }
    setIsLoading(false);
  }
};

  return (
    <div className="access-token-container">
      <Row>
        <Col>
          <MintingComponent setIsLoading={setIsLoading} accessTokenContract={contracts.accessTokenContract} currentPrice={currentPrice} getCurrentSupply={getCurrentSupply}/>
        </Col>
        <Col>
          <Card>
            <Card.Header>Token Information</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group className="mb-3 d-inline-block">
                      <Row>
                        <Col>
                          <Form.Label>Set new price in ETH</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Control
                          className="number-form"
                          type="number"
                          placeholder="New price"
                          value={newPrice}
                          onChange={handleNewPrice}
                        />
                      </Row>
                    </Form.Group>
                      <Row xs={3} className="justify-content-center">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip-button-1">Set new passport price in ETH</Tooltip>}
                          >
                          <Button className="form-btn" onClick={() => setPrice(newPrice)}>Set price</Button>
                        </OverlayTrigger>
                      </Row>
                  </Col>
                </Row>
              </Card.Body>

              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group className="mb-3 d-inline-block">
                      <Row>
                        <Col>
                          <Form.Label>Set new limit</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Control
                          className="number-form"
                          type="number"
                          placeholder="New limit"
                          value={newLimit}
                          onChange={handleNewLimit}
                        />
                      </Row>
                    </Form.Group>
                      <Row xs={3} className="justify-content-center">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="tooltip-button-1">Set new passport supply limit</Tooltip>}
                        >
                          <Button className="form-btn" onClick={() => changeLimit(newLimit)}>Set limit</Button>
                        </OverlayTrigger>
                      </Row>
                  </Col>
                </Row>
              </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageSection setIsLoading={setIsLoading} imageContract={contracts.imageContract}/>
        </Col>
      </Row>
      </div>
  )
};

export default TokenPage;