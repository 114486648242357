import React from 'react';
import './LoadingIndicator.css';
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

const LoadingIndicator = () => {
  return (
    <>
      <div></div>
      <div>
        <Alert className="d-flex justify-content-center" variant='success'>Loading or uploading...</Alert>
        <div className="d-flex justify-content-center m-2">
        <Spinner animation="border" variant="success"/>
        </div>
      </div>
      <div></div>
      <div></div>
    </>
  );
};

export default LoadingIndicator;
