import React, { useEffect, useState } from "react";
import { CONTRACT_ADDRESS, PAYMENT_SPLIT_CONTRACT_ADDRESS, IMAGE_DATA_CONTRACT_ADDRESS, LOTTERY_CONTRACT_ADDRESS, transformPassportData } from "../../constants";
import MetropolisWorldAccessToken from "../../utils/AccessToken.json";
import MetropolisWorldImageContract from "../../utils/ImageData.json";
import MetropolisWorldPaymentSplit from "../../utils/PaymentSplitPassport.json";
import MetropolisWorldLottery from "../../utils/Lottery.json";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Nav, Dropdown, Row, Col, Card } from "react-bootstrap";
import Clock from "react-live-clock";
import * as Icon from "react-bootstrap-icons";
import { ethers } from "ethers";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as Realm from "realm-web";
import bcrypt from "bcryptjs";
import LoadingIndicator from "../LoadingIndicator";
import { transformPannelData } from "../../constants";
import MetropolisIcon from "../../assets/MetropolisIcon.png";
import "./NavBar.css";

const NavigationBar = ({ currentAccount, setCurrentAccount, setUserPage, setDetailsPage, currentWallet, setCurrentWallet, showUnused, setShowUnused}) => {
  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [show, setShow] = useState(false);
  const [pwd, setPwd] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePwd = (e) => {
    setPwd(e.target.value);
  };
  const handleLogout = () => {
    setCurrentAccount(null);
    localStorage.clear();
  };

  const handleUserPage = () => {
    console.log("clicked it");
    setUserPage(true);
  };

  const goHome = () => {
    setUserPage(false);
    setDetailsPage(false);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const name = JSON.parse(currentAccount).name;
  const level = JSON.parse(currentAccount).level;

  const handleConnectWallet = async () => {
    try {
      const { ethereum } = window;
      if(!ethereum) {
        alert("Try MetaMask!");
        return;
      }
      const accounts = await ethereum.request({ method: 'eth_requestAccounts'});
      console.log("Connected: ", accounts[0]);
      setCurrentWallet(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePasswordDb = async () => {
    setIsLoading(true);
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    const salt = "$2a$10$.qotYSqDjhj0JVUGzfk1q.";
    try {
      const hashedPassword = bcrypt.hashSync(pwd, salt);
      // Authenticate the user
      const user = await realmApp.logIn(credentials);
      const add = await user.functions.updatePassword(
        currentAccount.id,
        hashedPassword
      );
      console.log(add);
      setIsLoading(false);
      setShow(false);
      alert("password updated");
    } catch (err) {
      console.error("Failed to get minted list", err);
    }
  };

  return (
    <>
      <Navbar bg="light" variant="light" expand="lg">
        {/* <Container fluid> */}
          {/* <Navbar.Brand href="#" onClick={goHome}>
            <img
              alt=""
              // src="../../assets/apple-touch-icon.png"
              src={MetropolisIcon}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Mission Control
          </Navbar.Brand> */}
          <Navbar.Collapse className="justify-content-end">
            <div className="wallet-container">
          {currentWallet ? (<p>
            {currentWallet.slice(0,5) + '...' + currentWallet.slice(38,42)}
          </p>) : (
            <Nav.Link>
              <Button onClick={handleConnectWallet}>
                Connect Wallet
              </Button>
            </Nav.Link> 
          )}
            </div>
          <div className="vertical-line"></div>
          <div className="hello-name">
            <p>{name}</p>
          </div>
            <Dropdown align="end">
              <Dropdown.Toggle className="settings-icon">
                <Icon.GearFill />
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className="btn-info" onClick={handleShow}>Change password</Dropdown.Item>
                  {level === "admin" ? (
                    <Dropdown.Item className="btn btn-info" onClick={handleUserPage}>Add users</Dropdown.Item>
                  ) : (
                    <></>
                  )}
                  {!showUnused ? (
                    <Dropdown.Item className="btn-info" onClick={() => setShowUnused(true)}>Show unused pages</Dropdown.Item>
                  ) : (
                    <Dropdown.Item className="btn-info" onClick={() => setShowUnused(false)}>Hide unused pages</Dropdown.Item>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Item className="btn-info" onClick={handleLogout}>Log out</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
          </Navbar.Collapse>
          <Navbar.Toggle />
        {/* </Container> */}
      </Navbar>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change your password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={pwd}
                onChange={handleChangePwd}
              />
            </Form.Group>
          </Form>
          {isLoading && <LoadingIndicator />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updatePasswordDb} disabled={isLoading}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavigationBar;
