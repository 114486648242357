import React, { useEffect, useState } from "react";
import "./HomePage.css";
import { CONTRACT_ADDRESS, transformPassportData } from "../../constants";
import MetropolisWorldAccessToken from "../../utils/AccessToken.json";
import NavigationBar from "../NavBar";
import { ethers } from "ethers";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { transformPannelData } from "../../constants";
import * as Realm from "realm-web";

const HomePage = ({ currentAccount, setDetailsPage, setPannel }) => {
  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const [pannels, setPannels] = useState([]);
  const [dataView, setDataView] = useState(1);

  const showDetails = (p) => {
    setDetailsPage(true);
    console.log(p);
    setPannel(p);
  };

  useEffect(() => {
    const getPannels = async () => {
      console.log("getting pannels");
      const apiKey = process.env.REACT_APP_MONGOAPIKEY;
      const credentials = Realm.Credentials.apiKey(apiKey);
      try {
        // Authenticate the user
        const user = await realmApp.logIn(credentials);
        const pannelList = await user.functions.getPannels();
        console.log("pannel list is:", pannelList);
        if (pannelList) {
          const pans = pannelList.map((pannelData) =>
            transformPannelData(pannelData)
          );
          console.log("setting the state");
          setPannels(pans);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getPannels();
    console.log(pannels);
  }, []);

  useEffect(() => {
    console.log("pannels updated: ", pannels);
  }, [pannels]);

  const renderPannels = () => {
    return pannels.map((pannel, index) => (
      <Card className="mt-2" key={index}>
        <Card.Body>
          <Card.Title>{pannel.headline}</Card.Title>
          <Card.Subtitle>{pannel.type}</Card.Subtitle>
          <Button
            className="mt-2"
            value={pannel.page}
            onClick={(e) => showDetails(e.target.value)}
          >
            Show
          </Button>
        </Card.Body>
      </Card>
    ));
  };
  return (
    <>
    <Row>
      <h3>Interesting stats here</h3>
    </Row>
    <Row>
      <DropdownButton title="Datasets">
        <Dropdown.Item onClick={() => setDataView(1)}>Engagement</Dropdown.Item>
        <Dropdown.Item onClick={() => setDataView(2)}>Core</Dropdown.Item>
        <Dropdown.Item onClick={() => setDataView(3)}>MW Data</Dropdown.Item>
        <Dropdown.Item onClick={() => setDataView(4)}>NFT Sales</Dropdown.Item>
        <Dropdown.Item onClick={() => setDataView(5)}>Twitter</Dropdown.Item>
        <Dropdown.Item onClick={() => setDataView(6)}>Audience</Dropdown.Item>
        <Dropdown.Item onClick={() => setDataView(7)}>Returning Users</Dropdown.Item>
      </DropdownButton>
    </Row>
      <Row>
        {/* <Col style={{span: 1}}> */}
          {/* <Row xs={1} md={1} className="g-4">
            {pannels.length > 0 && <Col> {renderPannels()}</Col>}
          </Row> */}
        {/* </Col> */}
        <Col>
          {dataView === 1 ? (
            <div className="data-dashboard">
              <iframe src="https://app.databox.com/datawall/d4b10a7e76e7bac1cbe6a0196afcbb1b0635bdbbe?i" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
            </div>
          ) : dataView === 2 ? (
            <div className="data-dashboard">
              <iframe src="https://app.databox.com/datawall/ba005c0ceffaa12531b4aa1f238694ab0635fb64d?i" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen={true}></iframe>
            </div>
          ) : dataView === 3 ? (
            <div className="data-dashboard">
              <iframe src="https://app.databox.com/datawall/2cd8b34ee1fda9986bb9e34f938583b106364f6db?i" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen={true}></iframe>
            </div>
          ) : dataView === 4 ? (
            <div className="data-dashboard">
              <iframe src="https://app.databox.com/datawall/1e57b2a1c0d8639b2fff26ec9c83e8770637fcfc5?i" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen={true}></iframe>
            </div>
          ): dataView === 5 ? (
            <div className="data-dashboard">
              <iframe src="https://app.databox.com/datawall/7e049c83b49268d7bfafe32b966c53cb063808d78?i" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen={true}></iframe>
            </div>
          ): dataView === 6 ? (
            <div className="data-dashboard">
              <iframe src="https://app.databox.com/datawall/66f7f7d14600642ddaaccea81f9e336a06380927d?i" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen={true}></iframe>
            </div>
          ): dataView === 7 ? (
            <div className="data-dashboard">
              <iframe src="https://app.databox.com/datawall/ab8b4389ff07d75d8cbcbfa1d4717d4b06380c088?i" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen={true}></iframe>
            </div>
          ): (
            <></>
          )} 
        </Col>
      </Row>
    </>
  );
};

export default HomePage;
