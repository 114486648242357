import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";
import "./UserPage.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const WinChanceComponent = ({ setIsLoading, winChanceContract, accessTokenContract }) => {

  const [currentReferalIncrement, setCurrentReferalIncrement] = useState(0);
  const [newReferalIncrement, setNewReferalIncrement] = useState(0);

  const [newLossIncrement, setNewLossIncrement] = useState(0);
  const [newWinDecrement, setNewWinDecrement] = useState(0);

  const [citiesDropped, setCitiesDropped] = useState(0);

  const [winChanceAddress, setWinChanceAddress]  = useState("");
  const [winChanceAmount, setWinChanceAmount] = useState(0);
  const [passportIndex, setPassportIndex] = useState(0);

  useEffect(() => {
    // loadWinChanceData();
  }, []);

  const handleReferalIncrement = (e) => {
    setNewReferalIncrement(e.target.value);
  };
  const handleLossIncrement = (e) => {
    setNewLossIncrement(e.target.value);
  };
  const handleWinDecrement = (e) => {
    setNewWinDecrement(e.target.value);
  };
  const handleWinChanceAddress = (e) => {
    setWinChanceAddress(e.target.value);
  };
  const handleWinChanceAmount = (e) => {
    setWinChanceAmount(e.target.value);
  };

  const loadWinChanceData = async () => {
    getReferalIncrement();
    getCitiesDropped();
  };

  const getReferalIncrement = async () => {
    try {
      const getReferalIncrementTxn = await winChanceContract.getReferalIncrease();
      setCurrentReferalIncrement(getReferalIncrementTxn);
      console.log("Get referal increment txn: ", getReferalIncrementTxn);
    } catch (error) {
      console.log("Error retrieving referal increment: ", error);
    }
  };

  const getCitiesDropped = async () => {
    try {
      const getCitiesDroppedTxn = await winChanceContract.getCitiesDropped();
      setCitiesDropped(getCitiesDroppedTxn);
      console.log("Get cities dropped txn: ", citiesDropped);
    } catch (error) {
      console.log("Error retrieving cities dropped: ", error);
    }
  };

  const changeReferalIncrement = async () => {
    try {
      const changeReferalIncrementTxn = await winChanceContract.setReferalIncrease(newReferalIncrement);
      await changeReferalIncrementTxn.wait();
      console.log("Referal increment changed to: ", changeReferalIncrementTxn);
      getReferalIncrement();
    } catch (error) {
      console.log("Error changing referal increment: ", error);
    }
  };

  const changeLossIncrement = async () => {
    try {
      const changeLossIncrementTxn = await winChanceContract.setLossIncrease(newLossIncrement);
      await changeLossIncrementTxn.wait();
      console.log("Loss increment changed to: ", changeLossIncrementTxn);
    } catch (error) {
      console.log("Error changing loss increment: ", error);
    }
  };

  const changeWinDecrement = async () => {
    try {
      const changeWinDecrementTxn = await winChanceContract.setWinDecrease(newWinDecrement);
      await changeWinDecrementTxn.wait();
      console.log("Win decrement changed to: ", changeWinDecrementTxn);
    } catch (error) {
      console.log("Error changing win decrement: ", error);
    }
  };

  const checkIfHasNFT = async () => {
    try {
      const checkIfHasNFTTxn = await accessTokenContract.checkIfHasNFT(winChanceAddress);
      console.log("Check if has NFT txn: ", checkIfHasNFTTxn);
      return checkIfHasNFTTxn;
    } catch (error) {
      console.log("Error checking if has NFT: ", error);
    }
  };

  const increaseWinChance = async () => {
    try {
      const returnedPassport = await checkIfHasNFT();
      console.log("returned passport: ", returnedPassport);
      const increaseWinChanceTxn = await accessTokenContract.increaseWinChance(returnedPassport[passportIndex].id.toNumber(), winChanceAmount, {gasLimit: 1000000});
      await increaseWinChanceTxn.wait();
      console.log("Win chance increased: ", increaseWinChanceTxn);
    } catch (error) {
      console.log("Error increasing win chance: ", error);
    }
  };

  const decreaseWinChance = async () => {
    try {
      const returnedPassport = await checkIfHasNFT();
      console.log("returned passport: ", returnedPassport);
      const decreaseWinChanceTxn = await accessTokenContract.decreaseWinChance(returnedPassport[passportIndex].id.toNumber(), winChanceAmount, {gasLimit: 1000000});
      await decreaseWinChanceTxn.wait();
      console.log("Win chance decreased: ", decreaseWinChanceTxn);
    } catch (error) {
      console.log("Error decreasing win chance: ", error);
    }
  };

  const testFunc = async () => {
    try {
      const testFuncTxn = await winChanceContract.increaseWinChance(winChanceAddress, winChanceAmount);
      await testFuncTxn.wait();
      console.log("Test func txn: ", testFuncTxn);
    } catch (error) {
      console.log("Error testing: ", error);
    }
  }

  return (
    <Card>
      <Card.Header>Win Chances</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Row>
                  <Form.Label>Win chance referal increase</Form.Label>
                </Row>
                <Row>
                  <Form.Control 
                    type="number"
                    placeholder="0"
                    value={newReferalIncrement}
                    onChange={handleReferalIncrement}
                  />
                </Row>
              </Form.Group>
                <Row xs={3} className="justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-button-1">Set the amount a referal increases the win chances</Tooltip>}
                  >
                    <Button onClick={() => changeReferalIncrement()}>Increase referal</Button>
                  </OverlayTrigger>
                </Row>
            </Col>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Row>
                  <Form.Label>Win chance loss increase</Form.Label>
                </Row>
                <Row>
                  <Form.Control 
                    type="number"
                    placeholder="0"
                    value={newLossIncrement}
                    onChange={handleLossIncrement}
                  />
                </Row>
              </Form.Group>
                <Row xs={3} className="justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-button-1">Set the amount a loss increases the win chances</Tooltip>}
                  >
                    <Button onClick={() => changeLossIncrement()}>Increase loss</Button>
                  </OverlayTrigger>
                </Row>
            </Col>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Row>
                  <Form.Label>Win chance win decrease</Form.Label>
                </Row>
                <Row>
                  <Form.Control 
                    type="number"
                    placeholder="0"
                    value={newWinDecrement}
                    onChange={handleWinDecrement}
                  />
                </Row>
              </Form.Group>
                <Row xs={3} className="justify-content-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="tooltip-button-1">Set the amount a win decreases the win chances</Tooltip>}
                  >
                    <Button onClick={() => changeWinDecrement()}>Decrease win</Button>
                  </OverlayTrigger>
                </Row>
            </Col>
          </Row>
        </Card.Body>
      <Card.Header>Set win chances</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                {/* <Row>
                  <Form.Label>Win chance address</Form.Label>
                </Row> */}
                <Row>
                  <Col style={{width: '15rem'}}>
                    <Row className="mb-3">
                      <Form.Label>Win chance address</Form.Label>
                        <Form.Control
                          type="string"
                          placeholder="0x...000"
                          value={winChanceAddress}
                          onChange={handleWinChanceAddress}
                        />
                    </Row>
                    <Row className="mb-3">
                      <Form.Label>Win chance amount</Form.Label>
                        <Form.Control 
                          type="number"
                          placeholder="0"
                          value={winChanceAmount}
                          onChange={handleWinChanceAmount}
                        />
                    </Row>
                    <Row className="mb-3">
                      <Button onClick={() => increaseWinChance()}>Increase</Button>
                      <Button onClick={() => testFunc()}>Test</Button>
                    </Row>
                    <Row className="mb-3">
                      <Button onClick={() => decreaseWinChance()}>Decrease</Button>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
    </Card>
  );
};

export default WinChanceComponent;