import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import * as Realm from "realm-web";
import { Button, Row, Col, Form, Modal, Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import LoadingIndicator from "../../../LoadingIndicator";
import "./EtherealPage.css";
import EggData from "./EggData.json";
import * as XLSX from 'xlsx/xlsx.mjs';

const EtherealsPage = ({}) => {

  // https://ik.imagekit.io/metropolis/eggs/ethereals/kelpie/HeadMod-8.png?tr=w-100,h-100

  const [traits, setTraits] = useState(null);
  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });

  // const justinRealmApp = new Realm.App({ id: process.env.REACT_APP_JUSTINMONGOAPP });

  const [eggData, setEggData] = useState(EggData);

  const [newAttributes, setNewAttributes] = useState(null);

  const [spreadsheetData, setSpreadsheetData] = useState(null);

  const [showTools, setShowTools] = useState(false);

  const etherealsImageCellRenderer = (params) => {
    const url = `https://ik.imagekit.io/metropolis/eggs/ethereals/${params.data.creature}/${params.value}/tr:h-250`
    // console.log("url: ", url);
    return (
      <div>
        <p className="m-0">{params.value}</p>
        <img 
          className="mb-2"
          src={url}
        />
      </div>
    )
  };

  const gridRef = useRef();
  const defaultColDef = useMemo(() => ({
    sortable: true,
    editable: false,
    resizable: true,
    filter: 'agTextColumnFilter',
    width: "auto",
    filterParams: {buttons: ['apply', 'cancel', 'reset'], closeOnApply: true}, 
  }));

  const [columnsData, setColumnsData] = useState([
    {field: "filename", editable: true, cellRenderer: etherealsImageCellRenderer},
    {field: "creature", editable: true},
    {field: "customerName", editable: true}
  ]);

  const [changedRowData, setChangedRowData] = useState([]);

   // Sets a filter based on the value entered in the input field. 
   const onFilterTextChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }, []);

  const clearFilter = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.setQuickFilter('');
    document.getElementById('filter-text-box').value = '';
  }, []);

  const onCellValueChanged = async (e) => {
    // If the cell changed has already been changed and added to rowChanged then find it and update the row.
    console.log("row changed: ", changedRowData);
    if(changedRowData.find(item => item.filename === e.data.filename && e.data.creature === item.creature)) {
      const items = changedRowData.find(item => item.filename === e.data.filename && e.data.creature === item.creature);
      console.log("Items: ", items);
      const index = changedRowData.indexOf(items);

      // Temp variable to hold the current state of changed spreadsheet data. 
      let temp_state = [...changedRowData];
      // Temp variable to hold the current row we are updating.
      let temp_element = {...temp_state[index]};
      // Update the temp row with the new data.
      temp_element = e.data;
      // Set the old row to the new row.
      temp_state[index] = temp_element;
      // Update state
      setChangedRowData(temp_state);

      console.log("Changed data: ", changedRowData);
    } else {
      // Else if row data not already included, add it. 
      setChangedRowData(prevRow => [...prevRow, e.data]);
      console.log("else");
    }
  };

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  // const getJustin = useCallback(async () => {
  //   const apiKey = process.env.REACT_APP_JUSTINMONGOAPIKEY;
  //   const credentials = Realm.Credentials.apiKey(apiKey);
  //   try {
  //     const justinUser = await justinRealmApp.logIn(credentials);
  //     return justinUser;
  //   } catch (error) {
  //     console.log(`Error getting justin user: ${error}`);
  //   }
  // }, []);

  useEffect(() => {
    refreshEtherealTraits();
  }, []);

  const refreshEtherealTraits = async () => {
    try {
      const user = await getUser();
      const refreshEtherealTraits = await user.functions.get_etherealTraits();
      setTraits(refreshEtherealTraits);
      console.log("traits: ", refreshEtherealTraits);
    } catch (error) {
      console.log("Error refreshing ethereal traits: ", error);
    }
  };

  const updateEtherealTraits = async () => {
    try {
      const user = await getUser();
      for(let i = 0; i < changedRowData.length; i++) {
        const updateEtherealTraits = await user.functions.update_etherealTraits(changedRowData[i].filename, changedRowData[i].creature, changedRowData[i].customerName);
        console.log("Updated traits", updateEtherealTraits);
      }
      clearAfterUpdate();
    } catch (error) {
      console.log("Error updating ethereal traits", error);
    }
  }

  const clearAfterUpdate = () => {
    setChangedRowData([]);
  };

  const getAllEthereals = async () => {
    try {
      const user = await getUser();
      const getAllEthereals = await user.functions.get_ethereals();
      console.log("All ethereals: ", getAllEthereals);
      setNewAttributes(getAllEthereals[0].attributes);
      return getAllEthereals;
    } catch (error) {
      console.log("Error getting all ethereals: ", error);
    }
  };

  const getAllEtherealTraits = async () => {
    try {
      const user = await getUser();
      const getAllEtherealTraits = await user.functions.get_etherealTraits();
      console.log("All ethereal traits: ", getAllEtherealTraits);
      return getAllEtherealTraits;
    } catch (error) {
      console.log(`Error getting all ethereal traits: ${error}`);
    }
  };

  const checkAttributes = async () => {
    // Check if creature has customer name attributes
    // If not, call update attributes
    const ethereals = await getAllEthereals();
    const etherealTraits = await getAllEtherealTraits();
    for(let i = 5000; i < 5250; i++) {
      for(let j = 0; j < ethereals[i].attributes.length; j++) {
        var creature = ethereals[i].egg.toLowerCase();
        var attribute = ethereals[i].attributes[j].value;
        var trait = etherealTraits.find(object => (object.filename === attribute) && (object.creature === creature || "diving bell" == creature));
        // var trait = etherealTraits.find(object => (object.filename === attribute));
        if(trait) {
          if(trait.customerName == "") {
            // console.log("No customer name found for trait: ", trait);
            continue;
          }
          if (ethereals[i].attributes[j].value == trait.customerName) {
            console.log(`ethereal ${i} and attribute ${attribute} is already named`)
            continue;
          }
          // console.log("trait: ", trait);
          ethereals[i].attributes[j].value = trait.customerName;
          console.log("new ethereal: ", ethereals[i]);
        } else {
          console.log("No matching trait found for attribute: ", attribute);
        }
      }
      console.log("final attributes: ", ethereals[i].attributes);
      // await updateAttributes(ethereals[i].attributes);
    }
  }

  const updateAttributes = async (attributes) => {
    // setNewAttributes([...newAttributes, newAttributes[0].value = "test"]);
    // console.log("New attributes: ", newAttributes);
    try {
      const user = await getUser();
      const updateAttributes = await user.functions.update_ethereals(1, attributes);
    } catch (error) {
      console.log("Error updating ethereal attributes", error);
    }
  }

  const handleSpreadsheet = async (e) => {
    console.log("e: ", e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {type: "binary"});
      // const wsNames = wb.SheetNames.forEach((sheet) => {
      //   var xl_row_object = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheet]);
      //   var json_object = JSON.stringify(xl_row_object);
      //   console.log("json_object: ", json_object);
      // })
      const wsname = wb.SheetNames[4];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      console.log("data: ", data);
      setSpreadsheetData(data);
    }

    reader.onerror = (e) => {
      console.log("Error reading file: ", e);
    }

    reader.readAsBinaryString(file);
  };

  const updateEtherealTraitsSpreadsheet = async () => {
    try {
      const user = await getUser();
      // console.log(spreadsheetData[0]["File Name"], spreadsheetData[0]["Display Name"])
      for(let i = 252; i < spreadsheetData.length; i++) {
        console.log("spreadsheetData[i]: ", spreadsheetData[i]["File Name"] + ".png", spreadsheetData[i]["Display Name"]);
        // const updateEtherealTraits = await user.functions.update_etherealTraits(spreadsheetData[i]["File Name"] + ".png", "polydot", spreadsheetData[i]["Display Name"]);
        // console.log("Updated traits", updateEtherealTraits);
      }
    } catch (error) {
      console.log(`Error updating ethereal traits via spreadsheet: ${error}`);
    }
  }

  // const test = async () => {
  //   try {
  //     const justinUser = await getJustin();
  //     // console.log(spreadsheetData[0]["File Name"] + ".png", "kelpie", spreadsheetData[0]["Display Name"])
  //     const test = await justinUser.functions.test(spreadsheetData[0]["File Name"] + ".png", "molten", spreadsheetData[0]["Display Name"]);
  //   } catch (error) {
  //     console.log(`Error: ${error}`);
  //   }
  // }

  return (
    <div>
      <h1>Ethereals</h1>
      {/* <button onClick={() => refreshEtherealTraits()}>Refresh</button> */}
      <input 
        type="string"
        placeholder="Search"
        id="filter-text-box"
        onInput={onFilterTextChanged}
      />
      <Button onClick={() => updateEtherealTraits()}>Update</Button>
      <Modal show={showTools} onHide={() => setShowTools(false)}>
        <Modal.Body>
        <div className="spreadsheet-tools">
          <input 
            type="file"
            onChange={(e) => handleSpreadsheet(e)}
          />
          <button onClick={() => updateEtherealTraitsSpreadsheet()}>Update via spreadsheet</button>
          <button onClick={() => test()}>Test</button>
        </div>
        <div className="ethereal-attributes">
          <button onClick={() => getAllEthereals()}>All ethereals</button>
          <button onClick={() => updateAttributes()}>Update attributes</button>
          <button onClick={() => getAllEtherealTraits()}>All ethereal traits</button>
          <button onClick={() => checkAttributes()}>Check</button>
        </div>
        </Modal.Body>
      </Modal>
      <div className="spreadsheet-container">
        <div role="Spreadsheet div" className="ag-theme-alpine" style={{width: "70%", height: 500}}>
            <AgGridReact
              ref={gridRef} 
              rowData={traits}
              rowHeight={250}
              columnDefs={columnsData}
              defaultColDef={defaultColDef}
              onCellValueChanged={onCellValueChanged}
              // onFirstDataRendered={onFirstDataRendered}
            />
          </div>
        </div>
        {/* <button onClick={() => setShowTools(true)}>Tools</button> */}
    </div>
  );
}

export default EtherealsPage;