import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import * as Realm from "realm-web";
import { Button, Row, Col, Form, Modal, Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import LoadingIndicator from "../LoadingIndicator";
import AvatarsPage from "./Components/Avatars/AvatarsPage";
import EtherealsPage from "./Components/Ethereals/EtherealsPage";

const CitizenPage = ({ currentAccount, sideBarItemPages }) => {

  const renderPages = () => {
    if(sideBarItemPages.citizensPage === "avatars") {
      return <AvatarsPage currentAccount={currentAccount}/>
    } else if (sideBarItemPages.citizensPage === "ethereals") {
      return <EtherealsPage currentAccount={currentAccount}/>
    }
  }

  return (
    <div>
      {renderPages()}
    </div>
  )
};

export default CitizenPage;