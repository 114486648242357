
// Mainnet addresses
// const ACCESS_TOKEN_CONTRACT_ADDRESS = '0x0A16305612706B4eabce43247D61Fe7fBED708E4';
// const PAYMENT_SPLIT_CONTRACT_ADDRESS = '0xc14bd0EE9B933a461488557D713Eb2Ee23740Dd6 ';
// const IMAGE_DATA_CONTRACT_ADDRESS = '0x084d1301407B08f3feA7D9fFeae4c89d3e9BC449';
// const LOTTERY_CONTRACT_ADDRESS = '0x86B52861F0b0DC85c82D8555bD0cc883a3B13550';
// const WHITELIST_CONTRACT_ADDRESS = '0x958414A65A2267645D4b52B6850d4aA82f5159eE';
// const SOFTCLAY_CONTRACT_ADDRESS = '0x6f9857B1Fd1037aD9e7A8d8244c9cc0d8e669FF7';
// const WIN_CHANCE_CONTRACT_ADDRESS = '0x5Ffeb68F93a3dB017a2870b3B8f1575eAe96589E';

// Personal test addresses
const ACCESS_TOKEN_CONTRACT_ADDRESS = '0x4d265E5De4E882E0Da85E4212DEb26F417120C85';
const PAYMENT_SPLIT_CONTRACT_ADDRESS = '0x16426c4C06bB0920a2563d4104BDf6b95a4A09Dc';
const IMAGE_DATA_CONTRACT_ADDRESS = '0x0c6AD0FfB5E34704239C73DC9F57FDDde82534D2';
const LOTTERY_CONTRACT_ADDRESS = '0x6F988b0E73bCD154e5dEDe928037bb36143deaC1';
const WHITELIST_CONTRACT_ADDRESS = '0x15E092eEFC3ae489139F2F9413a2eAab09e2651C';
const SOFTCLAY_CONTRACT_ADDRESS = '0xB519f3b3BBcD7761408037d23C3F708b9bB3f4C4';
const WIN_CHANCE_CONTRACT_ADDRESS = '0x249f84b0A8cD2542393eA14D0b61180E7d835411';

const EGG_COLLECTION_CONTRACT_ADDRESS = '0x4Da68c9A4F9a15efc8Dc700537C08aEcc77B2730';


const transformPassportData = (passportData) => {
    return {
      name: passportData.name,
      imageURI: passportData.cdnImage, //.replace("ipfs://", "https://ipfs.io/ipfs/"),
      winChances: passportData.winChances,
      level: passportData.level,
      description: passportData.description,
      
    };
  };
  const transformPannelData = (pannelData) => {
    return {
      headline: pannelData.headline,
      type: pannelData.type,
      page: pannelData.page
    };
  };

export { ACCESS_TOKEN_CONTRACT_ADDRESS, PAYMENT_SPLIT_CONTRACT_ADDRESS, IMAGE_DATA_CONTRACT_ADDRESS, LOTTERY_CONTRACT_ADDRESS, WHITELIST_CONTRACT_ADDRESS, SOFTCLAY_CONTRACT_ADDRESS, WIN_CHANCE_CONTRACT_ADDRESS, 
  transformPassportData, transformPannelData, EGG_COLLECTION_CONTRACT_ADDRESS
};
