import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import RoleSelector from "../RoleSelector";

const AdminPage = ({accessTokenContract, imageContract, paymentSplitContract, lotteryContract, whitelistContract, softClayContract, winChanceContract}) => {
  return (
    <div>
      <h1>ADMIN</h1>
      <RoleSelector accessTokenContract={accessTokenContract} imageContract={imageContract} paymentSplitContract={paymentSplitContract} lotteryContract={lotteryContract} whitelistContract={whitelistContract} softClayContract={softClayContract} winChanceContract={winChanceContract} />
    </div>
  )
}

export default AdminPage;