import { useState, useRef, useCallback } from "react";
import { Col, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import "./Collectables.css";

const CollectablePage2 = ({ collectablePage, setCollectablePage, allCollectables, setSelectedCollectable}) => {

  const iconCellRenderer = (params) => {
    return (
     <img
      width="150"
      height="150"
      src={params.value}
     />
    ) 
   };

  const linkCellRenderer = (params) => {
    return (
      <a href={params.value}>{params.value}</a>
    )
  }

  const gridRef = useRef();
  const [rowData, setRowData] = useState(allCollectables);
  const [columnData, setColumnData] = useState([
    {field: 'id'},
    {field: 'name'},
    {field: 'icon', cellRenderer: iconCellRenderer},
    {field: 'link', cellRenderer: linkCellRenderer},
    {field: 'type'},
    {field: 'category'},
  ]);


  const onFirstDataRendered = useCallback(() => {
    autoSizeAll();
  });

  const autoSizeAll = useCallback(() => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds);
  }, []);

  const onCellClicked = (e) => {
    setSelectedCollectable(e.data);
    console.log("e", e);
  };

   // Sets a filter based on the value entered in the input field. 
   const onFilterTextChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }, []);

  // Clears the search and applied filters when button is clicked. 
  const clearFilter = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.setQuickFilter('');
    document.getElementById('filter-text-box').value = '';
  }, []);

  return (
    <div>
      <h1>Collectable Page 2</h1>
      <Button onClick={() => setCollectablePage(1)}>Back</Button>
      <Button onClick={() => setCollectablePage(3)}>Next</Button>
      <div className="current-collectables-container">
        <div className="search-container">
          <input 
            type="string"
            placeholder="Search"
            id="filter-text-box"
            onInput={onFilterTextChanged}
          />
          <button onClick={() => clearFilter()}>Clear</button>
        </div>
        {/* <div aria-labelledby="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 500, maxWidth: 1225, paddingLeft: '15%', paddingRight: '15%'}}> */}
        <div aria-labelledby="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 500}}>
          <AgGridReact 
            ref={gridRef}
            rowData={rowData}
            rowHeight={150}
            columnDefs={columnData}
            // defaultColDef={defaultColDef}
            // onCellValueChanged={onCellValueChanged}
            onFirstDataRendered={onFirstDataRendered}
            onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </div>
  )
}

export default CollectablePage2;