import { useState, useEffect, useCallback, useMemo } from "react";
import * as Realm from "realm-web";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import LoadingIndicator from "../LoadingIndicator";

const CustomerProfilePage = ({ currentAccount }) => {

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const nftRealmApp = new Realm.App({ id: process.env.REACT_APP_NFTDROPAPP });

  const [softClayAddress, setSoftClayAddress] = useState("");
  const [softClayValue, setSoftClayValue] = useState(0);
  const [softClayBalance, setSoftClayBalance] = useState(0);
  const [user, setUser] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(JSON.parse(currentAccount));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUser(); 
  }, []);

  const handleSoftClayAddress = async (e) => {
    setSoftClayAddress(e.target.value);
    await getSoftClayBalance(e.target.value);
  };

  const handleSoftClayValue = (e) => {
    setSoftClayValue(Number(e.target.value));
  }

  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  const addSoftClay = async () => {
    setIsLoading(true);
    const user = await getUser();
    try {
      const addSoftClayTxn = await user.functions.add_soft_clay(softClayAddress, softClayValue);
      console.log("add soft clay txn: ", addSoftClayTxn);
      const logDatabaseUser = await user.functions.user_log(loggedInUser.name, "add_soft_clay", {wallet: softClayAddress, soft_clay: softClayValue});
      getSoftClayBalance(softClayAddress);
    } catch (error) {
      console.log("Errr adding soft clay: ", error);
    }
    setIsLoading(false);
  };

  const removeSoftClay = async () => {
    setIsLoading(true);
    const user = await getUser();
    try {
      const removeSoftClayTxn = await user.functions.remove_soft_clay(softClayAddress, softClayValue);
      console.log("remove soft clay txn: ", removeSoftClayTxn);
      const logDatabaseUser = await user.functions.user_log(loggedInUser.name, "remove_soft_clay", {wallet: softClayAddress, soft_clay: softClayValue});
    } catch (error) {
      console.log("Error removing soft clay: ", error);
    }
    setIsLoading(false);
  }

  const getSoftClayBalance = async (walletAddress) => {
    const user = await getUser();
    try {
      const getSoftClayTxn = await user.functions.get_soft_clay(walletAddress);
      if(getSoftClayTxn !== null || getSoftClayTxn !== undefined) {
        setSoftClayBalance(getSoftClayTxn.softClay);
      }
      console.log("Get soft clay txn: ", getSoftClayTxn);
    } catch (error) {
      console.log("Error getting soft clay: ", error);
      setSoftClayBalance(0);
    }
  };

  return (
    <div>
      <h1>Howdy Ho Zack!!</h1>
      <Modal show={isLoading}>
        <LoadingIndicator />
      </Modal>
      <Row>
        <Col>
          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Form.Label>
                  Wallet
                </Form.Label>
                  <Form.Control 
                    type="text"
                    placeholder="Enter Soft Clay Address"
                    value={softClayAddress}
                    onChange={handleSoftClayAddress}
                  />
              </Form.Group>
              <p>Soft clay balance: {softClayBalance}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 d-inline-block">
                <Form.Label>Soft Clay Value</Form.Label>
                  <Form.Control 
                    type="number"
                    placeholder="Enter Soft Clay Value"
                    value={softClayValue}
                    onChange={handleSoftClayValue}
                  />
              </Form.Group>
            </Col>
          </Row>
          <Button className="m-1" onClick={() => addSoftClay()}>Add</Button>
          <Button className="m-1" onClick={() => removeSoftClay()}>Remove</Button>
          {/* <Button className="m-1" onClick={() => getSoftClayBalance(softClayAddress)}>Get soft clay</Button> */}
        </Col>
      </Row>
    </div>
  )
};

export default CustomerProfilePage;