import { useState, useRef, useCallback } from "react";
import { Button } from "react-bootstrap";
import * as Realm from "realm-web";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'
import "./Collectables.css"

const CollectablePage3 = ({ collectablePage, setCollectablePage, allCollectables, properties, selectedCollectable, username }) => {

  const [selectedProperty, setSelectedProperty] = useState(null);

  const propertyImageCellRenderer = (params) => {
    const url = "https://ik.imagekit.io/metropolis/tr:h-150" + params.value;
    return (
      <div>
        {/* <p className="m-0">{params.value}</p> */}
        <img 
          src={url}
        />
      </div>
    )
  };

  const gridRef = useRef();
  const [rowData, setRowData] = useState(properties);
  const [defaultColDef, setDefaultColDef] = useState({
    filter: 'agTextColumnFilter', 
    filterParams: {buttons: ['apply', 'cancel', 'reset'], closeOnApply: true},
    resizable: true,
  });
  const [columnData, setColumnData] = useState([
    {field: 'id', filter: 'agNumberColumnFilter' },
    {field: 'name'},
    {field: 'img_name', cellRenderer: propertyImageCellRenderer},
  ]);

   // Sets a filter based on the value entered in the input field. 
   const onFilterTextChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(document.getElementById('filter-text-box').value);
  }, []);

  // Clears the search and applied filters when button is clicked. 
  const clearFilter = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    gridRef.current.api.setQuickFilter('');
    document.getElementById('filter-text-box').value = '';
  }, []);

  const onCellClicked = (e) => {
    setSelectedProperty(e.data);
  };

  const realmApp = new Realm.App({ id: process.env.REACT_APP_MONGOAPP });
  const getUser = useCallback(async () => {
    const apiKey = process.env.REACT_APP_MONGOAPIKEY;
    const credentials = Realm.Credentials.apiKey(apiKey);
    try {
      // Authenticate user
      const user = await realmApp.logIn(credentials);
      return user;
    } catch (error) {
      console.log("Error getting user: ", error);
    }
  }, []);

  const addCollectableToProperty = async () => {
    if(selectedCollectable === null) {
      alert("Please select a collectable to add to a property");
      return;
    } else if (selectedProperty === null) {
      alert("Please select a property to add the collectable to");
      return;
    }
    const user = await getUser();
    try {
      const addCollectableToPropertyTxn = await user.functions.add_collectable_to_property(selectedCollectable.id, selectedProperty.id);
      console.log("addCollectableToPropertyTxn: ", addCollectableToPropertyTxn);
      const userLog = await user.functions.user_log(username, "add_collectable_to_property", {col_id: selectedCollectable.id, prop_id: selectedProperty.id});
    } catch (error) {
      console.log("Error adding collectable to property: ", error);
    }
  }

  return (
    <div>
      <h1>Collectable Page 3</h1>
      <Button onClick={() => setCollectablePage(2)}>Back</Button>
      <Button onClick={() => addCollectableToProperty()}>Submit</Button>
  
      <div className="property-collectables-container">
        <div className="search-container">
          <input 
            type="text"
            id="filter-text-box"
            placeholder="Search"
            onInput={onFilterTextChanged}
          />
          <button onClick={() => clearFilter()}>Clear</button>
        </div>

        <div aria-labelledby="Spreadsheet div" className="ag-theme-alpine" style={{width: 'auto', height: 500}}>
          <AgGridReact 
            ref={gridRef}
            rowData={rowData}
            rowHeight={150}
            columnDefs={columnData}
            defaultColDef={defaultColDef}
            // onCellValueChanged={onCellValueChanged}
            // onFirstDataRendered={onFirstDataRendered}
            onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </div>
  )
}

export default CollectablePage3;