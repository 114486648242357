import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";

const ImageSection = ({setIsLoading, imageContract }) => {

  // Local state for image data contract
const [elements, setElements] = useState(["Fire", "Water", "Air", "Space", "Pixel", "Earth"]);
const eLength = elements.length;
const [elementsIndex, setElementsIndex] = useState(0);
const [ipfsImage, setIpfsImage] = useState([]);
const [ipfsImageValue, setIpfsImageValue] = useState("");
const [cdnImage, setCdnImage] = useState([]);
const [cdnImageValue, setCdnImageValue] = useState("");
const [animationImage, setAnimationImage] = useState([]);
const [animationImageValue, setAnimationImageValue] = useState("");
const [currentSelectedLevel, setCurrentSelectedLevel] = useState(0);
const [addImageToContractButton, setAddImageToContractButton] = useState(false);

/*********************** Functions for image contract ***********************/
// Handle user input

const handleIpfsImageValue = (e) => {
  setIpfsImageValue(e.target.value);
};
const handleCdnImageValue = (e) => {
  setCdnImageValue(e.target.value);
};
const handleAnimationValue = (e) => {
  setAnimationImageValue(e.target.value);
};

// Set new image urls and clear the input fields. Increment index to move onto next element
const setImages = () => {
  if(elementsIndex < eLength) {
    setIpfsImage(oldArr => [...oldArr, ipfsImageValue]);
    setCdnImage(oldArr => [...oldArr, cdnImageValue]);
    setIpfsImageValue("");
    setCdnImageValue("");
    setElementsIndex(elementsIndex + 1);
    // Only show the button if there are no more elements to add
    if(elementsIndex === eLength - 1) {
      setAddImageToContractButton(true);
    }
  }
};

// Just like setImages, but for animation. Separate function because not every rank has animations
const setAnimations = () => {
  if (elementsIndex < eLength) {
    setAnimationImage(oldArr => [...oldArr, animationImageValue]);
    setAnimationImageValue("");
    setElementsIndex(elementsIndex + 1);
    if (elementsIndex === eLength - 1) {
      setAddImageToContractButton(true);
    }
  }
};

// Function to add our image arrays to the contract
const addImageToContract = async () => {
  setIsLoading(true);
  if(currentSelectedLevel === 1) {
    try {
      const addNavigatorImageTxn = await imageContract.setNavImages(ipfsImage, cdnImage);
      await addNavigatorImageTxn.wait();
      console.log("Nav image txn: ", addNavigatorImageTxn);
    } catch (error) {
      console.log("Error adding navigator image to contract: ", error);
    }
  } else if (currentSelectedLevel === 2) {
    try {
      const addPioneerImageTxn = await imageContract.setPioneerImages(ipfsImage, cdnImage);
      await addPioneerImageTxn.wait();
      console.log("Pioneer image txn: ", addPioneerImageTxn);
    } catch (error) {
      console.log("Error adding pioneer image to contract: ", error);
    }
  } else if (currentSelectedLevel === 3) {
    try {
      const addLegendImageTxn = await imageContract.setLegendImages(ipfsImage, cdnImage);
      await addLegendImageTxn.wait();
      console.log("Legend image txn: ", addLegendImageTxn);
    } catch (error) {
      console.log("Error adding legend image to contract: ", error);
    }
  } else if (currentSelectedLevel === 4) {
    try {
      const addAnimationImageTxn = await imageContract.setAnnimations(animationImage);
      await addAnimationImageTxn.wait();
      console.log("Animation image txn: ", addAnimationImageTxn);
    } catch (error) {
      console.log("Error adding animation image to contract: ", error);
    }
  }
  resetImageData();
  setIsLoading(false);
};

// Clear image data 
const resetImageData = () => {
  setElementsIndex(0);
  setIpfsImage([]);
  setCdnImage([]);
  setIpfsImageValue("");
  setCdnImageValue("");
  setAddImageToContractButton(false);
};

// Retrieve current image urls for selected element at selected level
// Currently only prints to console, but can be revised 
const retreiveElementData = async (element) => {
  try {
    const getIpfsDataTxn = await imageContract.getIPFSImageForElement(element, currentSelectedLevel);
    console.log("IPFS data:", getIpfsDataTxn);
    alert("IPFS data for " + element + ": " + getIpfsDataTxn);
  } catch (error) {
    console.log("Error retrieving IPFS element data: ", error);
  } 
  try {
    const getCdnDataTxn = await imageContract.getCDNImageForElement(element, currentSelectedLevel);
    console.log("CDN data:", getCdnDataTxn);
    alert("CDN data for " + element + ": " + getCdnDataTxn);
  } catch (error) {
    console.log("Error retrieving CDN element data: ", error);
  }
};

const retreiveAnimationData = async (element) => {
  try {
    const getAnimationDataTxn = await imageContract.getAnnimationForElement(element);
    console.log("Animation data:", getAnimationDataTxn);
    alert("Animation data for " + element + ": " + getAnimationDataTxn);
  } catch (error) {
    console.log("Error retrieving animation element data: ", error);
  }
};

// Control panel to select which level of the contract to ineract with
const renderImageSelection = () => {
  if(currentSelectedLevel === 0) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>Update images</Card.Title>
            <DropdownButton title="Image level selection">
              <Dropdown.Item onClick={() => setCurrentSelectedLevel(1)}>Navigator</Dropdown.Item>
              <Dropdown.Item onClick={() => setCurrentSelectedLevel(2)}>Pioneer</Dropdown.Item>
              <Dropdown.Item onClick={() => setCurrentSelectedLevel(3)}>Legend</Dropdown.Item>
              <Dropdown.Item onClick={() => setCurrentSelectedLevel(4)}>Animation</Dropdown.Item>
            </DropdownButton>
        </Card.Body>
      </Card>
    );
  }
  else if(currentSelectedLevel === 1) {
    return (
      <Card>
        <Card.Body>
          <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => {setCurrentSelectedLevel(0); resetImageData()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
          </Button>
            <Card.Title>Navigator</Card.Title>
            <Form.Group className="mb-3 d-inline-block">
              <Form.Label className="m-1">Navigator image</Form.Label>
                <Form.Control 
                  type="string"
                  placeholder="Image URL"
                  value={ipfsImageValue}
                  onChange={handleIpfsImageValue}
                />
              <Form.Label className="m-1">Navigator cdn</Form.Label>
              <Form.Control 
                type="string"
                placeholder="CDN URL"
                value={cdnImageValue}
                onChange={handleCdnImageValue}
              />
            </Form.Group>
            {elementsIndex < eLength && (<Button className="form-btn" onClick={() => setImages()}>Add image for {elements[elementsIndex]}</Button>)}
            {addImageToContractButton && (<Button className="form-btn" onClick={() => addImageToContract()}>Set navigator images</Button>)}

            {/* Map elements to a dropdown menu */}
            <DropdownButton title="Retrieve data">
                {elements.map((element, index) => {
                  return (
                    <div key={index}>
                      <Dropdown.Item onClick={() => retreiveElementData(element)}>
                        {element}
                      </Dropdown.Item>
                    </div>
                  );
                }
              )}
            </DropdownButton>
        </Card.Body>
      </Card>
    );
  }
  else if (currentSelectedLevel === 2) {
    return (
      <Card>
        <Card.Body>
          <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => {setCurrentSelectedLevel(0); resetImageData()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
          </Button>
            <Card.Title>Pioneer</Card.Title>
            <Form.Group className="mb-3 d-inline-block">
              <Form.Label>Pioneer image</Form.Label>
                <Form.Control 
                  type="string"
                  placeholder="image url"
                  value={ipfsImageValue}
                  onChange={handleIpfsImageValue}
                />
              <Form.Label>Pioneer cdn</Form.Label>
              <Form.Control 
                type="string"
                placeholder="cdn url"
                value={cdnImageValue}
                onChange={handleCdnImageValue}
              />
            </Form.Group>
            {elementsIndex < eLength && (<Button className="form-btn" onClick={() => setImages()}>Add image for {elements[elementsIndex]}</Button>)}
            {addImageToContractButton && (<Button className="form-btn" onClick={() => addImageToContract()}>Set pioneer images</Button>)}
            <DropdownButton title="Retrieve data">
                {elements.map((element, index) => {
                  return (
                    <div key={index}>
                      <Dropdown.Item onClick={() => retreiveElementData(element)}>
                        {element}
                      </Dropdown.Item>
                    </div>
                  );
                }
              )}
            </DropdownButton>
        </Card.Body>
      </Card>
    );
  }
  else if (currentSelectedLevel === 3) {
    return (
      <Card>
        <Card.Body>
          <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => {setCurrentSelectedLevel(0); resetImageData()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
          </Button>
            <Card.Title>Legend</Card.Title>
            <Form.Group className="mb-3 d-inline-block">
              <Form.Label>Legend image</Form.Label>
                <Form.Control 
                  type="string"
                  placeholder="image url"
                  value={ipfsImageValue}
                  onChange={handleIpfsImageValue}
                />
              <Form.Label>Legend cdn</Form.Label>
                <Form.Control 
                  type="string"
                  placeholder="cdn url"
                  value={cdnImageValue}
                  onChange={handleCdnImageValue}
                />
            </Form.Group>
            {elementsIndex < eLength && (<Button className="form-btn" onClick={() => setImages()}>Add image for {elements[elementsIndex]}</Button>)}
            {addImageToContractButton && (<Button className="form-btn" onClick={() => addImageToContract()}>Set legend images</Button>)}
            <DropdownButton title="Retrieve data">
                {elements.map((element, index) => {
                  return (
                    <div key={index}>
                      <Dropdown.Item onClick={() => retreiveElementData(element)}>
                        {element}
                      </Dropdown.Item>
                    </div>
                  );
                }
              )}
            </DropdownButton>
        </Card.Body>
      </Card>
    );
  } else if (currentSelectedLevel === 4) {
    return (
      <Card>
        <Card.Body>
          <Button style={{display: "flex", justifyContent: "start"}} className="arrow-btn" onClick={() => {setCurrentSelectedLevel(0); resetImageData()}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16" >
              <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
          </Button>
            <Card.Title>Animation</Card.Title>
              <Form.Group className="mb-3 d-inline-block">
                <Form.Label>Animation image</Form.Label>
                  <Form.Control 
                    type="string"
                    placeholder="animation URL"
                    value={animationImageValue}
                    onChange={handleAnimationValue}
                  />
              </Form.Group>
              {elementsIndex < eLength && (<Button className="form-btn" onClick={() => setImages()}>Add image for {elements[elementsIndex]}</Button>)}
              {addImageToContractButton && (<Button className="form-btn" onClick={() => addImageToContract()}>Set legend images</Button>)}
              <DropdownButton title="Retrieve data">
                  {elements.map((element, index) => {
                    return (
                      <div key={index}>
                        <Dropdown.Item onClick={() => retreiveAnimationData(element)}>
                          {element}
                        </Dropdown.Item>
                      </div>
                    );
                  }
                )}
              </DropdownButton>
        </Card.Body>
      </Card>
    );
  }
};
  return (
    <div className="image-data-container">
      {renderImageSelection()}
    </div>
  );
}

export default ImageSection;